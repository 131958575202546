/* @import url(https://fonts.googleapis.com/css2?family=Poppins:ital, weght@0, 100;0, 200;0, 300;0, 400;0, 500;0, 600;0, 700;0, 800;0, 900;1, 100;1, 200;1, 300;1, 400;1, 500;1, 600;1, 700;1, 800;1, 900&display=swap); */
.catp-social,
.m-round {
    padding: 10px;
}
.CommShare h4,
.MobileAbout .PressPage h2,
.MobileView .GetStartedBtn .rounded-50,
.Upcomming h4,
main.BusinessHome.FaqPages.Community .CommonBreadcrumb h1 {
    text-transform: uppercase;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-area,
.covid ul,
.frineds-list ul {
    grid-template-columns: auto auto auto;
}
body {
    font-family: Poppins, sans-serif;
}
.text-align-center {
    text-align: center !important;
}
.text-align-left {
    text-align: left !important;
}
.card-item.blog-card a.card-image-wrap .card-image {
    position: relative;
    height: 180px;
    padding-top: 50%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.15);
}
.ListingFooter section.k-class.BizFooter {
    padding-top: 30px !important;
}
.ListingFooter {
    border-top: 1px solid #eee;
}
.ShowHover .HoverItem .hover-image img,
.card-item.blog-card a.card-image-wrap .card-image img.card__img,
.cities.recent .list1 .card-item .card-image img.card__img,
.cities.recent.posts .ReviewsWrote .card-image img,
span.user-preview img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
}
.cities.recent .list1 .card-item .card-image {
    position: relative;
    height: 150px;
    padding-top: 40%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.15);
}
.cities.recent .card-item .card-content-wrap .card-content .part {
    padding-top: 38.2%;
    position: relative;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}
.cities.recent .card-item .card-content-wrap .card-content .part img.card__img {
    height: 82%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
}
.header-area .main-menu-content {
    padding-right: 25px;
}
.cities.recent .card-item.col-last .card-image,
.cities.recent .card-item.col-last .card-image img.card__img,
.contact-form-action .form-control.c1.near-feild {
    border-radius: 0;
}
.r-bg button {
    outline: 0 !important;
}
.r-bg button div {
    display: none !important;
    background: 0 0 !important;
}
.rating-rating span.rating-count {
    margin-left: 4px;
    color: #46484d;
    font-weight: 500;
    font-size: 13px;
    width: auto;
    height: auto;
    background: 0 0;
    position: relative;
    top: 0;
}
.react-rater-star.is-active,
.react-rater-star.is-disabled.is-active-half {
    color: #fff !important;
    background: #ff4f00;
    border-radius: 3px;
    margin: 0 3px 0 0;
    width: 17px;
    height: 17px;
    text-align: center;
    vertical-align: bottom;
    padding: 0;
    line-height: 17px;
}
.react-rater-star.is-disabled.is-active-half {
    color: #ccc !important;
}
main.listing-details .main-menu-content.listin_nav nav ul li {
    padding-right: 10px;
}
.react-rater-star.is-active-half:before {
    color: #fff !important;
    left: auto !important;
}
.height-48 {
    height: 48px;
    border-radius: 5px !important;
    background-color: transparent !important;
    display: inline-flex;
    align-items: center;
    color: #fff !important;
    box-shadow: none !important;
    border-radius: none !important;
    border: none !important;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
}
.catp-social span a .social_icon:before {
    border-width: 8px;
    margin-top: -8px;
}
.catp-social span a .social_icon:after,
.catp-social span a .social_icon:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.ntooltip-top {
    margin-left: -23px !important;
    top: -31px;
    left: 0;
}
.D-Logo img,
.cities.recent.posts h2,
.main-search-input-item.location.country .css-2b097c-container,
main.home-1 .menu-full-width .main-menu-content nav ul li:first-child a {
    width: auto;
}
.main-search-input-item.location.country .css-2b097c-container .css-yk16xz-control {
    border: 0;
    border-radius: 0;
    border-left: 2px solid #c4c4c4;
    min-height: 38px;
    margin-top: 7px;
}
.catp-social span a img {
    width: 30px !important;
    margin-right: 5px;
}
.FontFamiles,
.catp-social > div,
.cities.recent.posts .card-item.col-last.first-col .media-body.item-heading.b-heading .learn-btn {
    display: flex;
    align-items: center;
}
.catp-social span a .social_icon {
    position: relative;
    background: #fff;
    border: 1px solid #ddd;
    min-width: 30px;
    text-align: center;
    color: #000;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}
.catp-social span a .social_icon,
.catp-social span a img {
    display: inline-block;
    vertical-align: middle;
}
.catp-social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ccc;
}
.cities,
.cities.recent h2,
.claimed .user-name,
.claimed a,
.inline-block,
.m-round img,
.name-user,
.product,
.review-rating-summary.ratings .review-rating-summary-inner,
.review-rating-summary.ratings .stats-average__rating,
.top-right .bell-icon a,
main.dashboard-page .dashboard-area .y-page-link a,
main.dashboard-page.BuyerProfile .user-info .uploads-update ul.uploads .upload-photos .drag-and-drop-wrap.text-center,
span.photos {
    display: inline-block;
}
.catp-social > div span,
.sidebar.section-bg.details-sidebar .sidebar-widget.amenities li a.d-flex.align-items-center.before-none img.icons,
.user_review a,
ul.VoteReviews li img.card__img,
ul.gender li label.review-label {
    margin: 0 10px 0 0;
}
.ActivityIcon,
.AddAddress,
.SearchArea,
.craousel-slider,
.drag-and-drop-file,
.ntooltip,
main.dashboard-page.details-info #page-content-wrapper section.dashboard-area.business-detail.u-photos .y-page-link {
    position: relative;
}
.GalleryPics .card-item.blog-card,
.MoreFeatures .modal-body .Collapsible__contentInner,
.billing-form-item.mb-0.LoginPopup,
.billing-form-item.mb-0.LoginPopup .modal-header,
.browes-btn .dropzone,
.card,
.cities.recent .card-content-wrap,
.k-class.white-bg .copy-right ul.list-items.SocialIcons li,
.main-menu-content nav ul.dropdown-menu-item li:last-child,
.reviews-sidebar ul li:last-child,
main.BusinessHome .slick-slider .slick-arrow,
main.BusinessHome.TermsOfService .main-menu-content.BHome.TP li,
main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent.FriendsManage .sorted,
main.home-1 header.header-area .main-menu-content nav #user-picture .user-information ul.UserSocialInfo li:last-child:hover {
    border: 0;
}
.m-round img {
    height: 32px;
    width: 32px;
    border-radius: 3px;
}
.m-body {
    padding-left: 10px;
}
.media-body.m-body {
    margin: -10px 0 0;
}
.react-tabs__tab-list {
    text-align: center;
    border-bottom: 1px solid #eeeeef;
}
ul.react-tabs__tab-list li.react-tabs__tab {
    border-bottom: 3px solid transparent !important;
    color: #000 !important;
}
ul.react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected {
    border-color: #f11200 !important;
    background: 0 0 !important;
}
li.react-tabs__tab.react-tabs__tab--selected {
    border-color: #f11200 !important;
    border: 0;
}
.react-tabs__tab--selected {
    background: #e2e2e2 !important;
    border-color: #aaa !important;
    color: #000;
}
.react-tabs__tab {
    border-bottom: 1px solid #aaa !important;
    color: #000 !important;
    font-weight: 400;
}
.list1 .card-content {
    padding: 0 20px 17px !important;
}
.cities {
    width: 100%;
    vertical-align: top;
    padding-top: 30px;
    text-align: left;
}
.cities.recent .media.m-round {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    margin: 0 0 10px;
}
.ShowHover a,
.cities.recent .media-body.item-heading h4.media-heading.m-h4,
.user-name-chat strong,
section.dashboard-area.business-detail .y-page-link .opening-hours ul li .days {
    font-size: 14px;
    font-weight: 600;
}
.cities.recent .media-body.item-heading {
    margin-bottom: 10px;
}
.like_class {
    font-size: 16px;
    margin-top: 0;
}
.like_class svg {
    font-size: 20px;
}
.cities.recent .card-item .card-content-wrap .card-content,
.no-padding {
    padding: 0 !important;
}
.cities.recent .card-item {
    margin-bottom: 30px;
    border: 1px solid #eee;
    padding: 0 10px 10px;
    border-radius: 3px;
}
.cities h2 {
    margin: 0 0 20px;
    text-align: center;
    color: #f11200;
    font-size: 16px;
    font-weight: 700;
}
.hero-wrapper2 .category-item .icon-element img {
    width: 22px;
}
.cities.recent h2 {
    margin-bottom: 19px;
    width: 100%;
}
.cities.recent .row.mt-5 {
    margin-top: 0 !important;
}
.inline-flex {
    display: inline-flex;
}
.update,
.update span {
    display: inline-block;
}
.BusinessInfo.banner_img img,
.CommImg img.icon,
.MobileView .AppContent p.color-primary,
.cities.recent.posts .ReviewsWrote .media.m-round.last-col .media-left,
.part img.card__img,
.stroy-media.post-details-page.review_page .slick-slide video,
.user-img-place img.random-img,
.width-100,
section.form-shared.login-page .contact-form-action.sign-up .col-lg-6.user-info.sign-up .input-box,
section.form-shared.login-page .contact-form-action.sign-up .col-lg-6.user-info.sign-up .input-box input.form-control {
    width: 100%;
}
.stroy-media.post-details-page.review_page .user_review button.btn.btn-link.card__img.img-thumbnail.btn.btn-none img,
.update span.add-date {
    width: auto;
    height: auto;
}
.no-border-radius {
    border-radius: 0 !important;
}
.no-margin,
main.listing-details .modal-top.border-0.mb-4.p-0 {
    margin: 0 !important;
}
section.hero-wrapper.hero-wrapper2 .highlight-lists.d-flex {
    display: inline-block !important;
    text-align: center;
    width: 100%;
}
.hero-wrapper2 .category-item {
    background: 0 0;
    display: inline-block;
}
.css-1wa3eu0-placeholder {
    top: 45% !important;
}
.main-search-input .main-search-input-item {
    margin-right: -1px;
}
.category-item.radius-rounded a {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}
.bottom-heading h4.sec__title.undefined,
.footer-item .footer__title,
main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs .form-area ul.comments-list li .comment-body span.comment__author {
    font-size: 16px;
    font-weight: 600;
}
.bottom-heading {
    position: absolute;
    width: 100%;
    bottom: 15px;
    text-align: center;
}
.bottom-heading .section-heading.display-inline p.sec__desc.undefined {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
}
.ConnectHeading,
.KatikaMusic .Music p.FooterMusic,
.about,
.modal-content.warning-list .alert-content h2.modal-title.mt-2.mb-1,
.partners,
.pkg-title,
.show_photo,
.steps,
.tabs_heading {
    text-align: center;
}
.tabs_heading h5.sec__title.undefined {
    font-size: 21px;
    color: #f11200;
    padding: 27px 0 0;
    line-height: normal;
    margin: 0;
    font-weight: 700;
}
.update {
    margin-top: 4px;
}
.update span {
    background: #5cd3b7;
    width: 30px;
    height: 30px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    line-height: 30px;
    border-radius: 3px;
    margin: 0 3px 0 0;
}
.update span.add-date {
    background: 0 0;
    color: #5cd3b7;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    margin: 0;
    line-height: 40px;
}
.BusinessInfo.banner_img,
.LandingPageMainBanner,
.react-tabs__tab-panel--selected,
.top-area-left,
.top-area-right,
section.hero-wrapper2.bizbanner {
    width: 100%;
    display: inline-block;
}
.tabs_heading h5.sec__title.undefined::before {
    color: transparent;
}
section.hero-wrapper2.bizbanner {
    background: top right/51% no-repeat #f5f5f5;
    position: relative;
    height: 737px;
}
.BusinessInfo.banner_img {
    padding: 0;
    position: static;
    background: 0 0;
    height: 100%;
    border-radius: 0;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu .user-information ul.UserSocialInfo li span,
.stroy-media.post-details-page.review_page .user-body .left,
li.chat-message.d-flex.reply small.pull-right.text-muted,
main .user-login-info .user-side.login span,
main.BusinessHome.BizHome header.header-area,
main.dashboard-page.billing-history nav.pull-right {
    position: static;
}
.mobile-img {
    width: 602px;
    position: absolute;
    right: auto;
    top: auto;
    left: -300px;
    margin: auto;
    bottom: 22px;
    z-index: 2;
}
.EmailKatika.E-Applications .input-box fieldset.form-group,
.MobileView .AppContent button.btn.btn-primary.rounded,
.billing-content .contact-form-action .text-danger.form-group,
.col-lg-2.column-td-6.GalleryPics:nth-child(12),
.col-lg-2.column-td-6.GalleryPics:nth-child(18),
.col-lg-2.column-td-6.GalleryPics:nth-child(6),
.dashboard-area,
.review-user ul.UserSocialInfo,
main.dashboard-page .author-inner-bio,
main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs .tabs-content ul.comments-list li .comment.listing-details,
main.dashboard-page.BuyerProfile .reviews-tabs.ManageFriends .comment.listing-details,
main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent.BuyerLocation .form-area,
main.signup-page .billing-content .contact-form-action .form-group,
section.dashboard-area.business-detail .get-direction,
section.two-col section.dashboard-area.business-detail .edit-content a span.dashboard-icon,
ul.BottomMenu li span.dashboard-icon {
    margin: 0;
}
.billing-content.BSignupForm .contact-form-action.sign-up .input-box .form-group {
    margin: 10px 0 0;
}
.billing-content .contact-form-action .input-box1 .form-group {
    margin: 20px 0 0;
}
.biz-ligh-back {
    width: 100%;
    background: #f5f5f5;
    height: 737px;
    position: relative;
}
.MobileView .biz-ligh-back {
    width: 100%;
    height: 290px;
    position: relative;
    background: url(./../images/biz-home.jpg) 0 0 / cover no-repeat;
}
.MobileView .AppContent {
    width: 75%;
    margin: 0 auto;
    color: #000;
}
.MobileView .AppContent .rounded {
    border-radius: 50px !important;
    outline: 0;
}
.MobileView .AppContent .rounded:focus,
.details-meeting .show.dropdown button:focus,
main.dashboard-page.account-page .accordion-item .card,
section.form-shared.login-page .form-group.login-btn.facebook button.theme-btn {
    box-shadow: none;
}
.BHeading h2 span,
.MobilePartner .color-primary,
.MobileView .color-primary,
.UploadPhotosHeading h1 span,
.VideoContent h1 span,
.guidelines a:hover,
.top-right .user-side a:hover span,
section.form-shared.login-page .billing-form-item.mb-0 .billing-title-wrap.border-bottom-0.pr-0.pl-0.pb-0.text-center p.font-size-16.font-weight-medium a:hover,
section.form-shared.login-page .billing-form-item.mb-0 p a:hover {
    color: #f11200;
}
.MobileView .GetStartedBtn .rounded-50 {
    border-radius: 50px;
    font-size: 20px;
}
.content-presence.BusinessInfo {
    position: static;
    padding-top: 0;
    min-height: 550px;
}
.content-presence.BusinessInfo h1 {
    color: #b30c00;
    font-size: 32px;
    font-weight: 600;
    width: 100%;
    text-align: left;
}
.content-presence.BusinessInfo p {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #000;
    line-height: normal;
    margin-top: 20px;
    width: 94%;
}
.MobileViewHead h1,
.heading h2 {
    color: #000;
    font-weight: 800;
}
.MobileView .content-presence.BusinessInfo h1 {
    color: #f11200;
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    text-align: left;
}
.MobileView .content-presence.BusinessInfo p {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #000;
    line-height: normal;
    margin-top: 20px;
    width: 94%;
}
section.breadcrumb-area.listing-detail-breadcrumb.detail-gallery .breadcrumb-content .review-rating-summary.ratings .react-rater-star,
ul.comments-list.padding-top-10px .react-rater-star {
    line-height: 25px;
    font-size: 25px;
    height: 25px;
    width: 25px;
}
.content-presence p {
    color: #000;
    font-size: 16px;
    margin-top: 20px;
}
.content-presence.BusinessInfo a.btn.btn-default,
.reviews-sidebar ul li .rating-rating {
    margin-top: 20px;
}
.GeneralButton.pull-right {
    position: static;
    width: 100%;
}
.cities.recent.posts .ReviewsWrote .media.m-round.last-col {
    flex-direction: column;
    border: 0;
    margin-bottom: 0;
    padding: 10px 15px;
}
.cities.recent.posts .GeneralButton a.btn.btn-default {
    font-size: 12px;
    position: static;
    right: 20px;
    top: 10px;
    color: #fff;
    padding: 3px 5px;
    float: right;
}
.cities.recent.posts .User_Info {
    display: flex;
    width: 100%;
    flex-direction: row;
}
.cities.recent.posts .card-item.col-last.first-col .ReviewsWrote .post-desc p.descs {
    padding: 5px 0;
    margin: 0;
    border-top: 0;
    min-height: 60px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3333;
    color: #050505;
}
.TrashIcon a span svg,
.bookmark-post-subtitle a,
.cities.recent.posts .User_Info .media-body.m-body p.m-p {
    color: #999;
}
.cities.recent.posts .User_Info img.media-object {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}
.BuyerContent .contact-form-action .form-group select.form-control,
.MobileHelp .contact-help,
.MobileView .Rectangle.Video,
.Rectangle.Video .col-lg-6.col-sm-6,
.chat-filter select.selectpicker,
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu .user-information,
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu img,
.cities.recent.posts,
.cities.recent.posts .ReviewsWrote img.card__img.img-thumbnail,
main .user-login-info .user-side.login.DropMenu #user-picture .user-information,
main.BusinessHome .testimonial-carousel .slick-list,
main.dashboard-page .header-menu-wrapper .main-menu-content,
main.dashboard-page.details-info section.dashboard-area.business-detail.social-media .y-page-link ul li:last-child,
main.home-1 .user-area #user-picture .user-information,
section.breadcrumb-area.listing-detail-breadcrumb {
    padding: 0;
}
.cities.recent.posts .card-item.col-last.first-col .media-body.item-heading.b-heading {
    margin: 0;
    padding: 5px 10px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.cities.recent.posts .card-item.col-last.first-col .media-body.item-heading.b-heading a {
    color: #fff;
    font-size: 18px;
    margin: 0;
}
.cities.recent.posts .card-item.col-last.first-col .media-body.item-heading.b-heading span {
    font-size: 11px;
    color: #e5e5e5;
}
.cities.recent.posts .card-item.col-last.first-col .media-body.item-heading.b-heading a.learn {
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    font-size: 14px;
}
.cities.recent.posts .ReviewsWrote {
    height: auto;
    min-height: auto;
    max-height: 100%;
}
main.home-1 .cities.recent.posts {
    padding-top: 50px;
}
.cities.recent.posts .card-item.col-last .user_review {
    margin: 0;
    justify-content: space-between;
    padding: 0 15px;
    border: 0;
}
.cities.recent.posts .user_review button.btn.btn-link.card__img.default span {
    font-size: 14px;
    color: #999;
}
.cities.recent.posts .ReviewsWrote .card-image {
    text-align: center;
    background: #000;
}
.card-image.slider-gallery .slick-slider {
    position: static;
    height: 100%;
    width: 100%;
}
.card-image.slider-gallery .slick-slider .slick-list {
    width: 100%;
    height: 100%;
    position: static;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.card-image.slider-gallery .slick-slider .slick-list .slick-track {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.cities.recent.posts .ReviewsWrote .card-image.slider-gallery img {
    height: 100%;
    width: 100%;
    position: static;
}
.card-image.slider-gallery .slick-next {
    right: 0;
}
.card-image.slider-gallery .slick-prev {
    left: 0;
}
.card-image.slider-gallery .slick-slider .slick-list .slick-track .slide-slider video {
    bottom: 0;
    height: 100%;
    left: 0;
    right: 0;
    background: #000;
    max-height: 550px;
    max-width: 100%;
}
main.home-1 .cities.recent.posts .card-image.slider-gallery .slick-slider .slick-list .slick-track .slide-slider video {
    background: #000;
    max-height: 600px;
    position: static;
    height: auto;
    width: auto;
    max-width: 100%;
}
.cities.recent.posts .ReviewsWrote .card-image.slider-gallery {
    z-index: 1;
}
.post-meta .comment-post-area .user-img-comment {
    width: 42px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 10px 0 0;
    object-fit: cover;
}
.user-img-comment {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 10px 0 0;
}
.post-meta .comment-post-area {
    padding: 0 10px;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
}
.main-menu-dashboard {
    padding: 15px 20px;
    background: #f11200;
    height: 72px;
}
.post-msg,
main.dashboard-page .dashboard-area .y-page-link ul.covid-point.business-price nav.nav.nav-tabs a.nav-item.nav-link {
    padding: 5px 10px;
}
.BusinessInfo.banner_img.desktop-show {
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
}
.BusinessInfo.banner_img.mobile-show,
main.dashboard-page .sidebar-widget.side-menu .info-list ul.list-items li a:hover:before,
section.breadcrumb-area.listing-detail-breadcrumb .bread-svg {
    display: none;
}
main.dashboard-page.account-page .accordion-item .card .card-header {
    background-color: #fcfcfc;
}
main.dashboard-page.account-page .accordion-item .card .card-body {
    background: #fcfcfc;
    border-top: 1px solid #ddd;
}
.reviews-tabs.SocialMedia p,
a.font-weight-medium.ForgotPass,
section.dashboard-area.security.invoice .y-page-link table.table td,
section.dashboard-area.security.invoice .y-page-link table.table th {
    font-size: 12px;
}
main.dashboard-page .dashboard-area .y-page-link ul li span.zip-code- {
    color: red;
    margin: 0 0 0 10px;
}
.cities.recent.posts .card-item {
    border-radius: 12px;
}
.cities.recent.posts .card-image.slider-gallery {
    margin: 10px 15px;
}
.cities.recent.posts .slick-initialized .slick-slide {
    display: block;
    overflow: hidden;
    background: #000;
}
.Date-TimePost h4,
.Date-TimePost h6,
.LandingPage .content-presence.BusinessInfo h1,
.PressPage.PressPages p,
.TestimonialAreaLandingContent p,
.cities.recent.posts .ReviewsWrote .media-body.m-body h4.media-heading.m-h4 a,
.header-menu-wrapper.header-fixed .owner a,
.pkg-title h1,
.pkg-title h5,
main.GalleryListing .tabs-top .rc-tabs.rc-tabs-top .rc-tabs-nav-container.rc-tabs-nav-container-scrolling .rc-tabs-tab,
section.PricePlanLanding .y-page-link h2 {
    color: #000;
}
.post-desc h1 {
    font-size: 18px;
    color: #000;
    margin: 15px 0 0;
}
.cities.recent.posts .card-item.col-last .user_review .like-commment {
    display: inline-block;
    margin: 5px 0;
}
.like-commment span {
    color: #656565;
    font-size: 14px;
}
.BuyerEmailList .collapse.show .card-body,
.user_review button.btn.btn-link.card__img.img-thumbnail.btn.btn-none {
    padding-left: 0;
    padding-right: 0;
}
.businesses,
.pkg-title h1,
.reviews-tabs .form-area,
main.listing-list.post-list.banner {
    margin-top: 20px;
}
.pkg-title h5 {
    margin: 30px 0 50px;
}
.stroy-media.post-details-page.review_page .TopHeaderLeft,
main.dashboard-page .dashboard-area .y-page-link ul li span.text-left {
    float: left;
}
.stroy-media.post-details-page.review_page .topHeaderRight {
    float: right;
    position: static;
    background: #000;
    height: 100%;
    width: 100%;
    padding-top: 30px;
    padding-left: 20px;
}
.scroll-content-inner .card .card-body p.mb-0,
.stroy-media.post-details-page.review_page .topHeaderRight h4,
main.dashboard-page section.dashboard-area.security .y-page-link.security-area .schdule-date h4 {
    font-size: 14px;
}
.stroy-media.post-details-page.review_page .topHeaderRight h6 {
    font-size: 12px;
    margin: 5px 0 10px;
}
.stroy-media.post-details-page.review_page .topHeaderRight a {
    border: 1px solid #e5e5e5;
    font-size: 13px;
    color: #fff;
    padding: 5px 14px;
    border-radius: 3px;
    margin-top: 10px;
    display: inline-block;
}
.Buyer-Reviews .reviews-tabs.ManageFriends ul.comments-list li .DeleteReviews a:hover span,
.FBDisconnectBtn button:hover,
.see-all-btn a:hover,
.stroy-media.post-details-page.review_page .topHeaderRight a:hover {
    background: #f11200;
    color: #fff;
    border-color: #f11200;
}
.stroy-media.post-details-page.review_page .details-info.story-details-info {
    margin-top: 15px;
    background: #fff;
}
.stroy-media.post-details-page.review_page .details-info.story-details-info .comment.listing-details.gallery-story {
    grid-template-columns: 35px auto;
}
.stroy-media.post-details-page.review_page .user-body .left .name {
    float: left;
    margin: 0 0 -7px;
}
.stroy-media.post-details-page.review_page .user-body .left p.comment_hour {
    color: #656565;
    font-size: 10px;
}
.stroy-media.post-details-page.review_page .story-data {
    padding-bottom: 0;
    margin: 0;
    border-bottom: 1px solid #656565;
}
.stroy-media.post-details-page.review_page .story-data h5 {
    font-size: 14px;
    font-weight: 700;
}
.stroy-media.post-details-page.review_page .story-data .description {
    line-height: normal;
    margin-top: 10px;
}
.Like_Comment {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}
.stroy-media.post-details-page.review_page .user_review {
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid #656565;
    border-top: 0;
    column-gap: 90px;
}
.stroy-media.post-details-page.review_page .user_review button.btn.btn-link.card__img.img-thumbnail.default.btn.btn-none {
    width: 110px;
    text-align: center;
    margin: 0 10px;
    color: #656565;
}
.stroy-media.post-details-page.review_page .post-meta {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}
.stroy-media.post-details-page.review_page .slick-slide img {
    display: inline-block;
    min-height: 105px;
    width: 100%;
}
.stroy-media.post-details-page.review_page .slick-slide {
    background: #000;
    position: relative;
}
.stroy-media.post-details-page.review_page .story-slider.view-story .slick-list {
    height: 100%;
    width: 440px;
    margin: 0 auto;
}
.stroy-media.post-details-page.review_page .slide-slider img,
.stroy-media.post-details-page.review_page .slide-slider video {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}
.stroy-media.post-details-page.review_page .comment-section {
    min-height: 350px;
    position: absolute;
    width: 95%;
    bottom: 8%;
    max-height: unset;
    height: 450px;
    border: 0;
}
.stroy-media.post-details-page.review_page .add-comment {
    position: absolute;
    left: 10px;
    right: 10px;
    top: auto;
    bottom: 10px;
}
.stroy-media.post-details-page.review_page .comment.storyComment {
    margin: 0 15px 5px;
}
.stroy-media.post-details-page.review_page .form-group.basic-textarea.reaction-2 .Emoji-Off,
.stroy-media.post-details-page.review_page .form-group.basic-textarea.reaction-2 .Emoji-On {
    right: 5px;
    top: 5px;
}
.stroy-media.post-details-page.review_page .form-group.basic-textarea.reaction-2 input.form-control.pl-2.my-0.storycoment {
    border-radius: 50px;
    background: #f0f2f5;
    border: 0;
    font-size: 10px;
}
.stroy-media.post-details-page.review_page .comment.storyComment .comment-body {
    margin-left: 10px;
    padding-top: 0;
    width: 100%;
}
.stroy-media.post-details-page.review_page .form-group.basic-textarea {
    margin: 10px 0 0;
    width: 100%;
}
.post-msg {
    width: auto;
    background: #f0f2f5;
    display: inline-block;
    border-radius: 8px;
}
.CommShare,
.MainLandingImg {
    width: 100%;
    display: inline-block;
}
.main-menu-content.BHome,
.main-menu-content.listin_nav,
main.listing-list.post-list .comment-time {
    text-align: left;
    padding: 0;
}
main.listing-list.post-list .comment-time button.btn.btn-link.card__img.img-thumbnail.btn.btn-none {
    padding: 0;
    margin: 0 15px 0 0;
    width: auto;
    font-size: 12px;
    color: #8991a0;
    font-weight: 500;
}
span.time-com {
    color: #b4bbc6;
    font-size: 10px;
    margin: 0 0 0 10px;
}
.comment.storyComment.reply-coment {
    margin: 0 15px 5px 35px;
    display: flex;
    flex-direction: column;
}
.coment-user {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}
.coment-user .meta-data {
    align-items: center;
    height: 36px;
    padding: 6px 0;
    margin: 0 0 0 5px;
}
.EmailKatika.E-Applications .input-box fieldset.form-group .form-check label.form-check-label,
.coment-user .meta-data span.comment__author {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
main.listing-list.post-list aside.emoji-picker-react {
    margin: 10px auto 0;
}
main.BusinessHome.FaqPages.Community ul.Community-Posts li {
    display: grid;
    grid-template-columns: 270px auto;
    border: 1px solid #ddd;
    padding: 1rem;
    grid-column-gap: 20px;
}
.PostedDetails {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
}
.PostedUser {
    text-align: right;
    color: #000;
}
.PostTitle {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
}
.PressPage .PostTitle h2 {
    text-align: left;
    font-weight: 600;
    font-size: 26px;
}
.PressPage .PostDesc p {
    width: auto;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    margin: 10px 0 0;
}
.Date-TimePost h6 {
    margin: 10px 0;
}
.CommDetail button.btn.btn-primary {
    margin: 25px 0 0;
    padding: 5px 15px;
}
.Upcomming h4 {
    color: #f11200;
    font-weight: 700;
    margin: 0 0 30px;
    font-size: 24px;
}
.CommShare {
    text-align: center;
    margin-bottom: 70px;
}
.CommShare h4 {
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
}
.CommShare img.Slack.icon {
    width: 40px;
    margin: -8px 15px 0 0;
    display: inline-block;
}
.CommShare a {
    font-size: 30px;
}
.businesses.Community a {
    width: auto;
    padding: 8px 20px;
}
ul.partners-bullet {
    list-style: disc;
    padding-left: 30px;
    margin-top: 20px;
    font-weight: 400;
}
.partnersBottom h2 {
    font-size: 24px;
    font-weight: 700;
}
.partnersBottom button {
    min-width: 224px;
    max-width: 225px;
    font-weight: 700;
    min-height: 50px;
}
.OfficialPartners h2 {
    font-weight: 800;
    color: #f11200;
}
section.TeamItem.about-team .Items {
    width: 292px;
    margin: 0 30px 0 0;
    border-radius: 5px;
}
section.TeamItem.about-team .Items p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
}
.communityVideo {
    max-width: 660px;
    margin: 0 auto;
}
.PressPage.communityPage h2,
main.BusinessHome.FaqPages.Labs .PressPage h2 {
    text-transform: capitalize;
}
.pkg-title h1 {
    font-size: 32px;
    font-weight: 900;
}
.PressPage.PressPages h2,
main.BusinessHome.FaqPages.FAQ .PressPage h2,
main.BusinessHome.FaqPages.GetHelp .PressPage h2,
main.BusinessHome.FaqPages.Partners .PressPage h2 {
    text-transform: none;
}
main.BusinessHome.FaqPages.GetHelp .PressPage p {
    width: 494px;
    color: #000;
}
.LandingTopBar {
    background: #000;
    padding: 10px 0;
}
.FBDisconnectBtn button:hover svg,
.LandingTopBar p,
.TrashIcon a:hover span svg,
.close-list button:hover,
.main-menu-content nav ul li a,
.user-side.directory .notification span,
main.BusinessHome.AboutUs .CommonBreadcrumb h1,
main.BusinessHome.AboutUs .CommonBreadcrumb p {
    color: #fff;
}
main.BusinessHome.BizHome.LandingPage section.hero-wrapper2.bizbanner {
    background: #f6f3ee;
    height: 675px;
}
.LandingPage .biz-ligh-back,
.collaps-sidebar .icon,
.copy-right:after,
.signup-picture .user-img-place,
main.BusinessHome .slick-slider .slick-arrow:hover,
main.BusinessHome.FaqPages.GetHelp.BizHome.dashboard-page.Plans.account-page .main-menu-content.BHome,
ul.list-items.SocialIcons li a:before {
    background: 0 0;
}
.BuyerContent .contact-form-action textarea.message-control.form-control,
.LandingPage .logo.listing.webapp.LandingLogo_b img,
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu,
main.dashboard-page.BuyerProfile .sidebarBuyer ul.list-items li,
main.home-1 .main-menu-content .user-side.login,
main.listing-details .modal-content.p-4 {
    height: auto;
}
.MainHeadingLanding h1 {
    color: #000;
    font-weight: 700;
    font-size: 62px;
}
.MainHeadingLanding p {
    color: #000;
    font-size: 20px;
    font-weight: 600;
}
.MainLandingImg video {
    max-width: 700px;
}
.MainLandingImg {
    margin-top: -180px;
    position: relative;
    z-index: 1;
}
.SignUpBtnLandingPage button {
    background: #da082c;
    border: 0;
    border-radius: 6px;
    padding: 10px 50px;
    color: #fff;
}
.LandingForm button.btn.btn-primary,
.LandingForm input {
    border-radius: 15px;
}
section.WhoWeAre {
    background: #f6f3ee;
    margin-top: 130px;
}
.arrow-up {
    width: 0;
    height: 0;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-bottom: 35px solid #f6f3ee;
    margin: 0 auto;
    position: relative;
    top: -35px;
}
.WhoWeAreContent h1 {
    text-align: center;
    font-weight: 600;
    color: #000;
    margin-bottom: 40px;
    margin-top: 30px;
}
.WhoWeAreContent p {
    margin-bottom: 15px;
    color: #000;
}
.LandingHedingHowIt h1,
.SocialMediaName .SocialName.SocialMediaDescp h6 {
    color: #000;
    font-weight: 600;
}
.LandingPage .content-presence.BusinessInfo {
    min-height: auto;
    padding: 0;
}
.LandingPage .content-presence.BusinessInfo h1,
.LandingPage .content-presence.BusinessInfo p {
    text-align: unset;
    width: 100%;
}
section.TestimonialAreaLanding {
    background: #fef9ea;
}
.SubHedingLanding h4 {
    font-weight: 400;
    color: #000;
}
.SubHedingLanding h1 {
    font-weight: 600;
    color: #232323;
    margin: 13px 0;
}
.SubHedingLanding h6 {
    color: #000;
    margin-bottom: 15px;
}
.BrainhubCarousel__container .BrainhubCarousel .BrainhubCarousel__trackContainer,
.FullBgMobile,
button.Btns.Request,
section.PricePlanLanding {
    background: #000;
}
.main-search-input-item.search-input button.button.theme-btn,
.theme-btn1 {
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f11200;
    display: inline-block;
    font-size: 22px;
    box-shadow: none;
}
section.PricePlanLanding section.dashboard-area.account-page {
    background: #fff;
    border-radius: 15px;
    min-height: 540px;
}
section.LandingFooter h1,
section.PricePlanLanding .pkg-title h1 {
    color: #fff;
    font-weight: 600;
}
.SignMeLanding h2,
.SignMeLanding p {
    color: #000;
    text-align: center;
    font-weight: 600;
}
.SignMeLanding p {
    font-weight: 400;
}
ul.SignMeUpPoints p {
    color: #000;
    font-weight: 500;
}
.LandingForm input {
    min-height: 50px;
}
section.LandingFooter {
    background: #000;
    padding: 50px 0 30px;
    text-align: center;
}
section.LandingFooter h3 {
    margin-bottom: 20px;
    color: #fff;
}
.SignUpBtnLandingPage a.btn.btn-primary {
    background: #da082c;
}
section.PricePlanLanding .y-page-link h5,
section.PricePlanLanding .y-page-link h6 {
    text-transform: uppercase;
    margin: 0 0 15px;
}
section.PricePlanLanding .y-page-link h5 {
    color: #000;
    text-transform: none;
}
section.PricePlanLanding .y-page-link h2 sub {
    bottom: 0;
}
ul.PackageDetails p {
    margin-left: 8px;
    color: #000;
}
.slide-slider.addIframe iframe {
    width: 100%;
    height: 450px;
}
@media only screen and (max-width: 767px) and (min-width: 480px) {
    .main-container {
        text-align: left;
        padding-top: 200px;
        padding-bottom: 140px;
    }
}
.header-area {
    top: 0;
    width: 100%;
    z-index: 1010;
}
.main-container {
    background-image: url("../images/Profole_1602164886_864.jpg") !important;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    z-index: 1;
    color: #fff;
    text-align: center;
    padding-bottom: 50px;
    height: 700px;
    margin-bottom: 80px;
}
.header-menu-wrapper.header-fixed,
section.dashboard-area.business-detail .y-page-link {
    padding-bottom: 15px;
}
.theme-btn1 {
    font-weight: 600;
    color: #fff;
    line-height: 40px;
    text-transform: capitalize;
    transition: 0.3s;
    position: relative;
    border-radius: 0 4px 4px 0;
    border: 0;
}
.owner,
.owner a {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
.main-search-input-item.search-input button.button.theme-btn:focus,
.theme-btn1:focus {
    outline: 0;
}
.contact-form-action .form-control.c1 {
    line-height: inherit;
    padding: 12px 20px 12px 70px;
    font-size: 16px;
    color: #333;
    border: 0;
    resize: none;
    font-weight: 500;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.owner {
    margin-top: 57px;
    padding-left: 35px;
}
.main-menu-content nav ul li:last-child:hover {
    border-color: #ff6b6b;
}
.owner a {
    border-radius: 0;
    border-bottom: 2px solid transparent;
    padding-bottom: 8px;
}
.owner a:hover {
    color: #fff;
    border-bottom-color: #fff;
}
.search-category {
    margin-top: 106px !important;
    width: 100%;
}
.main-search-input {
    background-color: transparent !important;
}
.change-password,
.change-password .form-area,
.highlighted-categories,
.margin-top-0px,
.reviews-tabs.ManageFriends .form-area,
main.home-1 .hover-active-details .rating-rating.rating-detail.landing {
    margin-top: 0;
}
.part {
    width: 168px;
    display: inline-block;
    margin: 0 10px 0 0;
    border: 1px solid #e6e6e6;
    border-radius: 3px 3px 0 0;
}
.top-info,
.user-desc {
    width: 100%;
}
.part:last-child {
    margin-right: 0;
}
.part .like_class {
    padding: 0 0 5px 5px;
    margin: 0;
    border-top: 0;
}
.show_photo h4.media-heading.m-h4 a {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding: 10px 0 0;
    display: inline-block;
}
.cities.recent .media-body.item-heading.b-heading {
    margin-top: 10px;
    border-bottom: 1px solid #eeeeef;
    padding-bottom: 10px;
}
p.descs {
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
}
.user_review {
    padding: 0;
    border-top: 1px solid #eeeeef;
    margin-top: 5px;
}
.cities.recent .card-item.col-last.first-col p.descs,
.user-desc {
    padding: 0 10px;
}
.cities.recent .media.m-round.last-col {
    margin-bottom: 0;
    padding: 10px;
}
.user-desc {
    display: inline-block;
}
.cities.recent .card-item.col-last .rating-rating {
    border-top: 1px solid #eeeeef;
    padding: 15px 0 0;
    margin: 42px 0 0;
}
.cities.recent .card-item.col-last.first-col .media-body.item-heading.b-heading {
    margin: 10px 10px 0;
}
.card-item.col-last .user_review {
    margin: 5px 10px 0;
}
.cities.recent .card-item.col-last.first-col .rating-rating {
    border: 0;
    padding: 0;
    margin: 10px 10px 0;
}
.category.text-center {
    background: #f5f5f5;
    padding: 40px 0 70px;
    margin-top: 50px;
}
.category.text-center h2,
.katika-app h2 {
    font-size: 21px;
    font-weight: 700;
    color: #f11200;
}
.main-menu-content.listin_nav a,
.single-listing-wrap.details h2.widget-title,
.top-right a,
main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul.covid-point li label.review-label {
    color: #2b273c;
}
.category.text-center .mt-5 {
    margin-top: 30px !important;
}
.katika-app {
    background: #fff;
    padding: 60px 0 120px;
}
.katika-app .mt-5 {
    margin-top: 35px !important;
}
.main-menu-content.listin_nav ul.dropdown-menu-item {
    top: 48px;
    max-height: 395px;
    overflow: auto;
}
.comment .comment-body {
    margin-left: 16px;
    padding-top: 10px;
}
.main-menu-content.listin_nav nav ul.dropdown-menu-item li a,
.main-menu-content.listin_nav nav ul.dropdown-menu-item li:first-child a {
    padding-left: 25px;
}
.logo.listing {
    padding: 35px 0 0;
}
.search-feild {
    background: #fff;
    border: 0.5px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-top: 30px;
    height: 48px;
    position: relative;
}
.main-search-input-item.search-input .contact-form-action form {
    display: grid;
    grid-template-columns: auto 1px 300px 50px;
}
span.HBorder {
    width: 1px;
    height: 25px;
    background: #c4c4c4;
    margin-top: 10px;
}
main.home-1 span.HBorder {
    position: relative;
    z-index: 111;
    margin-top: 10px;
}
.main-search-input-item.location .css-2b097c-container,
.main-search-input-item.search-input input.form-control {
    border: 0;
    padding: 12px 0 12px 15px;
    width: 100%;
    height: 45px;
}
.main-search-input-item.location .css-2b097c-container .css-yk16xz-control {
    border: 0;
    border-radius: 0;
    border-left: 2px solid #c4c4c4;
    min-height: 46px;
}
.main-search-input-item.location .css-2b097c-container {
    padding: 0;
    height: 46px;
}
.main-search-input-item.search-input button.button.theme-btn {
    width: 50px;
    font-weight: 600;
    color: #fff;
    line-height: 45px;
    text-transform: capitalize;
    transition: 0.3s;
    position: absolute;
    border-radius: 0 4px 4px 0;
    border: 0;
    right: 0;
    top: 0;
}
.top-right {
    margin-top: 0px;
    /* display: grid;
    grid-template-columns: 127px auto; */
}
.top-right.user-login-info.MarginTop{
    margin-top: 30px;
}
.top-info,
.user-area {
    display: grid;
    grid-template-columns: auto auto;
}
.user-side.Chat a,
.user-side.directory a {
    height: 35px;
    min-width: 35px;
    background: 0 0;
    line-height: 35px;
    margin-top: -8px;
    border-radius: 50%;
    display: inline-block;
}
.top-right a {
    border-radius: 3px;
    font-size: 13px;
    height: 24px;
    font-weight: 600;
    display: inline-block;
}
.collaps-sidebar .sidenav---sidenav-nav---3tvij .active,
.top-right .bell-icon a:hover,
.top-right a:hover,
.user-side.Chat a:hover,
.user-side.directory a:hover,
main.dashboard-page .sidebar-widget.side-menu .info-list ul.list-items li a.active,
main.dashboard-page .sidebar-widget.side-menu .info-list ul.list-items li a:hover {
    background: #f0f0f0;
}
.top-right .business {
    padding: 12px 0;
    display: inline-block;
}
.main-menu-content.h-fixed {
    padding: 15px 0 0;
}
.header-menu-wrapper.header-fixed .owner,
.margin-top-25px {
    margin-top: 25px;
}
.header-menu-wrapper.header-fixed .owner a:hover {
    color: #ec1b20;
}
.user-area {
    text-align: right;
    padding: 12px 0;
}
.user-side {
    font-size: 10px;
    font-weight: 400;
    text-align: center;
}
.user-side.directory,
.user-side.login {
    height: auto;
    display: inline-block;
    position: relative;
}
.user-side.directory span,
.user-side.login span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: #2b273c;
    font-weight: 400;
    font-size: 12px;
}
main .user-login-info .user-side.login {
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main .user-login-info .user-side.login img {
    height: 28px;
    width: 28px;
    border-radius: 50%;
}
main.home-1 .main-menu-content .user-area {
    grid-template-columns: auto;
    padding: 0;
}
main.home-1 .main-menu-content nav ul li a {
    /* width: 130px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    padding-right: 10px;
}
main.home-1 .main-menu-content .user-side.login span {
    position: static;
    color: #fff;
}
main.home-1 .main-menu-content nav ul li a:hover {
    color: #ff6b6b;
    /* padding: 10px; */
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu .user-profile img,
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu img,
main .user-login-info .user-side.login.DropMenu img,
main.home-1 .main-menu-content .user-side.login img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
}
main.home-1 .main-menu-content .user-side.login .bell-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
}
main .user-login-info .user-area {
    grid-template-columns: 40px 40px 110px;
}
.user-side.login img {
    height: 28px;
}
.user-side a,
.user-side a:hover,
main.BusinessHome.AboutUs .Rectangle.Video {
    padding: 0;
    background: 0 0;
}
.rating-row.stars {
    padding: 5px 0 10px;
    display: inline-block;
    border: 0;
}
.card-item.card-listing.d-flex.listing-page {
    margin-bottom: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    transition: box-shadow 0.3s;
}
.card-listing .card-content-wrap.lists {
    flex: 2 1;
    border-radius: 0 4px 4px 0;
    border: 0;
}
.card-item.card-listing.d-flex.listing-page a.card-image-wrap {
    padding: 10px 0 10px 10px;
}
.rating-rating span svg,
.rating-row.stars .rating-rating svg,
main.listing-details .btn-box button.theme-btn.border-0.button-success.mr-1 svg {
    vertical-align: top;
}
.card-content-wrap.lists .card-content {
    font-size: 12px;
    line-height: normal;
}
.TotalFriends .BookMark span.Icon svg,
.TotalFriends .Friends svg,
.rating-row.stars .rating-rating span svg,
main.dashboard-page .dashboard-area .y-page-link .ActivityDetail span.ActivityText,
main.dashboard-page .sidebar-widget.side-menu .info-list ul.list-items li a svg {
    font-size: 16px;
}
.rating-row.stars .rating-rating span.rating-count {
    top: 0;
    margin-top: 2px;
}
.tabs-top,
main.dashboard-page .menu-full-width .owner,
p.card-desc {
    margin-top: 10px;
}
.card-listing .card-image-wrap .card-image img.card__img {
    height: 200px;
}
.card-content-wrap.lists .card-content p.card-desc {
    margin: 10px 0 15px;
    display: inline-block;
    font-size: 14px;
}
.bottom_buttons {
    background: #f4f4f4;
    border-radius: 4px;
    width: 100%;
    padding: 8px 4px;
    border-color: #eeeeef;
}
.bottom_buttons a.btn-link {
    margin: 0 4px;
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
}
.bottom_buttons button.r-button-d-block {
    vertical-align: top;
    height: 40px;
    padding: 0 20px;
    margin-left: 4px;
    margin-right: 4px;
}
.bottom_buttons a,
.bottom_buttons button {
    background-color: #fff;
    border: 1px solid #bbbac0;
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
.bottom_buttons a:hover,
.bottom_buttons button:hover {
    background-color: #c4c4c4;
    border-color: #c4c4c4;
    color: #000;
}
.card-content-wrap.lists h4.card-title {
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}
.address {
    display: inline-block;
    text-align: right;
    margin-top: 12px;
}
.address ul {
    line-height: 15px;
}
ul.rating-list.distance li span.la-star {
    color: #333;
    font-size: 14px;
    font-weight: 400;
}
.showmore-btn.filter {
    border: 0;
    box-shadow: none;
    color: #326d62;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 10px;
}
.filter-category {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}
.showmore-btn.filter:hover {
    background: 0 0;
    color: #f11200;
}
.sidebar.listing-page .sidebar-widget {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
}
.SeeAbout .input-box:last-child,
.sidebar.listing-page .sidebar-widget:last-child,
main.dashboard-page .dashboard-area .y-page-link ul li:last-child {
    border-bottom: 0;
}
.sidebar.listing-page {
    border: 0;
    padding: 20px 0 40px;
    position: sticky;
    top: 155px;
}
.listing-list.post-list .sidebar.listing-page {
    top: 130px;
}
.ListingBox {
    display: flex;
    flex-direction: row;
}
.sidebar.listing-page .custom-checkbox input[type="checkbox"]:checked + label:before {
    background-color: #326d62;
    border-color: #326d62;
}
.sidebar.listing-page .custom-checkbox.pop-check-box input[type="checkbox"]:checked + label:before {
    z-index: 0;
}
.sidebar.listing-page .rating-list li .review-label .review-mark:before,
main.dashboard-page .dashboard-area .y-page-link ul li .review-label .review-mark:before {
    background-color: #326d62;
}
.sidebar.listing-page .rating-list li .review-label input:checked ~ .review-mark,
main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul.covid-point li .review-label input:checked ~ .review-mark {
    border-color: #326d62;
}
.chat-area .chat-empty h5,
.noti-heading h2,
h2.all-result,
h2.heading-listing {
    font-size: 20px;
    font-weight: 600;
    color: #000;
}
li.chat-message .card-body p.mb-0,
main.listing-list h6.pb-1 {
    font-size: 13px;
}
h2.heading-listing {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
}
.filter-category ul {
    list-style: circle;
}
.filter-category ul li.custom-checkbox.filter-c {
    display: inline-block;
    margin: 0 5px;
    position: relative;
}
.filter-category ul li.custom-checkbox.filter-c label {
    display: inline-block;
    margin: 0 0 0 8px;
    font-size: 12px;
    font-weight: 400;
}
.filter-category ul li.custom-checkbox.filter-c span {
    width: 3px;
    height: 3px;
    background: #757280;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 12px;
}
.sidebar.listing-page .showmore-btn.filter {
    font-size: 13px;
    width: auto;
}
h2.all-result {
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 20px;
}
.SelectPrice .check-box-list.filter-category.mt-4 {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px !important;
}
.SelectPrice .check-box-list.filter-category.mt-4 .custom-checkbox {
    display: inline-block;
    width: auto;
}
.SelectPrice {
    display: inline-block;
    border: 1px solid #a3a3a3;
    border-radius: 100px;
    overflow: hidden;
    margin-bottom: 10px;
}
.SelectPrice .custom-checkbox {
    display: inline-block;
    border-left: 1px solid;
    margin: 0;
    width: 50px;
    text-align: center;
}
.SelectPrice .custom-checkbox:first-child {
    border-left: 0;
    position: relative;
}
.SelectPrice .custom-checkbox input[type="checkbox"]:not(:checked) + label:before,
.custom-checkbox input[type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 18px;
    height: 18px;
    border: 0;
    background-color: #fff;
    border-radius: 0;
    z-index: -1;
}
.ListingLeftSide {
    flex: 0.50535 0 0;
    position: relative;
    padding: 40px 20px 100px;
}
.listing-list.post-list .ListingLeftSide {
    padding: 0 20px 0;
    margin-bottom: 00px;
}
.sidebar.listing-page .SelectPrice .custom-checkbox input[type="checkbox"]:checked + label:before {
    background-color: #f11;
    border-color: #f11;
    width: 100%;
    overflow: hidden;
    height: 25px;
    top: 0;
}
.InstaPhoto.SelectPrice .custom-checkbox input[type="checkbox"]:checked + label:before {
    z-index: 1;
    height: 120px;
    width: 120px;
    background: 0 0;
    border: 2px solid #f11200;
    top: 0;
}
.map-side {
    flex: 0.49465 0 295px;
    padding: 0;
}
.map-side.sticky,
.side-bar-left.sticky {
    top: 136px;
    z-index: 99;
    min-width: 294px;
    right: 0;
    max-width: 100%;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    height: 100%;
    padding: 0;
    color: #fff;
    bottom: auto;
}
.covid.two,
main.GalleryListing ul.react-tabs__tab-list li {
    padding-bottom: 15px;
}
.side-bar-left.sticky {
    left: 25px;
    right: auto;
    min-width: 240px;
    background: #fff;
}
.b-listing.gap-left {
    margin-left: 250px;
}
.side-bar-left.sticky::after .b-listing {
    padding-left: 250px;
}
.MobileView .VideoContent,
.MobileView .VideoContent .ratio,
.VideoContent,
.map-height {
    height: 100%;
}
.SelectPrice .custom-checkbox input[type="checkbox"]:checked + label:after {
    opacity: 0;
    visibility: visible;
}
.SelectPrice .custom-checkbox input[type="checkbox"]:not(:checked) + label,
.custom-checkbox input[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 0;
    font-size: 13px;
}
.review-rating-summary.ratings span.la.ml-0 svg {
    vertical-align: top;
    font-size: 22px;
}
.review-rating-summary.ratings .stats-average__rating .rating-rating.d-flex {
    display: inline-block !important;
}
.review-rating-summary.ratings p.stats-average__rating-rating {
    display: inline-block;
    margin: 0 0 0 10px;
    position: relative;
    top: 2px;
}
.course-rating-text {
    display: inline-block;
    margin: 0 0 0 10px;
    position: relative;
    top: -3px;
}
.course-rating-text a {
    background: #7d7d7d;
    color: #fff;
    padding: 2px 5px;
    border-radius: 2px;
    display: inline-block;
}
.review-rating-summary.ratings span.la.ml-0 {
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 1px 0;
}
.claimed {
    display: inline-block;
    width: 100%;
    margin-top: 6px;
}
.claimed a span.icon {
    background: #5cd3b7;
    color: #fff;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    line-height: 18px;
    display: inline-block;
    margin-top: 5px;
    font-size: 20px;
    text-align: center;
}
.claimed a span {
    display: inline-block;
    color: #5cd3b7;
    font-size: 18px;
}
.product-img {
    width: 180px;
    border: 1px solid #e5e5e5;
}
.product-img img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
}
.claimed .user-name span.dot {
    height: 3px;
    width: 3px;
    background: #fff;
    margin: 4px 5px;
    border-radius: 10px;
}
.BusinessHour {
    display: inline-block;
    margin-top: 10px;
}
.BusinessHour span.open {
    color: #bffeeb;
    font-weight: 600;
}
.BusinessHour span.rest-info {
    color: #bffeeb;
}
.BusinessHour span.rest-info img {
    display: inline-block;
    margin: -3px 0 0 10px;
}
.breadcrumb-area:before,
main.signup-page.BizSignUp .k-class.white-bg .copy-right:after {
    background-color: transparent;
}
.breadcrumb-area {
    height: 400px;
}
.MidCustomWidth h2,
.MidCustomWidth h6,
.MobileAbout .partners,
.craousel-slider .comments-list,
.write-review,
main.BusinessHome.AboutUs .MobileAbout .Rectangle.Video .VideoContent p {
    text-align: left;
}
.write-review a,
.write-review button {
    border: 1px solid #bbbac0;
    border-radius: 3px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #2b273c;
    margin: 0 5px;
}
.write-review a:hover,
.write-review button:hover,
main.dashboard-page .dashboard-area .y-page-link ul.pagination.justify-content-end.asrt-pagination li.page-item.active a.page-link {
    background: #f11200;
    border-color: #f11200;
    color: #fff;
}
.covid h2.covid-head {
    font-size: 16px;
    font-weight: 600;
    color: #2b273c;
}
.covid ul {
    display: grid;
    grid-row-gap: 10px;
    margin: 15px 0;
}
.covid.two {
    border-bottom: 1px solid #eeeeef;
}
.businesses h2.business-head,
main.dashboard-page .dashboard-area .y-page-link h2,
main.dashboard-page .y-page-link.reviews h4 {
    font-size: 20px;
    font-weight: 600;
    color: #2b273c;
}
.get-direction p.ShopAddress,
.video-desc p {
    font-size: 14px;
    line-height: 21px;
}
.businesses a {
    background: #f11200;
    border-radius: 3px;
    padding: 7px 10px;
    color: #fff;
    margin: 10px 0 0;
    display: inline-block;
}
.businesses a svg {
    font-size: 24px;
    display: inline-block;
}
.EmailAdd button:hover,
.PressKitBtn a:hover,
.PressKitBtn button:hover,
.api-key a.theme-btn.enter:hover,
.api-key a.theme-btn.remove:hover,
.businesses a:hover,
.password.save-button a.theme-btn.save-changes:hover,
.post-btn button.btn.btn-secondary:hover {
    opacity: 0.7;
}
.video-desc {
    width: 100%;
    display: inline-block;
    margin: 15px 0 0;
}
.list-items li.d-flex.days_time .days {
    width: 50px;
}
.get-direction {
    width: 188px;
    display: inline-block;
    margin-top: 25px;
}
.get-direction h3.heading-direction {
    font-size: 14px;
    font-weight: 600;
    color: #2b273c;
    line-height: 21px;
}
.get-direction p.ShopAddress {
    margin: 0;
    color: #2b273c;
}
.direction-btn {
    display: inline-block;
    text-align: center;
    width: 180px;
}
.direction-btn a {
    border: 1px solid #bbbac0;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0 15px;
    color: #2b273c;
    display: inline-block;
    height: 37px;
    line-height: 37px;
}
.direction-btn a:hover {
    color: #fff;
    background: #f11200;
    border-color: #f11200;
}
.single-listing-wrap.details .comments-wrap {
    border-top: 1px solid #eeeeef;
    margin-top: 60px;
    padding-top: 30px;
}
.single-listing-wrap.details .comment .comment-body .comment__author {
    font-size: 16px;
    color: #0073bb;
}
.photo_number {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 0 5px;
    display: inline-block;
    color: #595959;
}
.comment.listing-details .comment .comment-body .comment__date {
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    width: 100%;
    color: #2b273c;
}
.comment.listing-details .rating-rating {
    position: static;
    margin-top: 15px;
}
.comment.listing-details .rating-rating svg {
    vertical-align: middle;
}
.comment.listing-details .rating-rating span {
    color: #fff;
    display: inline-block;
    background: #ff4f00;
    width: 25px;
    height: 25px;
    margin: 0 3px 0 0;
    border-radius: 2px;
    text-align: center;
}
.comment.listing-details .rating-rating span.comment-date {
    background: 0 0;
    width: auto;
    color: #666;
    font-size: 14px;
    line-height: normal;
    height: auto;
    vertical-align: middle;
}
.comment.listing-details .comment-content {
    line-height: 21px;
    margin-top: 15px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #2b273c;
    font-size: 14px;
}
.product-img {
    display: inline-block;
    margin: 10px 15px 0 0;
    border-radius: 3px;
    position: relative;
    height: 180px;
    padding-top: 23%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.15);
}
.list-items li.d-flex.days_time span.closed-now {
    background: 0 0;
    margin: 0 0 0 15px;
    font-weight: 600;
    color: red;
}
.single-listing-wrap.details .listing-description {
    border-bottom: 1px solid #eeeeef;
    margin-bottom: 30px;
}
.sidebar.section-bg.details-sidebar {
    padding: 0;
    background: #fff;
    border: 0;
    position: sticky;
    top: 180px;
}
.sidebar.section-bg.details-sidebar .sidebar-widget {
    padding: 15px;
    display: inline-block;
    width: 100%;
    border: 1px solid rgba(128, 137, 150, 0.2);
    border-radius: 3px;
    margin-bottom: 20px;
}
.sidebar.section-bg.details-sidebar .author-inner-bio h4.author__title.font-weight-bold.pb-0.mb-1 {
    font-size: 20px;
    color: #2b273c;
    font-weight: 600 !important;
}
.sidebar.section-bg.details-sidebar .author-inner-bio p.author__meta {
    font-size: 16px;
    color: #2b273c;
    border-bottom: 4px solid #f11;
    display: inline-block;
    border-radius: 2px;
}
.sidebar.section-bg.details-sidebar a.theme-btn.d-block {
    background: #f11200;
    font-size: 16px;
    font-weight: 600;
}
.sidebar.section-bg.details-sidebar a.theme-btn.d-block:hover {
    background: #ea5555;
}
.sidebar.section-bg.details-sidebar .static-info li .la {
    color: #2b273c;
    background-color: transparent;
    float: right;
    line-height: 50px;
}
.sidebar.section-bg.details-sidebar ul li,
main.dashboard-page .dashboard-area .y-page-link ul li {
    flex-basis: 100%;
    border-bottom: 1px solid #eeeeef;
    margin: 0;
    height: 50px;
    padding: 0;
    line-height: 50px;
}
.sidebar.section-bg.details-sidebar .info-list.static-info.padding-top-0px i svg {
    font-size: 24px;
}
.sidebar.section-bg.details-sidebar ul li a,
main.dashboard-page .dashboard-area .y-page-link ul li a {
    color: #2b273c;
    font-size: 16px;
    margin: 0 10px;
}
.sidebar.section-bg.details-sidebar ul li button {
    color: #2b273c;
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
}
.sidebar.section-bg.details-sidebar ul li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}
.sidebar.section-bg.details-sidebar .sidebar-widget.amenities li {
    border: 0;
    height: 30px;
}
.sidebar.section-bg.details-sidebar .sidebar-widget.amenities li i svg {
    color: #000;
    margin: 0 10px 0 0;
    font-size: 22px;
    line-height: normal;
    display: inline-block;
}
.sidebar.section-bg.details-sidebar .sidebar-widget.amenities li a.d-flex.align-items-center.before-none span {
    width: 35px;
    text-align: left;
    background: 0 0;
    display: inline-block;
}
section.form-shared.login-page .billing-form-item.mb-0 {
    box-shadow: none;
    border: 0;
}
section.form-shared.login-page .billing-form-item.mb-0 h3.widget-title.font-size-28.pb-0 {
    color: #000;
    font-size: 28px !important;
}
section.form-shared.login-page .billing-form-item.mb-0 .billing-title-wrap.border-bottom-0.pr-0.pl-0.pb-0.text-center p.font-size-16.font-weight-medium {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #000;
    display: inline-block;
    margin: 0 0 30px;
    text-align: center;
}
section.form-shared.login-page .billing-form-item.mb-0 p {
    width: 94%;
    margin: 0 auto;
    color: #000;
    font-size: 12px;
}
.form-group.login-btn button,
.form-group.login-btn span,
.form-group.login-btn span button.theme-btn {
    width: 320px !important;
    margin: 0 auto;
    display: block;
    box-shadow: none;
    background: 0 0;
    height: 46px;
    padding: 0;
}
section.form-shared.login-page .form-group.login-btn.facebook button.theme-btn svg {
    color: #4267b2;
}
element.style {
    padding: 10px 10px 10px 0;
    font-weight: 500;
}
.billing-content .contact-form-action form .form-group.login-btn.google button span {
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 3px;
    color: #000;
    padding: 0 !important;
    font-weight: 400 !important;
}
section.form-shared.login-page .form-group.login-btn.apple button.theme-btn {
    background: #000 !important;
}
.form-shared.login-page .contact-form-action,
.form-shared.login-page .input-box input.form-control,
section.form-shared.login-page .input-box {
    width: 100%;
    margin: 0 auto;
}
main.signup-page section.form-shared.login-page .input-box {
    margin: 0 auto 20px;
}
main.signup-page .signup-picture .content-area-signup {
    text-align: left;
    width: 80%;
    margin: 0 auto 15px;
}
section.form-shared.login-page .input-box input.form-control {
    padding: 12px 20px 12px 10px;
    height: 34px;
    font-size: 12px;
    font-weight: 400;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 6px;
    background: #f9f9f9;
    color: #000;
}
section.form-shared.login-page .custom-checkbox a {
    font-size: 12px;
    margin: 0;
    display: inline-block;
    color: #0073bb;
}
.btn-box button.theme-btn.btn-login {
    width: 100%;
    background: #f11200;
    margin-bottom: 10px;
}
section.form-shared.login-page .billing-form-item.mb-0 p.font-weight-medium {
    width: 100%;
    text-align: right;
    font-size: 12px;
    font-weight: 400 !important;
}
.user-img-place {
    width: 400px;
    height: 400px;
    border-radius: 100%;
    display: block;
    background: #c4c4c4;
    margin: 0 auto;
}
.section-devid {
    display: block;
    width: 90%;
    margin: 30px auto;
    text-align: center;
    position: relative;
    height: 30px;
}
.line,
.section-devid span {
    top: 4px;
    bottom: 0;
    margin: auto;
    right: 0;
    left: 0;
}
.line {
    width: 100%;
    height: 1px;
    background: #a3a3a3;
    position: absolute;
}
.section-devid span {
    position: absolute;
    background: #fff;
    width: 45px;
}
.section-devid.NoAccount span {
    width: 260px;
}
.btn.btn-outline-primary.signupBtn {
    border: 2px solid #f11200;
    background: 0 0;
    color: #f11200;
}
.form-group.first input.form-control,
.form-group.last input.form-control {
    width: 156px;
}
.col-lg-6.user-info.sign-up {
    width: auto;
    padding: 0 0 0 15px;
    max-width: 48%;
    height: auto;
    border: 0;
    background: 0 0;
    margin: 0;
}
.col-lg-6.user-info {
    padding: 0 0 0 15px;
    max-width: 47%;
}
.input-box.date-of-birth .col-md-4 {
    max-width: 33%;
    padding: 0 2px;
}
.input-box.date-of-birth .col-md-4 .css-yk16xz-control {
    padding: 3px 0 3px 3px;
}
.direction-btn {
    margin: 0;
    position: relative;
    top: 25px;
    float: right;
}
.react-rater-star.is-disabled {
    cursor: default;
    font-size: 16px;
    margin: 0 3px 0 0;
}
section.form-shared.login-page .input-box .row {
    margin: 0 -2px;
}
.css-1pahdxg-control,
section.form-shared.login-page .css-g1d714-ValueContainer {
    padding: 2px;
}
.css-1pahdxg-control {
    border-radius: 4px !important;
}
.multi-images.delete-account-info,
.multi-images.gallery-info {
    box-sizing: border-box;
    height: 400px;
    width: auto;
    position: relative;
    background: #f5f5f5;
    background: linear-gradient(115deg, #f5f5f5 70%, #fff 110%);
    vertical-align: middle;
    background-repeat: no-repeat;
    display: inline-block;
}
section.breadcrumb-area.listing-detail-breadcrumb .breadcrumb-wrap {
    position: absolute;
    bottom: 10%;
    z-index: 9;
    width: 100%;
    left: 0;
    right: 0;
}
section.breadcrumb-area.listing-detail-breadcrumb:after {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(31.42%, rgba(0, 0, 0, 0.0001)), to(#000));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 31.42%, #000);
    content: "";
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}
section.breadcrumb-area.listing-detail-breadcrumb.detail-gallery {
    width: 100%;
    max-width: 1922px;
    margin: 0 auto;
    position: relative;
}
section.breadcrumb-area.listing-detail-breadcrumb.detail-gallery .images-gallery {
    overflow: hidden;
    position: relative;
    white-space: nowrap;
}
.gallery-item {
    position: relative;
    text-align: center;
    background: #000;
    padding: 10px;
    border-radius: 0.3rem 0 0 0.3rem;
}
.slick-slide img {
    display: inline-block;
    min-height: 400px;
}
main.listing-details .gallery-carousel-dots {
    margin-left: -5px;
    padding: 10px;
}
main.listing-details .btn-box button.theme-btn.border-0.button-success.mr-1 {
    padding: 0;
    line-height: normal;
    text-align: right;
    background: 0 0 !important;
    color: #000;
    font-size: 30px;
    font-weight: 400;
    outline: 0;
    box-shadow: none;
}
main.listing-details button.slick-arrow.slick-prev {
    left: 10px;
    background: #fff;
    border-color: #fff;
}
main.listing-details button.slick-arrow.slick-next {
    right: 10px;
    background: #fff;
    border-color: #fff;
}
main.listing-details .slick-slider .slick-arrow.slick-next:before {
    border-right: 2px solid #000;
    border-top: 2px solid #000;
    opacity: 1;
}
main.listing-details .slick-slider .slick-arrow.slick-prev:before {
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    opacity: 1;
}
main.BusinessHome .places-carousel .slick-dots li.slick-active,
main.listing-details .slick-slider .slick-arrow.slick-next:hover,
main.listing-details .slick-slider .slick-arrow.slick-prev:hover {
    background-color: #ff6b6b;
    border-color: #ff6b6b;
}
main.listing-details .slick-slider .slick-arrow.slick-next:hover:before,
main.listing-details .slick-slider .slick-arrow.slick-prev:hover:before {
    border-color: #fff;
}
.multi-images.delete-account-info img,
.multi-images.gallery-info img {
    max-height: 400px;
    min-height: 400px;
}
.comment.listing-details .user-img {
    display: inline-block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 0;
    margin: 10px 15px 0 0;
    position: relative;
    padding-top: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.15);
}
.comment.listing-details {
    display: grid;
    grid-template-columns: 64px auto;
    margin: 0 0 15px;
}
ul.comments-list.padding-top-10px .react-rater-star {
    margin-bottom: 20px;
}
.rating-rating span.comment-date {
    width: auto;
    background: 0 0;
    color: #666;
    margin: 0 0 0 10px;
}
section.dashboard-area.business-detail.social-media .y-page-link ul span.text-rigt,
section.single-listing-area .details.single-listing-wrap .comments-wrap ul.comments-list p.comment-content,
span.schdule-time {
    font-size: 14px;
    font-weight: 400;
}
.password-description,
section.single-listing-area .details.single-listing-wrap .comments-wrap {
    margin-bottom: 50px;
}
.comment.listing-details .user-img img.avatar__img {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 40px rgb(82 85 90 / 10%);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
    border: 0;
}
main.listing-details .gallery-carousel-dots .slick-slider.slick-initialized .slick-list .slick-track {
    width: 95% !important;
    position: sticky;
    top: 0;
    z-index: 9;
    background: #fff;
}
.slider-content {
    display: inline-block;
    text-align: left;
    width: 100%;
    padding: 24px 24px 8px;
}
.BrainhubCarousel {
    overflow: hidden;
    display: flex;
    align-items: center;
    background: #000;
    min-height: 495px;
    height: 495px;
}
.BrainhubCarousel__arrows {
    background-color: #fff;
    border-radius: 50%;
}
.BrainhubCarousel__arrows span {
    border-color: #222;
}
.BrainhubCarousel__dots .BrainhubCarousel__thumbnail {
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
    height: 85px;
}
.BrainhubCarousel__arrows:hover:enabled {
    background-color: #f11200;
}
button.BrainhubCarousel__thumbnail.BrainhubCarousel__thumbnail--selected {
    border: 2px solid #333;
    overflow: hidden;
    height: 85px;
}
ul.BrainhubCarousel__dots {
    padding: 0;
    display: inline-block;
}
.slider-dots {
    height: 400px;
    overflow: auto;
}
ul.BrainhubCarousel__dots li {
    width: 145px;
    display: inline-block;
    height: 85px;
    overflow: hidden;
    padding: 0 4px;
    margin-top: 8px;
}
.user-profile img,
ul.BrainhubCarousel__dots li button img {
    width: 100%;
}
.slider-content h3 {
    text-align: left;
    font-size: 20px;
    float: left;
    color: #000;
    line-height: 26px;
    width: 100%;
}
main.listing-details .sidebar.section-bg.details-sidebar .sidebar-widget .btn-box.text-center.padding-top-35px {
    position: static;
    display: block;
}
.photo-count {
    font-size: 14px;
    color: #2b273c;
}
.last-updated {
    font-size: 11px;
    color: #757280;
    border-top: 1px solid #eeeeef;
    margin-top: 10px;
    padding: 10px 0;
}
.dots-slider {
    height: 295px;
    overflow: auto;
}
.BrainhubCarousel__container {
    width: 100%;
    overflow: hidden;
    min-height: 465px;
    height: 495px;
}
.modal-content.p-4,
main.GalleryListing .tabs-top .rc-tabs.rc-tabs-top .rc-tabs-content.rc-tabs-content-animated .rc-tabs-tabpane {
    overflow: hidden;
}
main.home-1 .modal-content.p-4 {
    height: 83vh;
}
.see-all-btn {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    padding: 0 24px;
    margin-bottom: 20px;
}
.see-all-btn a {
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    text-decoration: none !important;
    height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0 auto;
    background: 0 0;
    border: 1px solid #bbbac0;
    border-radius: 4px;
    width: 100%;
    color: #2b273c;
}
main.dashboard-page {
    background: #fcfcfc;
}
main.dashboard-page .header-menu-wrapper {
    background: #f11200 !important;
    padding: 15px 0;
}
main.dashboard-page .sidebar.section-bg {
    background: #fff;
    border-color: #e5e5e5;
    padding: 15px;
    border-radius: 3px;
}
main.dashboard-page .author-bio .author__title {
    font-size: 14px;
    padding-bottom: 20px !important;
    font-weight: 700 !important;
}
main.dashboard-page .section-block-2 {
    background-color: #eeeeef;
    margin-bottom: 10px !important;
}
main.dashboard-page .author-bio img {
    width: 120px !important;
    height: 120px;
    border-radius: 3px;
    border: 0;
    box-shadow: none;
    background: #eee;
    object-fit: scale-down;
}
main.dashboard-page .sidebar.section-bg .sidebar-widget .list-items li {
    margin-bottom: 1px;
    color: #2b273c;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 400;
}
.font-weight-bold {
    font-weight: 600 !important;
}
main.dashboard-page .sidebar-widget,
section.dashboard-area.business-detail.u-photos .y-page-link h2 {
    margin-bottom: 0;
}
main.dashboard-page .sidebar-widget.side-menu .info-list ul.list-items li a {
    height: 40px;
    display: inline-block;
    line-height: 40px;
    width: 100%;
    padding: 0 0 0 10px;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}
.sidebar.section-bg.mt-2.mb-4.side-navbar {
    padding: 0;
    margin-top: 15px !important;
}
.sidebar.section-bg.mt-2.mb-4.side-navbar .d-flex.align-items-center {
    padding: 5px 15px;
}
.sidebar.section-bg.mt-2.mb-4.side-navbar h4.author__title.pb-0.pl-1 {
    font-size: 16px;
    font-weight: 600 !important;
}
main.dashboard-page .dm-icon {
    width: 36px;
    height: 36px;
    background: #000;
    border-radius: 100%;
    text-align: center;
    line-height: 36px;
}
main.dashboard-page .dm-icon .dm-icon-img img {
    width: auto !important;
    height: auto;
    text-align: center;
    margin: 0;
    line-height: 36px;
    position: relative;
    border-radius: 0;
}
main.dashboard-page .sidebar-widget.side-menu .info-list ul.list-items li a span.dashboard-icon {
    width: 20px;
    display: inline-block;
    background: 0 0;
    margin: 0 10px 0 0;
}
main.dashboard-page .dashboard-area .y-page-link {
    background: #fff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 15px 15px 30px;
    position: relative;
    height: 100%;
}
#page-content-wrapper,
main.dashboard-page div#sidebar-wrapper {
    padding-top: 30px !important;
}
span.dashboard-icon {
    text-align: center;
    display: inline-block;
    margin: 15px 15px 0 0;
}
.BuyerEmailList.SettingPage p,
.ProgressBar,
.password,
main.dashboard-page.BuyerProfile.BuyerEmailNoti .contact-form-action .PrivacyDesc p,
section.dashboard-area,
section.two-col section.dashboard-area.business-detail h2,
ul.gender {
    margin-bottom: 15px;
}
.y-page-link a span.dashboard-icon {
    color: #59595a;
}
.account-page .current-pkg .pkg-box a {
    display: inline-block;
    background: #ff6435;
    border-radius: 8px;
    color: #fff;
}
.account-page .current-pkg .pkg-box.plans-0 a {
    background: #ebeced;
    color: #666;
}
.account-page .current-pkg .pkg-box a:hover {
    background: #e04f23;
}
.account-page .current-pkg .pkg-box.plans-0 a:hover {
    background: #bbb;
}
main.dashboard-page .dashboard-area .y-page-link a.web-link {
    position: relative;
    top: -12px;
    text-decoration: underline !important;
    color: #326d62;
}
main.dashboard-page .dashboard-area .y-page-link a.web-link:hover,
main.dashboard-page .dashboard-area .y-page-link ul li span.text-rigt a:hover {
    color: #f11;
}
.SearchArea .EmailAdd.friend,
.close-list,
.edit-content {
    position: absolute;
    right: 0;
    top: 0;
}
main.dashboard-page .dashboard-area .y-page-link .edit-content a span.dashboard-icon {
    margin: 15px 30px 0 0;
}
main.dashboard-page .dashboard-area .y-page-link .edit-content a {
    color: #2b273c;
    font-size: 15px;
    font-weight: 500;
}
main.dashboard-page .dashboard-area .y-page-link ul {
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
}
main.dashboard-page .dashboard-area .y-page-link ul li span {
    display: inline-block;
    color: #2b273c;
    font-size: 14px;
    font-weight: 500;
}
main.dashboard-page .dashboard-area .y-page-link ul li .la {
    color: #2b273c;
    background-color: transparent;
    float: right;
}
main.dashboard-page .dashboard-area .y-page-link ul li span.text-rigt {
    float: right;
    font-weight: 400;
}
main.dashboard-page .dashboard-area .y-page-link ul li span.text-rigt a {
    font-size: 14px;
    margin: 0;
}
section.dashboard-area.business-detail .y-page-link h2 {
    margin-bottom: 25px;
}
.main-menu-content.BHome .TopHeader nav ul li a,
section.dashboard-area.business-detail .y-page-link p {
    color: #2b273c;
    font-size: 14px;
    font-weight: 400;
}
section.dashboard-area.business-detail .y-page-link .opening-hours ul li {
    height: auto;
    border-bottom: 0;
    line-height: 23px;
    color: #2b273c;
}
section.dashboard-area.business-detail .y-page-link .opening-hours ul li strong.font-weight-medium {
    font-size: 14px;
    font-weight: 400 !important;
}
.google-map {
    width: 330px;
}
section.two-col .col-lg-6 {
    padding: 0 2px;
}
section.two-col {
    padding: 0 14px;
}
section.two-col section.dashboard-area.business-detail .certificate-title h2 {
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    color: #000;
}
section.two-col section.dashboard-area.business-detail .certificate-title p {
    font-size: 14px;
    color: #666;
}
main.BusinessHome.AboutUs .BHeading h2,
section.two-col section.dashboard-area.business-detail .certificate-title {
    margin-bottom: 20px;
}
section.dashboard-area.business-detail .y-page-link .keywords ul li {
    border-bottom: 0;
    height: auto;
    width: auto;
    display: inline-block;
    flex-basis: auto;
    line-height: normal;
    margin: 7px 0 0;
}
section.dashboard-area.business-detail .y-page-link .keywords ul li a {
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 30px;
    line-height: normal;
    padding: 3px 20px;
    margin: 0 5px;
}
section.dashboard-area.business-detail .y-page-link .keywords ul li a:hover {
    background: #f11;
    color: #fff;
    border-color: #f11;
}
.main-menu-content.listin_nav ul li {
    padding-right: 0px !important;
}
.progress .progress-bar {
    background-color: #5cd3b7;
    border-radius: 10rem;
}
main.home-1 .rating-rating.rating-detail.landing {
    padding: 5px 0;
    margin-top: 10px;
}
.progress {
    display: flex;
    height: 13px;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: transparent;
    border-radius: 10rem;
    border: 1px solid #e5e5e5;
}
.footer-item .list-items li a {
    font-size: 13px;
    font-weight: 300;
}
.chat-module .full-width button,
.chat-module .str-chat__input-footer,
.footer-item .footer__title:after,
.footer-item .footer__title:before,
.navbar-none,
.react-switch-bg svg,
.sorted .dropdown-toggle::after,
main.BusinessHome .testimonial-item .testi-comment::before,
main.BuyerProfile .sidebar.section-bg.mt-2.mb-4.BuyerProfile .sidebar-widget .list-items li a:hover:before,
main.dashboard-page .dashboard-area .y-page-link ul.ActivityList li:last-child .ActivityIcon:after,
main.home-1 .main-menu-content .user-side.login .dropdown button.dropdown-toggle.btn.btn-success::after {
    display: none;
}
.custom-checkbox input[type="checkbox"]:checked + label {
    padding-left: 26px;
    font-size: 15px;
}
.SelectPrice .custom-checkbox input[type="checkbox"]:checked + label {
    padding-left: 0;
    color: #fff;
}
.ProgressBar {
    width: 100%;
    padding: 0 15px;
}
.ProgressBar span {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
    display: inline-block;
    color: #f11;
}
main.dashboard-page section.dashboard-area.account-page .y-page-link {
    padding: 13px 80px;
    min-height: 407px;
}
main.dashboard-page section.dashboard-area.account-page .y-page-link h6 {
    font-size: 14px;
    font-weight: 500;
    color: #666;
}
main.dashboard-page section.dashboard-area.account-page .y-page-link h5 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin-top: 5px;
}
main.dashboard-page section.dashboard-area.account-page .y-page-link h2 {
    font-size: 32px;
    font-weight: 600;
    margin-top: 30px;
}
.current-pkg {
    display: inline-block;
    margin-top: 20px;
}
.pkg-box {
    display: inline-block;
    background: #eeeeef;
    border-radius: 7px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    width: 192px;
    text-align: center;
    height: 39px;
    line-height: 39px;
}
main.dashboard-page .dashboard-area .y-page-link ul.PackageDetails {
    margin-top: 20px;
    padding-left: 25px;
}
main.dashboard-page .dashboard-area .y-page-link ul.PackageDetails li {
    height: auto;
    line-height: 18px;
    border-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    list-style: disc !important;
    color: #2b273c;
    padding: inherit;
    margin: 10px 0px 0 15px;
    /* display: list-item; */
}
.terms {
    font-size: 8px;
    font-weight: 400;
    color: #2b273c;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
}
#user-picture,
.Buyer-wrapper,
.chat-area,
.collaps-sidebar,
.password.save-button,
.search-area-gallery,
.upload-photos,
.uploads-photo,
.user-info,
.user-profile {
    position: relative;
}
main.dashboard-page .dashboard-area.deactive-account .y-page-link {
    padding: 20px 80px 100px;
}
main.dashboard-page .dashboard-area.deactive-account .y-page-link h5 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    display: inline-block;
}
.top-area {
    width: 100%;
    display: flex;
}
main.dashboard-page .dashboard-area.deactive-account .y-page-link h4 {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    display: inline-block;
    margin-bottom: 10px;
}
.top-area-right p {
    font-size: 12px;
    font-weight: 400;
    color: #000;
    margin: 0;
    line-height: 20px;
}
.top-area-right select {
    height: 32px;
    padding: 0 15px;
    font-size: 12px;
}
.RowOne,
.RowTwo {
    display: grid;
    grid-template-columns: 35% 65%;
}
.RowTwo {
    margin-top: 60px;
}
.ActivityDetail .description p,
.SocialUser,
.reviews-tabs.SocialMedia .SocialQuestions p,
main.dashboard-page .dashboard-area .y-page-link ul li span.text-rigt p {
    line-height: normal;
}
main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link,
main.dashboard-page.details-info section.dashboard-area.business-detail.Amenities .y-page-link {
    padding-bottom: 30px;
}
main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul li:last-child {
    border-bottom: 0;
    height: auto;
    padding-top: 15px;
}
main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul li span.text-rigt {
    width: 40%;
}
main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul li span.la-star {
    width: 50%;
}
main.dashboard-page .dashboard-area .y-page-link ul li label.review-label span.radio-btns {
    margin: 0 0 0 25px;
}
main.dashboard-page .dashboard-area .y-page-link ul li .review-label input:checked ~ .review-mark:before {
    opacity: 1;
    transform: scale(1);
}
main.dashboard-page .dashboard-area .y-page-link .safety .custom-checkbox input[type="checkbox"]:checked + label:before {
    background-color: #326d62;
    border-color: #326d62;
    z-index: 1;
}
main.dashboard-page .dashboard-area .y-page-link .safety .custom-checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    visibility: visible;
    z-index: 1;
}
.custom-checkbox input[type="checkbox"]:checked,
main.dashboard-page .dashboard-area .y-page-link .custom-checkbox input[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.safety .custom-checkbox {
    display: inline-block;
    margin: 0 15px 0 0;
}
section.dashboard-area.business-detail .safety .custom-checkbox label {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-bottom: 25px;
}
main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul.covid-point li,
main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul.covid-point li:last-child {
    height: 50px;
    display: inline-block;
    width: 100%;
    padding-top: 0;
    line-height: normal;
}
main.GalleryListing .tabs-top .rc-tabs.rc-tabs-top .rc-tabs-content.rc-tabs-content-animated,
main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul.covid-point li .custom-legend {
    padding-top: 15px;
}
main.dashboard-page .dashboard-area .y-page-link ul.price.p1 li {
    line-height: normal;
    padding-top: 15px;
}
.safety {
    display: inline-block;
    margin-top: 50px;
}
.sub-heading {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 24px;
}
.SelectUser h5,
.SignUpSelection h3,
.sub-heading span {
    font-weight: 700;
}
.gallery-carousel-dots .slick-slide {
    opacity: 0.4;
    padding: 0;
    width: 48% !important;
    display: inline-block;
    height: 80px;
    overflow: hidden;
    margin: 8px 5px 0 0;
    border: 2px solid #ff6b6b;
    border-radius: 5px;
}
section.dashboard-area.business-detail.u-photos .y-page-link p.files {
    font-size: 10px;
    font-weight: 400;
    color: #000;
}
.upload-photos {
    width: 100%;
    display: inline-block;
}
.upload-photos .dropzone {
    width: auto;
    margin: 0;
    padding: 0;
    border: 0;
    color: #f11200;
    text-shadow: none;
}
.upload-photos .dropzone a.drag-drop-btn {
    border: 0;
    box-shadow: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    position: absolute;
    right: 0;
}
.name-user strong.primary-font,
section.dashboard-area.business-detail.social-media .y-page-link ul span.text-left {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}
section.dashboard-area.business-detail.social-media .y-page-link ul li {
    border: 0;
    height: auto;
    line-height: 30px;
}
main.dashboard-page.details-info section.dashboard-area.business-detail.social-media .y-page-link {
    padding-bottom: 10px;
}
.user-info {
    background: #f7f7f7;
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid #c4c4c4;
    height: 190px;
    padding: 20px 0;
    z-index: 1;
}
#user-picture {
    display: grid;
    grid-template-columns: 250px 490px auto;
}
.user-profile {
    width: 220px;
    height: 220px;
    border-radius: 5px;
    overflow: hidden;
    display: inline-block;
}
.user-information {
    padding-top: 40px;
}
.user-name h2 {
    font-size: 32px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
}
.user-name h6 {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin-top: 5px;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu .user-information ul.UserSocialInfo,
main .user-login-info .user-side.login.DropMenu #user-picture .user-information ul.UserSocialInfo,
ul.UserSocialInfo {
    display: inline-block;
    margin-top: 5px;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu ul.UserSocialInfo li,
main .user-login-info .user-side.login.DropMenu #user-picture .user-information ul.UserSocialInfo li,
ul.UserSocialInfo li {
    font-size: 14px;
    color: #000;
    display: inline-block;
    margin: 0 10px 0 0;
}
ul.UserSocialInfo li svg {
    color: #ff4f00;
    font-size: 18px;
}
ul.UserSocialInfo li span {
    background: #ff4f00;
    color: #fff;
    border-radius: 3px;
    width: 17px;
    height: 17px;
    text-align: center;
    line-height: 17px;
    display: inline-block;
    vertical-align: top;
    margin-top: 4px;
}
.uploads-update {
    display: inline-block;
    border-left: 1px solid #c4c4c4;
    height: 96px;
    padding: 0 0 0 15px;
    margin-top: 40px;
}
.uploads-update ul.uploads li {
    width: 100%;
    display: inline-block;
    margin: 0 0 5px;
}
ul.UserSocialInfo li span svg {
    color: #fff;
    font-size: 14px;
}
.uploads-update ul.uploads a.drag-drop-btn {
    position: static;
    color: #0073bb;
    font-size: 14px;
    font-weight: 600;
}
.upload-photos button,
.uploads-update ul.uploads li .upload-photos a {
    color: #0073bb;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin: 0 0 0 8px;
}
.uploads-update ul.uploads aside {
    display: none !important;
}
.chat-module .str-chat.str-chat-channel.messaging.light,
.chat-module .str-chat__channel-list-team__main,
main.BusinessHome header.header-area,
main.dashboard-page.BuyerProfile {
    background: #fff;
}
main.dashboard-page.BuyerProfile #sidebar-wrapper {
    padding-top: 30px;
    background: 0 0;
    margin: 0;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu button#dropdown-basic,
main.BuyerProfile .sidebar.section-bg.mt-2.mb-4.BuyerProfile {
    background: 0 0;
    border: 0;
    padding: 0;
}
main.BuyerProfile .sidebar.section-bg.mt-2.mb-4.BuyerProfile .author-inner-bio {
    margin: 30px 0 0;
}
.sidebar.section-bg.mt-2.mb-4.BuyerProfile .author-inner-bio.pb-0 h4.author__title.pb-0.pl-1 {
    color: #000;
    font-size: 14px;
    font-weight: 600 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
}
.sidebar.section-bg.mt-2.mb-4.BuyerProfile .section-block-2 {
    background-color: transparent;
    margin-bottom: 0 !important;
}
main.BuyerProfile .sidebar.section-bg.mt-2.mb-4.BuyerProfile .sidebar-widget .list-items li {
    height: 42px;
    line-height: 42px;
    margin: 0;
    border-bottom: 1px solid #e5e5e5;
}
main.BuyerProfile .sidebar.section-bg.mt-2.mb-4.BuyerProfile .sidebar-widget .list-items li span {
    line-height: normal;
    height: auto;
    width: auto;
    background-color: transparent;
    margin: 0 10px 0 0;
    border-radius: 0;
}
main.BuyerProfile .sidebar.section-bg.mt-2.mb-4.BuyerProfile .sidebar-widget .list-items li a {
    border-left: 6px solid transparent;
    line-height: 42px;
    height: 42px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding: 0 0 0 6px;
    width: 100%;
}
.Buyer-wrapper {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 220px auto auto;
    grid-column-gap: 30px;
    z-index: 1;
}
.sidebarBuyer .info-list {
    border-top: 1px solid #e5e5e5;
    margin-top: 10px;
    display: inline-block;
    width: 100%;
}
.main-menu-content.BHome .TopHeader nav ul li:hover,
main.BusinessHome .main-menu-content nav ul li:last-child:hover,
main.BuyerProfile .sidebar.section-bg.mt-2.mb-4.BuyerProfile .sidebar-widget .list-items li a.active,
main.BuyerProfile .sidebar.section-bg.mt-2.mb-4.BuyerProfile .sidebar-widget .list-items li a:hover,
ul.generic-nav button.active.btn.btn-none {
    border-color: #f11200;
}
.Buyer-wrapper .contact-form-action .input-box label.label-text {
    width: 100%;
    display: inline-block;
    margin: 0;
    line-height: normal;
}
.Buyer-wrapper .contact-form-action .input-box label.sub-label {
    font-size: 12px;
    margin: -5px 0 0;
    font-weight: 500;
    display: block;
    color: #a3a3a3;
}
.BuyerContent .contact-form-action .form-group input.form-control,
.BuyerContent .contact-form-action .form-group select.form-control,
.BuyerContent .contact-form-action textarea.message-control.form-control {
    padding: 10px;
    height: 30px;
    width: 470px;
}
.scroll-content-inner .card .card-body strong,
ul.gender li span.la-star {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}
.Buyer-wrapper .contact-form-action .btn-box a {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 0 10px;
    color: #0073bb;
}
.Buyer-wrapper .contact-form-action .btn-box button.theme-btn.border-0 {
    padding: 0;
    height: 35px;
    line-height: 35px;
    width: 135px;
    background: #f11200;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
}
.GalleryHeading h1 {
    font-size: 34px;
    font-weight: 700;
    color: #000;
}
.col-lg-2.column-td-6.GalleryPics {
    padding: 0;
    width: 186px;
    height: 180px;
    display: inline-block;
    flex: 0 0 0;
    margin: 10px 10px 0 0;
}
main.GalleryListing .card-item.blog-card .card-image,
main.GalleryListing .card-item.blog-card .card-image:after,
main.GalleryListing .card-item.blog-card a.card-image-wrap .card-image img.card__img {
    border-radius: 5px;
    overflow: hidden;
    width: 185px;
    height: 185px;
    padding: 40% 0 0;
    margin: 0;
    position: relative;
    background-color: rgba(0, 0, 0, 0.15);
}
.col-lg-2.column-td-6.GalleryPics .card-item.blog-card {
    height: 170px;
    width: 170px;
    display: inline-block;
    border: 0;
}
.BusinessLogo {
    width: 32px;
    height: 32px;
    display: inline-block;
    margin: 7px 0 0;
    border-radius: 5px;
    overflow: hidden;
}
.BusinessLogo img {
    width: 100%;
    height: 100%;
}
main.GalleryListing .rating-rating {
    display: inline-block;
    margin: 0 0 0 10px;
}
.BusinessName {
    font-size: 14px;
    font-weight: 600;
    color: #0073bb;
}
.BusinessDetail {
    display: grid;
    grid-template-columns: 32px auto auto;
}
.empty-space,
.form-area label,
.key-field,
.key-field input {
    display: inline-block;
}
.total-pages {
    border: 1px solid #eeeeef;
    border-left: 0;
    border-right: 0;
    padding: 10px 0;
    margin: 15px 0 60px;
    color: #000;
    font-size: 14px;
}
.uploads-photo .drag-and-drop-wrap.text-center .drag-and-drop-file .dropzone {
    padding: 0;
    margin: 0;
    border: 0;
    width: auto;
    text-align: right;
}
.uploads-photo .drag-and-drop-wrap.text-center .drag-and-drop-file .dropzone a.drag-drop-btn {
    padding: 0;
    border: 0;
    margin: 0;
    box-shadow: none;
    background: #f11200;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    width: 160px;
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
}
.empty-space {
    height: 40px;
    width: 100%;
}
main.GalleryListing ul.react-tabs__tab-list {
    text-align: left;
    border: 0;
    margin: 0;
}
.search-area-gallery {
    width: 260px;
    float: right;
    opacity: 0;
}
.search-area-gallery button {
    position: absolute;
    top: 0;
    right: 0;
}
.uploads-photo {
    width: auto;
}
.uploads-photo .drag-and-drop-wrap.text-center {
    position: static;
    right: 0;
}
.search-area-gallery button.button.search-btn {
    background: #f11200;
    border: 0;
    color: #fff;
    padding: 7px 10px;
    border-radius: 0 5px 5px 0;
}
.react-switch-bg,
.react-switch-handle {
    border: 1px solid #e5e5e5 !important;
}
main.dashboard-page.BuyerProfile .main-menu-content {
    background: 0 0;
    padding: 0;
}
main.dashboard-page .main-menu-content {
    padding: 0;
    background: #f11200;
    height: 72px;
}
ul.top-nav {
    padding: 13px 0;
    text-align: left;
}
.main-menu-content nav ul li:last-child {
    float: right;
}
main.dashboard-page section.dashboard-area.security .y-page-link.security-area h4 {
    color: #2b273c;
    font-size: 20px;
    font-weight: 600;
}
main.dashboard-page section.dashboard-area.security .y-page-link.security-area h5 {
    font-size: 16px;
    font-weight: 500;
    color: #2b273c;
    margin-top: 40px;
    margin-bottom: 40px;
}
.form-area label {
    color: #2b273c;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 5px 0 0;
}
.password.save-button {
    margin-bottom: 60px;
}
.password.save-button a.theme-btn.save-changes {
    background: #5cd3b7;
    position: absolute;
    right: 15px;
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
}
.react-switch-bg {
    background: #5cd3b7 !important;
}
.password-description label {
    font-size: 13px;
    font-weight: 600;
}
.password-description label.small {
    font-size: 10px;
    font-weight: 500;
    color: #5cd3b7;
    margin: 0;
    padding: 0;
}
.password-description p,
main.dashboard-page.BuyerProfile .Buyer-wrapper.BuyerEmail .SeeAbout label.form-check-label,
span.metting-time {
    font-size: 14px;
    font-weight: 400;
    color: #2b273c;
}
.key-field {
    width: 530px;
    margin: 0;
}
.key-field input {
    width: 100%;
    height: 40px;
}
.api-key a.theme-btn.enter,
.api-key a.theme-btn.remove {
    height: 40px;
    font-size: 12px;
    line-height: 40px;
    font-weight: 600;
    margin: 0 5px 0 0;
    padding: 0;
}
.api-key .theme-btn.enter,
.notification .badge.schdule {
    background: #5cd3b7;
}
.api-key .theme-btn.remove {
    background: #2b273c;
}
.notification .badge.orange,
span.bulit {
    background: #ff4f00;
}
.api-key label {
    font-weight: 400;
    font-size: 14px;
    margin: 0 0 5px;
    color: #2b273c;
}
.api-key label.e-small {
    font-size: 12px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    color: #a5a5a5;
}
.key-field select {
    height: 40px;
    line-height: 40px;
    padding: initial;
}
main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs .form-area ul.react-tabs__tab-list,
main.dashboard-page section.dashboard-area.security .y-page-link.security-area .password .react-tabs ul.react-tabs__tab-list {
    display: inline-block;
    width: 100%;
    text-align: left;
}
main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs .form-area ul.react-tabs__tab-list li,
main.dashboard-page section.dashboard-area.security .y-page-link.security-area .password .react-tabs ul.react-tabs__tab-list li {
    font-size: 14px;
    font-weight: 400;
    margin: 0 50px 0 0;
    border-bottom: 3px solid transparent !important;
}
main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs .form-area ul.react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected,
main.dashboard-page section.dashboard-area.security .y-page-link.security-area .password .react-tabs ul.react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected {
    font-weight: 600;
    border-color: #326d62 !important;
    color: #2b273c !important;
}
.schdule-date {
    border-bottom: 1px solid #eeeeef;
    padding: 20px 0;
}
span.bulit {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}
.schdule-info {
    display: grid;
    grid-template-columns: 35px auto 330px auto;
    padding: 20px 0;
}
.name-meeting span.client-name {
    color: #2b273c;
    font-size: 14px;
    font-weight: 600;
}
span.metting-time strong {
    font-weight: 600;
    color: #2b273c;
}
.details-meeting .dropdown button {
    background: 0 0;
    border: 0;
    color: #a3a3a3;
}
.chat-area {
    min-height: 500px;
}
.ReviewsProduct textarea.form-control:focus,
.chat-module .form-group.basic-textarea button.float-right.mt-4.btn.btn-primary.btn-sm:focus,
.post-btn button.btn.btn-secondary:focus {
    outline: 0;
    box-shadow: none;
}
.chat-module .form-group.basic-textarea button.float-right.mt-4.btn.btn-primary.btn-sm:hover {
    color: #fff;
    background: #f11200;
}
.chat-area .chat-empty {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 445px;
    height: 100px;
    text-align: center;
}
.chat-area .chat-empty p {
    font-size: 14px;
    font-weight: 400;
    color: #000;
}
.details-meeting .dropdown .dropdown-toggle::after {
    margin: 10px 10px 0 0;
    float: left;
}
.details-meeting .dropdown .btn-primary:not(:disabled):not(.disabled).active,
.details-meeting .dropdown .btn-primary:not(:disabled):not(.disabled):active,
.details-meeting .show.dropdown .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #f11200;
    border-color: #f11200;
}
main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs .form-area ul.react-tabs__tab-list li {
    color: #a3a3a3 !important;
}
main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs .tabs-content ul.comments-list li {
    height: auto;
    padding-bottom: 20px;
}
main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs .tabs-content ul.comments-list li p.comment-content {
    line-height: normal;
    font-size: 14px;
    color: #2b273c;
    margin-bottom: 20px;
}
.write-b-review {
    border-top: 1px solid #eeeeef;
    width: 100%;
    display: grid;
    grid-template-columns: auto 150px;
}
.textarea p {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding: 10px 0;
    color: #a3a3a3;
}
main.dashboard-page .dashboard-area .y-page-link ul li .review-btns a.theme-btn.cancel,
main.dashboard-page .dashboard-area .y-page-link ul li .review-btns a.theme-btn.reply {
    background: #5cd3b7;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    width: 115px;
    padding: 0;
    text-align: center;
    height: 40px;
    line-height: 40px;
    box-shadow: none;
}
main.dashboard-page .dashboard-area .y-page-link ul li .review-btns a.theme-btn.cancel {
    background: #eeeeef;
    color: #2b273c;
    margin-top: 20px;
}
.write-b-review .textarea textarea.form-control {
    min-height: 105px;
    resize: none;
}
.review-btns p {
    padding: 5px 0;
    line-height: normal;
}
main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs .tabs-content ul.comments-list li .react-rater .react-rater-star {
    font-size: 18px;
}
main.GalleryListing .tabs-top .rc-tabs.rc-tabs-top {
    margin-top: -45px;
    border-bottom: 0;
}
main.GalleryListing .tabs-top .rc-tabs.rc-tabs-top .rc-tabs-nav-container.rc-tabs-nav-container-scrolling {
    width: 78%;
    margin-top: 0;
    border: 0;
    margin-bottom: 0;
}
main.GalleryListing .tabs-top .rc-tabs.rc-tabs-top .rc-tabs-bar {
    border: 0;
    border-bottom: 1px solid #eeeeef;
}
.GalleryPics {
    padding-right: 0;
    width: 187px;
    max-width: 187px;
    margin: 0 10px 10px 0;
}
.b-listing,
main.BusinessHome .section-heading.display-inline {
    max-width: 100%;
}
main.GalleryListing .tabs-top .rc-tabs.rc-tabs-top .rc-tabs-nav-container.rc-tabs-nav-container-scrolling .rc-tabs-tab-active.rc-tabs-tab {
    padding-bottom: 20px;
}
main.GalleryListing .tabs-top .rc-tabs.rc-tabs-top .rc-tabs-nav-container.rc-tabs-nav-container-scrolling .rc-tabs-ink-bar.rc-tabs-ink-bar-animated {
    background-color: #f11200;
    height: 4px;
}
.billing-content.BSignupForm .contact-form-action.sign-up .input-box input,
.empty-space-50 {
    height: 50px;
}
main.dashboard-page .dashboard-area .y-page-link.call-to-act {
    min-height: 600px;
}
main.dashboard-page .dashboard-area .y-page-link.call-to-act h4,
section.dashboard-area.security.invoice h4 {
    color: #2b273c;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 50px;
}
.collaps-sidebar {
    display: inline-block;
    min-width: 54px;
    z-index: 9;
}
.collaps-sidebar button.sidenav---sidenav-toggle---1KRjR {
    padding-left: 18px;
}
.collaps-sidebar .sidenav---sidenav-nav---3tvij {
    float: left;
    padding: 0 0 0 10px;
    margin: 0;
    clear: both;
    list-style: none;
    width: 100%;
    background: #fff;
}
.collaps-sidebar .collaps-sidebar .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.collaps-sidebar .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.collaps-sidebar .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > *,
.collaps-sidebar .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
    color: #000;
}
.collaps-sidebar .icon,
.collaps-sidebar .icons {
    background: #000;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    line-height: 27px;
    margin: 11px 0 0;
}
.collaps-sidebar .icons img {
    width: 14px;
}
.fix-sidebar {
    display: inline-block;
    position: absolute;
    min-height: 598px;
    top: 30px;
    left: -1px;
}
.collaps-sidebar .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
    display: block;
    float: left;
    width: 40px;
    margin-left: 10px;
}
.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    display: block;
    width: 20px;
    height: 2px;
    margin: 0 auto;
    background-color: #000;
}
.sidenav---sidenav---_2tBP {
    background: #fff;
    border: 1px solid #e5e5e5;
    min-width: 54px;
    border-left: 0;
}
.sidenav---sidenav-toggle---1KRjR {
    position: relative;
    float: left;
    width: 48px;
}
.fix-sidebar nav.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 200px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T:hover {
    background: #f0f0f0;
}
.chat-module .str-chat__channel-preview--active .str-chat__channel-preview-last-message,
.chat-module .str-chat__channel-preview--active .str-chat__channel-preview-title,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > *,
main.dashboard-page.billing-history .row.table-foot.asrt-table-foot .col-md-6 {
    color: #000;
}
.chat-module {
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    padding: 0 0 0 37px;
    margin-top: 25px;
}
.chat-module .str-chat__channel-list-team__main {
    border-right: 1px solid #e5e5e5;
}
.chat-module .str-chat-channel.messaging .str-chat__main-panel {
    padding: 0 10px 0 0;
}
.chat-module .str-chat__header-livestream {
    padding: 10px 15px;
}
.chat-module .str-chat__channel-preview--active {
    background: #ebebeb;
    color: #000;
}
.billing-form-item.mb-0.LoginPopup .form-group.login-btn button,
.billing-form-item.mb-0.LoginPopup .form-group.login-btn span,
.billing-form-item.mb-0.LoginPopup .form-group.login-btn span button.theme-btn,
.chat-module .card-body .react-scroll-to-bottom--css-abdfk-79elbk,
.react-scroll-to-bottom--css {
    width: 100% !important;
}
.react-scroll-to-bottom--css-gxpah-79elbk {
    position: relative;
    width: 100%;
}
.chat-module .form-group.basic-textarea input {
    height: 60px;
    width: 100%;
    border-radius: 5px;
    resize: none;
    display: inline-block;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgb(0 0 0 / 4%);
    padding: 17px;
    padding-left: 40px !important;
}
.space {
    width: 100%;
    height: 40px;
    display: inline-block;
}
.chat-module .form-group.basic-textarea button.float-right.mt-4.btn.btn-primary.btn-sm {
    background: #e5e5e5;
    border-radius: 2px;
    border-color: transparent;
    color: #2b273c;
    margin: 0 !important;
    font-weight: 600;
    font-size: 14px;
    width: 85px;
}
.form-group.basic-textarea {
    margin: 10px 0 0 8px;
    width: 97%;
    position: relative;
}
.form-group.basic-textarea.empty-box {
    top: 0;
}
.DeliveryImg img,
.MobileAppView img,
.Rectangle,
.billing-content.BSignupForm .contact-form-action.sign-up .input-box,
.chat-filter input[type="text"]:focus,
.chat-module .card.grey.lighten-3.chat-room .react-scroll-to-bottom--css-joqkq-79elbk,
.cities.recent.BuyerPhotos .part,
.friend-pic img,
main.BusinessHome.AboutUs .Rectangle.Video .VideoContent p,
main.BusinessHome.AboutUs .main-menu-content.BHome img,
main.BusinessHome.FaqPages .logo.listing img,
section.TeamItem.about-team.padding-bottom-100px img,
section.dashboard-area.business-detail edit-tabs .nav-tabs,
section.dashboard-area.business-detail ul.covid-point .nav-tabs,
ul.SocailImages {
    width: 100%;
}
.chat-room .friend-list .list-group-item {
    margin: 0 5px;
    border: 0;
    border-bottom: 1px solid;
    display: grid !important;
    grid-template-columns: 60px 155px 75px;
    text-align: left;
    padding-right: 15px !important;
}
.chat-room .friend-list .list-group-item:last-of-type {
    border-bottom: 0;
    border-radius: 8px;
}
.chat-room .friend-list .list-group-item.active {
    background-color: #eee;
    color: #000;
}
.chat-room .z-depth-1 img {
    height: 3rem;
    width: 3rem;
}
.chat-room .chat-message.reply {
    flex-direction: row-reverse;
}
li.chat-message.d-flex small.pull-right.text-muted {
    position: static;
    left: 0;
    float: none;
}
li.chat-message.d-flex .card {
    border: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    width: 100%;
    padding: 15px 10px;
    border-radius: 0;
}
.chat-module .full-width li:last-child.chat-message .card {
    margin-bottom: 30px;
}
.chat-module .card-body ul.list-group.list-unstyled {
    padding-bottom: 50px;
}
.chat-module .card-body {
    padding: 0 0 0 10px;
    min-height: 500px;
    display: inline-block;
    width: 100%;
}
main.dashboard-page .chat-module .sidebar.section-bg {
    border: 0;
    border-left: 1px solid #e5e5e5;
    min-height: 676px;
    border-radius: 0;
    background: 0 0;
    margin-top: 0;
    border-top: 0;
    padding: 0;
}
.chat-module .full-width {
    border-bottom: 1px solid #e5e5e5;
    min-height: 490px;
}
.chat-module .full-width li.chat-message {
    margin: 10px 25px;
}
.chat-room .friend-list .list-group-item .user-name-chat p.text-muted {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.message-time {
    display: inline;
    width: 100%;
    margin-left: 30px;
}
.chat-user-info h4,
.time-message,
main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent.BuyerLocation li .DeleteReviews a {
    margin: 0 0 0 10px;
}
.time-message {
    display: inline-block;
    padding: 0;
    border: 0;
}
.auto-search,
.chat-user-info {
    border-bottom: 1px solid #e5e5e5;
}
.chat-user-info {
    width: 100%;
    display: inline-block;
    padding: 15px 20px;
    background: #fff;
    margin: 0 0 0 14px;
    border-left: 1px solid #e5e5e5;
}
.chat-user-info span {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: #5cd3b7;
    border-radius: 50%;
}
.chat-user-info h4 {
    display: inline-block;
    font-size: 20px;
    color: #000;
    font-weight: 600;
}
.online-time {
    margin-left: 20px;
}
.user-name-chat {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.online-time p {
    font-size: 10px;
    font-weight: 400;
    margin: 0;
}
li.chat-message .card-body {
    padding: 0;
    min-height: auto;
}
.chat-room .scrollable-friends-list {
    height: 570px;
}
.chat-module .card .card-body .white.z-depth-1.p-3 {
    border-right: 1px solid #e5e5e5;
    min-height: 685px;
    border-radius: 0;
    padding-right: 0 !important;
    padding-top: 0 !important;
}
.ReviewsProduct,
main.dashboard-page.billing-history .table-bordered td,
main.dashboard-page.billing-history .table-bordered th {
    border: 1px solid #828282;
}
.side-bar-left {
    display: flex;
    min-width: 240px;
}
.b-listing {
    display: flex;
    min-width: 774px;
}
.listing-list.post-list .b-listing {
    padding-top: 20px;
}
.chat-room .scrollable-chat {
    height: 510px;
    padding-bottom: 15px;
}
.auto-search {
    width: 100%;
    height: 90px;
    display: inline-block;
}
.auto-search input.react-autosuggest__input {
    border: 0;
    line-height: 78px;
    padding: 0 0 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    opacity: 1;
}
ul.react-autosuggest__suggestions-list li {
    padding: 10px;
    color: #000;
}
#react-autowhatever-1 {
    z-index: 99;
    position: absolute;
    width: 100%;
    background: #fff;
    border-top: 1px solid #e5e5e5;
    left: 0;
    top: 80px;
}
ul.react-autosuggest__suggestions-list li:hover {
    background: #dbdbdc;
    cursor: pointer;
}
.password.save-button button.r-button-reply.btn.btn-primary {
    background: #f11200;
    border: 0;
}
main.dashboard-page.stats-page .y-page-link.security-area span.small-heading {
    font-size: 12px;
    font-weight: 400;
    color: #000;
}
main.dashboard-page.stats-page .total-stats ul li {
    display: inline-block;
    border: 0;
    flex-basis: 25%;
    text-align: center;
    line-height: 48px;
}
.total-stats {
    margin-top: 60px;
    padding-bottom: 20px;
}
main.dashboard-page.stats-page .total-stats ul li h3 {
    color: #ff4f00;
    font-size: 32px;
    font-weight: 600;
}
main.dashboard-page.stats-page .total-stats ul li span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    line-height: normal;
}
.margin-top-15px {
    height: 15px;
}
main.dashboard-page .dashboard-area .y-page-link ul.pagination.justify-content-end.asrt-pagination li.page-item {
    border: 0;
    flex-basis: auto;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    line-height: normal;
}
main.dashboard-page .dashboard-area .y-page-link ul.pagination.justify-content-end.asrt-pagination li.page-item a {
    margin: 0 0 0 -1px;
    border-color: #e5e5e5;
    color: #000;
}
main.dashboard-page.billing-history table.table.table-bordered tr th {
    background: 95% 50% no-repeat #e5e5e5;
    color: #828282;
    font-size: 14px;
    font-weight: 700;
}
main.dashboard-page.billing-history table.table.table-bordered tr th.sortable.desc.text- {
    background-image: url("../images/up.png") !important;
}
main.dashboard-page.billing-history table.table.table-bordered tr th.sortable.asc.text- {
    background-image: url("../images/down.png") !important;
}
main.dashboard-page.billing-history table.table.table-bordered tr td {
    color: #000;
    font-size: 12px;
    font-weight: 400;
}
.billing-content.BSignupForm.StepThree,
main.dashboard-page.billing-history .row.table-foot.asrt-table-foot {
    margin-top: 50px;
}
.Buyer-Reviews ul.comments-list.padding-top-10px .react-rater-star:before,
.reviews-sidebar ul li .react-rater-star.is-disabled.is-active-half:before,
main.GalleryListing .rating-rating .react-rater .react-rater-star:before,
main.ReviwsPage .ReviewsProduct .react-rater-star:before {
    left: auto;
}
main.dashboard-page.BuyerProfile .Buyer-wrapper {
    display: grid;
    grid-template-columns: 220px 500px auto;
    grid-column-gap: 10px;
    position: relative;
    z-index: 1;
}
.BuyerContent {
    padding-left: 20px;
}
.notification ul {
    margin-top: 30px;
    width: 100%;
    display: inline-block;
    position: relative;
}
.close-list a,
.close-list button {
    display: inline-block;
    background: #a3a3a3;
    color: #fff;
    width: 14px;
    height: 14px;
    line-height: 13px;
    text-align: center;
    border-radius: 3px;
}
.notification .badge {
    font-size: 10px;
    font-weight: 500;
    color: #fff;
    height: 15px;
    width: 62px;
    display: inline-block;
}
.SignUpTopBar,
.notification .badge.red,
button.Btns.Accpect {
    background: #f11200;
}
.notification .badge.phonecall {
    background: #326d62;
}
.notification ul li p {
    font-size: 10px;
    font-weight: 500;
    margin: 0 0 0 10px;
    display: inline-block;
    position: relative;
    top: 1px;
    color: #c4c4c4;
}
.close-list a {
    font-size: 10px;
}
.close-list button {
    padding: 0;
    line-height: normal;
    font-size: 9px;
}
.notification ul li .detail-content p {
    font-size: 12px;
    font-weight: 500;
    color: #828282;
}
.notification ul li {
    border-bottom: 1px solid #e5e5e5;
    padding: 0 0 20px;
    position: relative;
}
.buyer-info {
    border-left: 1px solid #c4c4c4;
    padding-left: 30px;
}
.buyer-heading h3 {
    font-size: 20px;
    font-weight: 600;
    color: #f11200;
    margin-bottom: 20px;
}
ul.VoteReviews h6 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
}
ul.VoteReviews li {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
}
.buyer-content-info {
    margin-top: 15px;
    margin-bottom: 20px;
}
.buyer-content-info h4 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
}
.buyer-content-info p {
    font-size: 12px;
    font-weight: 400;
    color: #000;
    margin: 0;
    line-height: normal;
}
.ReviwsPageHeading h1 {
    font-size: 32px;
    font-weight: 700;
    color: #000;
}
.ReviwsPageHeading,
.bg-search-box {
    display: grid;
    grid-template-columns: auto auto;
}
.ReviewsProduct {
    border-radius: 5px;
    margin-top: 20px;
    padding: 25px;
    min-height: 420px;
}
.MoreFeatures .modal-body .Collapsible,
.navbars,
.reviews-sidebar ul li {
    border-bottom: 1px solid #eeeeef;
}
.ReviewsProduct .react-rater-star,
.ReviewsProduct .react-rater-star.is-disabled.is-active-half {
    width: 33px;
    height: 33px;
    font-size: 30px;
    line-height: 33px;
}
.ReviewsProduct .rating-rating span.rating-count {
    display: inline-block;
    position: relative;
    top: -5px;
    font-weight: 400;
}
.ReviewsProduct textarea.form-control {
    margin-top: 20px;
    border: 0;
    resize: none;
    min-height: 400px;
    font-size: 16px;
    font-weight: 400;
    padding: 0;
    color: #000;
}
.EmailAdd,
.guidelines {
    text-align: right;
    margin-top: 5px;
}
.guidelines a {
    color: #000;
    font-size: 14px;
    font-weight: 400;
}
.photo-heading {
    margin: 35px 0 25px;
}
.photo-heading h2 {
    color: #000;
    font-size: 21px;
    font-weight: 700;
}
.add-review-photo .dropzone {
    border: 1px solid #828282;
    border-radius: 5px;
}
.add-review-photo ul li {
    margin: 0 5px 0 0 !important;
    position: relative;
}
.add-review-photo ul li button.btn {
    position: absolute;
    right: 10px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 0;
    top: 10px;
    color: #fff;
}
.post-btn {
    margin: 40px 0;
}
.post-btn button.btn.btn-secondary {
    background: #f11200;
    border: 0;
    border-radius: 3px;
    font-size: 18px;
    font-weight: 600;
    width: 210px;
    height: 44px;
}
.reviews-sidebar {
    border-left: 1px solid #eeeeef;
    display: inline-block;
    width: 100%;
    padding-left: 10px;
    padding-top: 20px;
    height: 100%;
}
.reviews-sidebar h2 {
    font-size: 21px;
    color: #000;
    font-weight: 600;
}
.reviews-sidebar ul li {
    padding-bottom: 20px;
}
.main-menu-content nav ul li.user-profile-name,
.reviews-sidebar ul li ul li {
    border: 0;
    padding: 0;
}
.reviews-sidebar ul li .recent {
    display: grid;
    grid-template-columns: 42px auto;
    grid-column-gap: 10px;
    margin-top: 15px;
}
.reviws-user {
    width: 42px;
    height: 42px;
    display: inline-block;
    border-radius: 50%;
    background: #c4c4c4;
    border: 0;
}
.review-user h4 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
}
.reviews-sidebar ul li .react-rater-star,
.reviews-sidebar ul li .react-rater-star.is-disabled.is-active-half {
    width: 20px;
    height: 20px;
}
.reviews-sidebar ul li p {
    font-size: 13px;
    font-weight: 400;
    color: #000;
    margin: 10px 0 0;
    line-height: normal;
}
.UploadPhotosHeading h1 {
    font-size: 24px;
    font-weight: 700;
    color: #0073bb;
}
.UploadPhotosHeading a {
    color: #0073bb;
    font-size: 14px;
    margin-top: 5px;
    display: inline-block;
}
.browes-btn {
    width: 138px;
    height: 43px;
    background: #f11200;
    border-radius: 5px;
    margin: 10px auto 0;
}
.friend-pic,
.pic {
    background: #c4c4c4;
}
.photo-upload h1 {
    font-size: 24px;
    color: #000;
    font-weight: 700;
}
.photo-upload {
    border: 1px dashed #000;
    text-align: center;
    border-radius: 5px;
    min-height: 476px;
    position: relative;
}
.add-review-photo .photo-upload .dropzone {
    padding: 0;
    border: 0;
    margin: 0;
}
.add-review-photo .photo-upload .dropzone button.browes-btn.drag-drop-btn {
    color: #fff;
    border: 0;
    border-radius: 3px;
}
.upload-btn {
    display: inline-block;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
}
.instructions {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 35px;
    margin-top: 65px;
}
.instruction p {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin-top: 20px;
}
.pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto;
    padding: 0;
    text-align: center;
}
.DeleteReviews,
.PermissinHeading,
.ReviewsDate,
.action-icons {
    text-align: right;
}
.Buyer-Reviews {
    display: grid;
    grid-template-columns: 220px auto;
    grid-column-gap: 30px;
    position: relative;
    z-index: 1;
}
.sidebarBuyer.ReviewsPage .sidebar-widget {
    margin-top: 35px;
}
main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent {
    padding: 0;
    margin-top: 25px;
}
.Buyer-Reviews h5.comment-content {
    color: #000;
    width: 75%;
}
.Buyer-Reviews p.comment-content {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-top: 5px;
    width: 75%;
}
.Buyer-Reviews .comment.listing-details .user-img {
    border-radius: 3px;
    position: relative;
    height: 58px;
    padding-top: 90%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.15);
    border: 0;
    width: 58px;
    margin-top: 0;
}
.Buyer-Reviews .comment.listing-details .user-img img.avatar__img {
    border-radius: 3px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
    border: 0;
    box-shadow: none;
}
.Buyer-Reviews .reviews-tabs ul.comments-list li {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 30px;
    padding-top: 20px;
}
.Buyer-Reviews .comment .comment-body {
    margin-left: 10px;
    padding-top: 0;
}
.Buyer-Reviews .comment .comment-body .meta-data p {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}
.Buyer-Reviews .comment.listing-details {
    display: grid;
    grid-template-columns: 64px 270px auto;
    margin: 0 0 20px;
}
.ReviewsDate {
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
}
.Buyer-Reviews ul.comments-list.padding-top-10px .react-rater-star {
    width: 20px;
    height: 20px;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 0;
}
.DeleteReviews span {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 3px;
    width: 34px;
    height: 34px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    font-size: 20px;
}
.comment-user {
    cursor: pointer;
    color: #0073bb;
}
.DeleteReviews a,
.DeleteReviews button {
    color: #a3a3a3;
}
main.dashboard-page.BuyerProfile.BuyerReviews.BookMarks .comment.listing-details {
    display: grid;
    grid-template-columns: 64px 300px auto;
}
main.dashboard-page.BuyerProfile.BuyerReviews.BookMarks .comment .comment-body .meta-data p {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    width: 160px;
    display: inline-block;
}
main.dashboard-page.BuyerProfile.BuyerReviews.BookMarks .comment .comment-body .meta-data img.avatar__img {
    width: auto;
    height: auto;
    margin: 0;
    border: 0;
    position: absolute;
    top: 0;
}
.BuyerFriends .sorted .dropdown button {
    background: 0 0;
    border: 0;
    color: #000;
    padding: 0;
    margin: 0;
}
.BuyerFriends .sorted .btn-success:not(:disabled):not(.disabled):active:focus,
.BuyerFriends .sorted .dropdown button:active,
.BuyerFriends .sorted .dropdown button:focus {
    background: 0 0;
    box-shadow: none;
    color: #000;
}
.sorted {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
    border-bottom: 1px solid #000;
}
.sorted .dropdown label {
    color: #000;
    font-size: 14px;
}
main.dashboard-page.BuyerProfile.BuyerFriends .Buyer-wrapper {
    grid-template-columns: 220px 650px auto;
}
.sorted .manage-friend {
    text-align: right;
    color: #000;
    margin-top: 5px;
}
.frineds-list ul {
    display: grid;
    margin-top: 35px;
    grid-row-gap: 20px;
}
.friend-pic {
    width: 55px;
    height: 55px;
    border-radius: 3px;
    overflow: hidden;
}
.frineds-list ul li {
    display: grid;
    grid-template-columns: 65px auto;
}
.friend-info,
.friend-info h5 {
    font-size: 12px;
    font-weight: 600;
    color: #000;
}
.friend-info span {
    width: 30%;
    display: inline-block;
    color: #000;
}
.BuyerContent.FriendsManage .sorted .dropdown label,
.friend-info a {
    color: #0073bb;
}
.BuyerProfile .user-info .Toastify__toast-container.Toastify__toast-container--top-right {
    top: auto;
}
.cities.recent button.remove-btn-bg {
    color: #0073bb;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    width: 100%;
    text-align: center;
}
.css-1qq1qhc {
    width: 50%;
    display: inline-flex;
    padding: 0 10px;
}
.social-share .col-md-6 button {
    border-radius: 3px;
    padding: 7px 10px;
    color: #fff;
    margin: 10px 0 0;
    display: inline-block;
    width: 100%;
    border: none;
    height: 40px;
}
.social-share .col-md-6 button svg {
    font-size: 22px;
    display: inline-block;
}
.billing-form-item.mb-0.LoginPopup .billing-content .contact-form-action form .display-inline-block .form-group button.theme-btn.bg-5.border-0.w-100.metro {
    background: 0 0 !important;
    border: 1px solid #d0d0d0 !important;
    color: #505050;
    font-weight: 500;
    border-radius: 8px;
}
.billing-form-item.mb-0.LoginPopup .billing-content .contact-form-action form .display-inline-block .form-group button.theme-btn.bg-5.border-0.w-100.metro:hover,
.billing-form-item.mb-0.LoginPopup .billing-content .contact-form-action form .display-inline-block .form-group button.theme-btn.bg-5.border-0.w-100.metro:hover svg {
    background: #3a589b !important;
    color: #fff;
}
.billing-form-item.mb-0.LoginPopup .billing-content .login-btn .theme-btn svg {
    font-size: 22px;
    color: #3a589b;
    margin: 0 10px 0 0;
}
.billing-form-item.mb-0.LoginPopup .billing-content .contact-form-action form .form-group.login-btn.google button span {
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 8px;
    color: #505050;
    padding: 0 !important;
}
.billing-form-item.mb-0.LoginPopup .billing-content .contact-form-action form .form-group.login-btn.google button span:hover {
    background: #f1f1f1;
}
.billing-form-item.mb-0.LoginPopup .billing-content .contact-form-action form .form-group .form-control {
    box-shadow: 0 0;
    border-radius: 8px;
}
.billing-form-item.mb-0.LoginPopup label.label-login {
    font-weight: 400;
    font-size: 16px;
    color: #505050;
    margin-bottom: 10px;
}
.billing-form-item.mb-0.LoginPopup .billing-content .custom-checkbox {
    justify-content: center !important;
}
.billing-form-item.mb-0.LoginPopup p.font-weight-medium1.termlogin {
    font-size: 10px;
    text-align: center;
}
.billing-form-item.mb-0.LoginPopup .billing-content .contact-form-action form .display-inline-block .form-group {
    display: inline-block;
    margin: 0 0 20px;
    width: 100%;
}
p.font-weight-medium.CreateOne {
    font-size: 12px;
    text-align: center;
}
.social-share .col-md-6.fb {
    padding-right: 7px;
}
.social-share .col-md-6.tw {
    padding-left: 7px;
}
.social-share .col-md-6.fb button {
    background: #1877f2;
}
.social-share .col-md-6.tw button {
    background: #50abf1;
}
.display-flex {
    display: flex;
}
.photo-count.btn-box button.theme-btn.border-0.border-0.mr-1 {
    background: 0 0;
    color: #007bff;
    padding: 0;
    box-shadow: none;
}
.slider-content .btn-box {
    position: absolute;
    right: -10px;
    top: -33px;
}
.slider-content .btn-box button.close-btn.border-0.mr-1 {
    background: 0 0;
    color: #999;
    padding: 0;
    box-shadow: none;
}
.MoreFeatures .modal-body,
.NeighborhoodsModal .modal-body {
    height: 450px;
    overflow: auto;
}
.MoreFeatures .modal-body .sidebar.listing-page,
.NeighborhoodsModal .modal-body .sidebar.listing-page {
    padding-bottom: 0;
    top: 0;
}
.MoreFeatures .modal-footer .button-modal-footer button.btn.btn-primary,
.NeighborhoodsModal .modal-footer .button-modal-footer button.btn.btn-primary,
.TrashIcon a:hover,
.signupBtn:hover {
    background: #f11200;
    border-color: #f11200;
}
.KatikaMusic .Music a:hover,
.KatikaMusic .Music button:hover,
.MoreFeatures .modal-footer .button-modal-footer button.btn.btn-primary:hover,
.NeighborhoodsModal .modal-footer .button-modal-footer button.btn.btn-primary:hover,
.bottom-btn button.btn.btn-default:hover {
    opacity: 0.8;
}
.MoreFeatures .modal-body .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: #333;
    position: relative;
    border: 1px solid #fff;
    padding: 10px;
    border-bottom: 0;
}
main.GalleryListing .modal.fade.account-delete-modal.show .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track li.BrainhubCarouselItem.BrainhubCarouselItem--active img.img-example {
    position: absolute;
    z-index: 9;
    max-width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}
.user-info-details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0 0 0 15px;
}
.card-item.blog-card.delete-account-info .card-image img,
.card-item.blog-card.gallery-info .card-image img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card-item.blog-card.delete-account-info .card-image,
.card-item.blog-card.gallery-info .card-image {
    border: 1px solid #cecece;
}
main.GalleryListing .modal.fade.account-delete-modal.show .alert-content .craousel-slider ul.BrainhubCarousel__track.BrainhubCarousel__track--transition.BrainhubCarousel__track--draggable img.img-example {
    max-width: 633px;
    width: auto;
    height: auto;
    max-height: 550px;
}
main.GalleryListing .modal.fade.account-delete-modal.show .BrainhubCarousel .BrainhubCarousel__trackContainer {
    overflow: hidden;
    min-height: 550px;
}
main.GalleryListing .modal.fade.account-delete-modal.show .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
    min-height: 550px;
}
main.GalleryListing .modal.fade.account-delete-modal.show .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track li.BrainhubCarouselItem {
    display: inline-block;
}
.media-story ul li p {
    color: #fff;
    text-align: left;
    margin: 0;
    font-weight: 300;
    font-size: 14px;
}
.craousel-slider .media-story ul li.friend-count {
    text-align: left;
    font-size: 12px;
    color: #ddd;
}
ul.comments-list.listing-gallery-p.padding-top-10px li .user-img img.avatar__img {
    box-shadow: none;
    border: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}
ul.comments-list.listing-gallery-p.padding-top-10px li .user-img {
    border: 0;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    margin-top: 0;
}
ul.comments-list.listing-gallery-p.padding-top-10px .comment.listing-details {
    grid-template-columns: 50px auto;
    margin: 0;
}
ul.comments-list.listing-gallery-p.padding-top-10px .comment.listing-details .comment-body .meta-data span.comment__author {
    color: #ddd;
    font-size: 14px;
    font-weight: 400;
}
ul.comments-list.listing-gallery-p.padding-top-10px .comment.listing-details .comment-body {
    margin: -8px 0 10px;
    padding: 0;
}
.chat-module .full-width button:before {
    content: "\01F893";
    font-size: 33px;
    top: 12px;
    position: relative;
}
.edit-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
ul.covid-point .nav-tabs .nav-item.show .nav-link {
    color: #000;
    background-color: #fff;
    border-color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    border-bottom: 3px solid #326d62;
}
.EmailKatika .custom-checkbox input[type="checkbox"]:not(:checked) + label,
.MobileAbout .PressPage p,
.PermissionCheckBox .custom-checkbox input[type="checkbox"]:not(:checked) + label,
.billing-content.verify-acc .alert.alert-success,
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu .dropdown-toggle::after,
.cities.recent.BuyerPhotos .user-info-details ul.comments-list.listing-gallery-p .meta-data span.comment__author,
.edit-tabs .nav-tabs a,
main .user-login-info .user-side.login.DropMenu .dropdown button#dropdown-basic.dropdown-toggle:after,
main.home-1 ul.comments-list.listing-gallery-p.padding-top-10px .comment.listing-details .comment-body .meta-data span.comment__author,
ul.SocialMediaList.Delivery .SocialMediaName .SocialName.SocialMediaLinks h6,
ul.covid-point .nav-tabs a {
    color: #000;
}
.Collapsible {
    background-color: #fff;
}
.Collapsible__contentInner {
    padding: 10px;
    border: 1px solid #ebebeb;
    border-top: 0;
}
.Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
}
.Collapsible__contentInner p:last-child,
.EmailKatika .custom-checkbox,
.SeeAbout .input-box fieldset.form-group {
    margin-bottom: 0;
}
.Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: #333;
    position: relative;
    border: 1px solid #eeeeef;
    padding: 10px;
}
.Collapsible__trigger:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 30%;
    font-size: 40px;
    transition: transform 0.25s;
    color: #757280;
    transform: rotate(-130deg);
    -webkit-transform: rotate(45deg);
    border: solid #7e7b88;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
}
.Collapsible__trigger.is-open:after {
    transform: rotate(225deg);
    top: 50%;
}
.Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}
.CustomTriggerCSS {
    background-color: #f08080;
    transition: background-color 0.2s;
}
.CustomTriggerCSS--open {
    background-color: #483d8b;
}
.Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #cbb700;
    color: #000;
}
.chat-filter {
    display: inline-block;
    width: 100%;
    position: relative;
    min-height: 60px;
}
.chat-filter input[type="text"] {
    width: 83%;
    transition: width 0.4s ease-in-out;
    position: absolute;
    right: 0;
    top: 28px;
    height: auto;
    left: 0;
    z-index: 99;
    opacity: 1;
    padding: 4px 10px;
    font-size: 14px;
}
.chat-filter optgroup {
    border-bottom: 1px solid #f11200;
    margin: 0;
}
.chat-filter select.selectpicker optgroup option {
    font-size: 14px;
    color: #444;
}
.chat-filter hr {
    border-top: 1px solid #f11200;
    background-color: #f11200;
}
.search {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0;
    margin-top: 0;
    width: 18rem;
    height: 80px;
    z-index: 1;
    right: auto;
    padding: 25px 0;
}
.search__border {
    position: absolute;
    top: 28px;
    left: auto;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(0, 0);
    transition: width 0.4s 0.4s;
    right: 10px;
    background-image: url(../images/eva_search-fill.png);
    background-repeat: no-repeat;
    background-position: center;
}
#trigger:checked ~ .search__border {
    transition: width 0.4s 0.2s;
    width: 100%;
    height: 2rem;
    border-radius: 4px;
    background: #fff;
    right: 0;
    border: 1px solid;
}
#trigger:checked ~ .search__border:after {
    width: 45px;
    transition: width 0.2s cubic-bezier(0.42, -0.7, 0.62, 1.25);
}
.search__border:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 54px;
    width: 45px;
    background: 0 0;
    border-radius: 0.6rem;
    transform-origin: 100% 50%;
    transform: rotate(0) translateX(3rem);
    transition: width 0.2s 0.8s;
    top: 3px;
}
.search__input {
    position: relative;
    width: 26rem;
    height: 6rem;
    padding: 0.9rem 0 0.9rem 2.4rem;
    background: 0 0;
    outline: 0;
    border: none;
    font-size: 2.4rem;
    color: #000;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s;
}
#trigger:checked ~ .search__input {
    opacity: 1;
    z-index: auto;
    transition: opacity 0.2s 0.6s;
}
.search__checkbox {
    position: absolute;
    top: -9999px;
    left: -9999px;
    opacity: 0;
    z-index: -10;
}
.search__label-init {
    z-index: 2;
    position: absolute;
    top: 25px;
    left: auto;
    width: 30px;
    height: 33px;
    cursor: pointer;
    right: 5px;
    margin: 0;
}
.search__close,
.search__label-active {
    right: 0;
    position: absolute;
    cursor: pointer;
}
#trigger:checked ~ .search__label-init {
    transform: scale(0);
}
.search__label-active {
    top: 26px;
    width: 50px;
    height: 30px;
    transform: scale(0);
    z-index: 1111;
    margin: 0;
}
#trigger:checked ~ .search__label-active {
    transform: scale(1);
}
.search__close {
    top: 25px;
    width: 3rem;
    height: 2rem;
    z-index: 1;
    line-height: 30px;
}
#trigger:checked ~ .search__close {
    z-index: auto;
}
#trigger:checked ~ .search__close:before {
    transform: rotate(0);
    opacity: 1;
    transition: transform 0.2s cubic-bezier(0.73, 0.14, 0.4, 1.58) 0.6s, opacity 0.1s 0.6s;
}
#trigger:checked ~ .search__close:after {
    transform: rotate(45deg);
    opacity: 1;
    transition: transform 0.2s cubic-bezier(0.73, 0.14, 0.4, 1.58) 0.8s, opacity 0.1s 0.8s;
}
.search__close:after,
.search__close:before {
    content: "Close";
    position: absolute;
    top: 3px;
    left: auto;
    width: 48px;
    height: 30px;
    background: 0 0;
    border-radius: 0.5rem;
    opacity: 0;
    z-index: 11;
    right: 5px;
}
.search__close:before {
    transform: rotate(0) translateX(2rem);
    transition: transform 0.2s, opacity 0.1s 0.1s;
}
.search__close:after {
    content: "";
    transform: rotate(0) translateX(2rem);
    transition: transform 0.2s 0.2s, opacity 0.1s 0.3s;
}
.chat-filter select.selectpicker {
    padding-left: 10px;
    width: 190px;
    border: 0;
    height: 80px;
    position: relative;
    z-index: 1;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: 0 0;
}
.search.open_search {
    z-index: 9;
}
.action-icons {
    height: 80px;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px;
    border-left: 3px solid #fff;
    margin: 0 0 0 -1px;
}
.action-icons ul li {
    display: inline-block;
    margin: 0 3px;
}
.action-icons ul .vertical-border {
    display: inline-block;
    width: 1px;
    background: #555;
    height: 13px;
}
.action-icons ul li a,
.action-icons ul li button {
    color: #555;
    font-size: 18px;
    background: 0 0;
    border: 0;
}
main.dashboard-page .chat-module .sidebar-widget {
    margin-bottom: 0;
    padding: 15px 10px 0;
}
.btn-success.focus,
.btn-success:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
    box-shadow: none;
    background: 0 0;
    color: #000;
}
.top-right .bell-icon a {
    height: 36px;
    padding: 0;
    margin: 0;
    font-size: 26px;
    border-radius: 50%;
    width: 36px;
    text-align: center;
    line-height: 30px;
    background: #f11200;
    color: #fff;
    transition: background-color 0.5s;
}
.top-right .bell-icon a:hover {
    color: #fff;
    background: #2b273c;
}
.MoreFeatures .modal-body .sidebar.listing-page .panel-body .custom-checkbox.pop-check-box label,
.NeighborhoodsModal .modal-body .sidebar.listing-page .panel-body .custom-checkbox.pop-check-box label {
    font-weight: 400;
    font-size: 14px;
    color: #2b273c;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu ul.OptionMenu li a:hover,
.main-menu-content nav ul li.user-profile-name a,
main .user-login-info .user-side.login.DropMenu ul.OptionMenu li a:hover,
main.home-1 .main-menu-content nav ul li.user-profile-name a:hover,
main.home-1 .main-menu-content nav ul li.user-profile-name a:hover span {
    padding: 0;
    color: #f11200;
}
.hero-wrapper {
    padding-top: 0px;
    height: 620px;
    overflow: hidden;
}
main.listing-list .sort-by .sorted .show.dropdown .dropdown-menu.show a.dropdown-item {
    font-size: 13px;
    padding: 2px 15px;
}
.ShowHover a:hover .ShowHover,
.UserPicture:hover .UpdatePicture,
.navbar-display,
main.home-1 .modal.fade.account-delete-modal.show .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
    display: block;
}
main.home-1 .main-menu-content .user-side.login .dropdown button.dropdown-toggle.btn.btn-success {
    background: 0 0;
    border: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
}
.dropdown-menu.show a.dropdown-item .user-img {
    width: 50px;
    height: 50px;
    border: 1px solid red;
    border-radius: 5px;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu #user-picture,
main .user-login-info .user-side.login.DropMenu #user-picture,
main.home-1 .user-area #user-picture {
    grid-template-columns: 72px auto;
}
main.home-1 .user-area .dropdown-menu {
    min-width: 260px;
    left: 0 !important;
    transform: translate(-140px, 80px) !important;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu .user-profile,
main .user-login-info .user-side.login.DropMenu .user-profile,
main.home-1 .user-area .dropdown-menu .user-profile {
    width: 72px;
    height: 72px;
    border-radius: 3px;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu .user-information .user-name,
main .user-login-info .user-side.login.DropMenu #user-picture .user-information .user-name,
main.home-1 .user-area #user-picture .user-information .user-name {
    width: 180px;
}
main.home-1 .user-area #user-picture .user-information .user-name h2 {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1877f2;
}
main.home-1 .user-area .dropdown-menu::before {
    content: "";
    color: #f11200;
    font-weight: 700;
    position: absolute;
    right: 45px;
    top: -12px;
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 30px 30px 0;
    display: inline-block;
    background: #fff;
}
main.home-1 .user-area .main-menu-content nav ul li:last-child {
    float: none;
    margin: 0;
}
main.home-1 header.header-area .main-menu-content nav #user-picture .user-information ul.UserSocialInfo li:last-child {
    float: none;
    margin: 0;
    border: 0;
}
main.home-1 .user-area #user-picture .user-information .user-name h6 {
    font-weight: 500;
    font-size: 14px;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu .user-information ul.OptionMenu,
main .user-login-info .user-side.login.DropMenu ul.OptionMenu,
ul.OptionMenu {
    display: inline-block;
    width: 100%;
    padding: 0;
    margin-top: 10px;
}
.main-menu-content nav ul.OptionMenu li {
    display: inline-block;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 5px 20px;
}
.main-menu-content nav ul.OptionMenu li:hover {
    background: #eee;
}
.main-menu-content nav ul.OptionMenu li:last-child {
    float: none;
    color: #0073bb;
    margin-top: 0;
    padding: 5px 20px;
    border: 0;
    border-radius: 0;
}
.main-menu-content nav ul.OptionMenu li svg {
    font-size: 20px;
    display: inline-block;
    margin: 0 10px 0 0;
}
main.home-1 .main-menu-content nav ul.OptionMenu li a {
    white-space: normal;
    position: absolute;
    top: 7px;
    left: 50px;
    color: #0073bb;
    vertical-align: middle;
}
.main-menu-content nav ul.OptionMenu li:last-child:hover {
    background: #eee;
    border: 0;
}
main.home-1 .main-menu-content nav ul.OptionMenu li:last-child a {
    position: static;
    text-align: left;
}
main.home-1 .main-menu-content nav ul li.user-profile-name {
    float: none;
    display: grid;
    grid-template-columns: auto auto 70px;
}
main.home-1 .main-menu-content nav ul li.user-profile-name .user-side.Chat,
main.home-1 .main-menu-content nav ul li.user-profile-name .user-side.directory {
    height: auto;
    display: inline-block;
    margin: 15px 0 0;
    position: relative;
}
.notification {
    background: #f11200;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    position: absolute;
    z-index: 11;
    top: -8px;
    left: 0;
    line-height: 17px;
    color: #fff;
}
.bottom-btn {
    text-align: center;
    margin-top: 30px;
}
.bottom-btn button.btn.btn-default {
    background: #f11200;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    width: 230px;
    height: 54px;
}
.bottom-btn button.btn.btn-default svg {
    font-size: 26px;
}
.signup-desc {
    margin-bottom: 30px;
    width: 80%;
    margin-top: 50px;
}
.signup-desc p {
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    line-height: normal;
}
.about h1 {
    color: #000;
    font-size: 36px;
    font-weight: 600;
}
.steps h2 {
    font-size: 20px;
    font-weight: 600;
    color: #999;
    margin: 5px 0 20px;
}
.billing-content.BSignupForm {
    padding: 0;
    display: inline-block;
    width: 100%;
}
.billing-content.BSignupForm .contact-form-action.sign-up,
.billing-content.BSignupForm .contact-form-action.sign-up .input-box input.form-control {
    min-width: 487px;
}
.SignUpTopBar .logo.listing {
    padding: 0;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.SignUpTopBar .logo.listing.RemoveLogoWidth img {
    max-width: unset;
}
main.BusinessHome.TermsOfService,
main.signup-page.BizSignUp {
    background: #f7f7f7;
}
.SignUpBg {
    background: #fff;
    padding-left: 20px;
    padding-right: 20px;
}
section.k-class.BizFooter {
    background: #fff !important;
    padding-bottom: 30px !important;
}
.k-class.white-bg {
    background: #fff;
    border-top: 1px solid #eeeeef;
    margin-top: 0;
}
.k-class.white-bg .copy-right ul li,
.k-class.white-bg .copy-right ul li a {
    color: #4f4f4f;
    border: 0;
}
ul.list-items.SocialIcons li a {
    background: #4f4f4f;
    width: 22px;
    height: 22px;
    display: flex;
    border-radius: 50%;
    text-align: center;
    line-height: 22px;
    justify-content: center;
    align-items: center;
}
ul.list-items.SocialIcons li a svg {
    color: #fff;
    text-align: center;
    font-size: 13px;
}
ul.list-items.SocialIcons li a:hover {
    border: 0;
    background: #f11200;
}
.k-class.white-bg .copy-right ul li {
    border-right: 1px solid #ddd;
    font-weight: 400;
    font-size: 12px;
}
.k-class.white-bg .copy-right ul li:last-child {
    border-right: 0;
    font-weight: 400;
    font-size: 12px;
}
.TopHeader nav {
    line-height: 30px;
    text-align: right;
}
.TopHeader nav ul {
    line-height: 75px;
}
.main-menu-content.BHome .TopHeader nav ul li ul.dropdown-menu-item {
    top: 99px;
}
main.BusinessHome .logo.listing {
    height: 72px;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    width: 100%;
}
.main-menu-content nav ul li ul.dropdown-menu-item li {
    line-height: normal;
    padding-right: 0 !important;
}
.main-menu-content.BHome ul.BNavebar li a {
    color: #000;
    line-height: normal;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
}
main.BusinessHome .main-menu-content nav ul li:last-child {
    border: 0;
    float: none;
    border-radius: 0;
}
.main-menu-content.BHome .TopHeader nav ul li,
main.BusinessHome .main-menu-content nav ul li:last-child {
    border-bottom: 3px solid transparent;
    margin-right: 23px;
    padding: 0;
}
.TopHeader ul.BNavebar {
    text-align: right;
    padding: 20px 0;
}
.TopHeader ul.BNavebar li {
    display: inline-block;
    margin: 0 0 0 15px;
}
.TopHeader ul.BNavebar li:last-child {
    border: 1px solid #000;
    width: 69px;
    text-align: center;
    height: 35px;
    line-height: 35px;
    border-radius: 4px;
}
.main-menu-content.BHome .TopHeader nav ul li ul.dropdown-menu-item li {
    border-bottom: 0;
    border-color: #fff;
    margin: 0;
}
.main-menu-content nav ul li ul.dropdown-menu-item li:hover {
    border-bottom: 0;
    border-color: #fff;
}
main.BusinessHome .slick-slider button.slick-arrow.slick-next:before,
main.BusinessHome .slick-slider button.slick-arrow.slick-prev:before {
    content: "";
    border-bottom: 12px solid transparent;
    width: 12px;
    height: 12px;
    position: absolute;
    transform: rotate(180deg);
    left: 8px;
    top: 8px;
    border-top: 12px solid transparent;
}
.BuyerEmailList,
.EmailDesc,
.Heading,
.SeeAbout .input-box {
    border-bottom: 1px solid #eeeeef;
}
.BusinessInfo {
    background: #fff;
    padding: 35px;
    border-radius: 7px;
    text-align: left;
    width: 564px;
    height: 445px;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 72px;
    margin: auto;
    left: auto;
}
.katika-wraper {
    background: #ff4f00;
    padding: 50px 0;
    text-align: center;
    color: #fff;
    margin-top: -7px;
    margin-bottom: 80px;
}
.MobileView .katika-wraper {
    background: #000;
    padding: 20px 0;
    text-align: center;
    color: #fff;
    margin-top: 0;
    margin-bottom: 20px;
}
.footer-item .footer__title {
    width: 100%;
    margin: 0 0 15px;
    padding: 0 0 5px;
}
.MobileView .content-presence.BusinessInfo {
    margin-bottom: 30px;
    padding: 0;
}
.MobileView .katika-wraper h1 {
    font-size: 22px;
    font-weight: 800;
}
main.BusinessHome.BizHome section.testimonial-area {
    position: static;
    z-index: unset;
}
.katika-wraper h1 {
    font-size: 48px;
    font-weight: 800;
}
.BusinessInfo h1 {
    color: #000;
    text-align: left;
    font-weight: 900;
    width: 70%;
}
.BusinessInfo p {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #000;
    line-height: normal;
    margin-top: 20px;
    width: 70%;
}
.BHeading,
.BHeading h2,
.GetStarted {
    text-align: center;
}
.BusinessInfo a.btn.btn-default,
.BusinessInfo button.btn.btn-default {
    background: #f11200;
    text-align: left;
    color: #fff;
    margin-top: 50px;
    font-weight: 600;
}
main.BusinessHome section.hero-wrapper.hero-wrapper2 {
    height: 736px;
}
.BHeading h2 {
    font-size: 30px;
    font-weight: 700;
    color: #000;
    margin-bottom: 70px;
}
.BHeading {
    width: 100%;
}
main.BusinessHome .testimonial-item .testi-comment {
    background-color: #fff;
    box-shadow: none;
    border-radius: 8px;
    position: relative;
    padding: 0;
    margin-bottom: 42px;
    z-index: 1;
}
.hello.row {
    display: flex !important;
}
main.BusinessHome .testimonial-item .testi-comment h1 {
    font-size: 32px;
    font-weight: 900;
    color: #000;
    margin-bottom: 30px;
    text-align: left;
    width: 71%;
}
main.BusinessHome .testimonial-item .testi-comment .testi__desc {
    font-size: 16px;
    color: #000;
    text-align: left;
    line-height: 24px;
}
main.BusinessHome .sign-up-image img.random-img {
    width: auto;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 0;
    box-shadow: none;
    display: inline-block;
    position: static;
    min-height: auto;
}
main.BusinessHome .slick-slider button.slick-arrow.slick-prev:before {
    border-left: 18px solid #808996;
}
main.BusinessHome .slick-slider button.slick-arrow.slick-next:before {
    border-right: 18px solid #808996;
}
main.BusinessHome .places-carousel .slick-dots li {
    background: #c5c9cf;
    border: 0;
}
.MobileView .VideoBox,
.VideoBox {
    border-radius: 0;
    overflow: hidden;
}
.GetStarted {
    margin-top: 50px;
}
.GetStarted a,
.GetStarted button {
    background: #f11200;
    color: #fff;
    width: 245px;
    height: 50px;
    line-height: 40px;
}
.VideoContent h1,
.VideoContent h3,
.VideoContent p {
    color: #000;
    text-align: left;
}
.GetStarted a:hover,
.GetStarted button:hover {
    opacity: 0.8;
    color: #fff;
}
.Rectangle span {
    height: 430px;
    display: inline-block;
    background: #c4c4c4;
    width: 100%;
}
.Rectangle.Video {
    background: #eeeeef;
    padding: 0;
}
.VideoContent h1 {
    font-size: 30px;
    font-weight: 900;
}
.VideoContent h3 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
}
.VideoContent p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
}
.VideoContent span {
    height: auto;
    background: #eeeeef;
    display: contents;
}
.VideoBox {
    background: #000;
    height: 100%;
    padding: 50px 0;
    margin: 0 0 0 -1px;
}
.VideoBox h1 {
    font-size: 48px;
    color: #fff;
    font-weight: 700;
}
.VideoBox p {
    font-size: 17px;
    color: #fff;
    width: 80%;
    margin: 0 auto;
    line-height: 35px;
}
.VideoBox a.btn.btn-default {
    background: #f11200;
    color: #fff;
    margin-top: 50px;
    font-weight: 600;
}
.MobileView .VideoBox video,
.VideoBox video {
    width: 100%;
    border-radius: 7px;
    overflow: hidden;
}
.VideoContent img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.MobileView .VideoBox {
    background: #fff;
    height: 100%;
    padding: 40px 0 0;
}
.MobileView .VideoBox h1 {
    font-size: 26px;
    color: #b30c00;
    font-weight: 700;
    width: 80%;
    margin: 0 auto;
}
.FaqsQuestion .SubHeading {
    color: #000;
    font-size: 32px;
    font-weight: 900;
}
.MobileView .VideoBox p {
    font-size: 20px;
    color: #000;
    width: 80%;
    margin: 20px auto 0;
    line-height: 35px;
}
.MobileView .VideoBox a.btn.btn-default {
    background: #416b62;
    color: #fff;
    margin-top: 50px;
}
.OwnersSay .img-area {
    background: #c4c4c4;
    height: 247px;
}
.OwnersSay .comment {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 0;
    font-style: italic;
}
.OwnersSay .OwnerName {
    margin: 5px 0 0;
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    color: #000;
}
.SignUpDesc {
    font-size: 16px;
    color: #000;
    font-weight: 400;
}
.TermsBG {
    background: #fff;
    padding: 20px;
}
.TermsHeading h1 {
    font-size: 36px;
    font-weight: 600;
    color: #000;
}
.TermsContent p {
    color: #000;
    margin-bottom: 15px;
    font-size: 15px;
    margin-top: 40px;
}
.TermsContent h6 {
    margin-top: 25px;
    margin-bottom: 10px;
    font-weight: 700;
}
span.start {
    color: #f11200;
    font-size: 24px;
    position: relative;
    top: 5px;
    font-weight: 700;
    left: 5px;
}
main.signup-page.BizSignUp .input-box label {
    color: #000;
    font-size: 15px;
    margin: 0;
}
main.signup-page.BizSignUp .contact-form-action .form-control {
    padding: 12px 20px 12px 10px;
    border: 1px solid #8692a6;
}
.signup-desc.location {
    padding-left: 25px;
    margin-top: 70px;
}
.billing-content.BSignupForm.StepThree .online-business .custom-checkbox {
    margin-right: 0;
    position: relative;
}
.signup-desc.step4.location p b {
    color: #059360;
}
.signup-desc.step4.location p {
    width: 41%;
    margin: 30px auto 50px;
}
.BuyerName {
    font-size: 18px;
    font-weight: 700;
    color: #000;
}
.BuyerPagesHeading {
    font-size: 22px;
    font-weight: 700;
    color: #f11200;
    margin-bottom: 5px;
}
main.dashboard-page.BuyerProfile .Buyer-wrapper.BuyerEmail {
    grid-template-columns: 220px 721px auto;
}
main.dashboard-page.BuyerProfile.BuyerEmailNoti .contact-form-action p {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin: 0;
}
.EmailKatika,
.PhoneNumber,
main.BusinessHome .testimonial-item .testi-comment .testi__desc p,
main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent.BuyerLocation li .DeleteReviews,
span.more {
    margin-top: 10px;
}
.BuyerEmailList {
    display: grid;
    grid-template-columns: auto 220px;
    padding-bottom: 10px;
}
.TrashIcon,
.TrashIcon a,
.TrashIcon a span {
    display: inline-block;
}
.EmailAdd a,
.EmailAdd button {
    background: #f11200;
    color: #fff;
    border: 0;
    border-radius: 4px;
    padding: 6px 15px;
    font-size: 14px;
}
.BuyerEmailList.EmailListing {
    grid-template-columns: auto 50px;
    line-height: 65px;
    padding: 0;
}
.TrashIcon {
    padding: 17px 0;
    line-height: normal;
    text-align: right;
}
.TrashIcon a {
    width: 30px;
    height: 30px;
    border: 1px solid #eeeeef;
    text-align: center;
    border-radius: 2px;
}
.TrashIcon a span {
    line-height: normal;
}
.EmailDesc {
    padding: 0 0 10px;
}
.PhoneNumber {
    display: grid;
    grid-template-columns: 140px 330px;
}
.PhoneNumber input.form-control {
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 3px 0 0 3px;
    margin: 0;
}
.PhoneNumber input.form-control.Number {
    margin: 0 0 0 -1px;
    border-radius: 0 3px 3px 0;
}
.EmailAdd.PhoneNo {
    text-align: left;
    margin-top: 15px;
}
.EmailKatika .custom-checkbox input[type="checkbox"]:checked + label,
.PermissionCheckBox .custom-checkbox input[type="checkbox"]:checked + label {
    font-size: 15px;
    color: #000;
}
.EmailKatika .custom-checkbox input[type="checkbox"]:checked + label:before,
.PermissionCheckBox .custom-checkbox input[type="checkbox"]:checked + label:before {
    background: #0073bb;
}
main.dashboard-page.BuyerProfile.BuyerEmailNoti .EmailKatika p {
    padding-left: 26px;
    color: #a5a5a5;
}
.Permissions {
    display: grid;
    grid-template-columns: 155px auto;
    grid-column-gap: 30px;
}
.EditLocationForm label,
.PermissinHeading h5 {
    font-size: 14px;
    font-weight: 700;
    color: #000;
}
.EmailAdd.PhoneNo.SaveBtn {
    display: grid;
    grid-template-columns: 215px auto;
    padding: 0;
    grid-column-gap: 15px;
}
.EmailAdd.PhoneNo.SaveBtn button {
    padding: 6px 0;
}
main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent.BuyerLocation {
    display: grid;
    grid-template-columns: 721px auto;
}
main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent.BuyerLocation li {
    display: grid;
    grid-template-columns: auto auto;
    padding: 15px 0;
}
main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent.BuyerLocation .comment.listing-details {
    grid-template-columns: auto;
    margin: 0;
}
main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent.BuyerLocation .comment.listing-details p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
}
main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent.BuyerLocation .comment.listing-details p span {
    display: inline-block;
    width: 3px;
    height: 3px;
    background: #000;
    border-radius: 50%;
    position: relative;
    left: 2px;
    top: -3px;
}
main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent.BuyerLocation .comment .comment-body {
    margin-left: 0;
    padding-top: 0;
}
.EditLocationForm {
    width: 470px;
    margin-top: 15px;
}
.EmailAdd.EditLocation {
    text-align: left;
    margin-top: 30px;
    display: grid;
    grid-template-columns: 75px auto;
    grid-column-gap: 15px;
}
.EmailAdd.EditLocation a {
    margin-top: 6px;
}
.SettingSubHeading {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    margin-top: 30px;
}
main.dashboard-page.BuyerProfile .Buyer-wrapper.BuyerEmail label {
    font-weight: 400;
    font-size: 15px;
}
.BuyerEmailList.SettingPage {
    grid-template-columns: auto;
    margin-bottom: 10px;
}
.EmailAdd.Settings {
    text-align: left;
    display: grid;
    grid-template-columns: 130px 82px;
    grid-column-gap: 20px;
    margin-top: 20px;
}
.EmailAdd.Settings a {
    width: auto;
    margin-top: 5px;
}
.SeeAbout {
    width: 348px;
    display: inline-block;
    border: 1px solid #eeeeef;
    border-radius: 3px;
}
.Heading {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    padding: 15px;
}
main.dashboard-page.BuyerProfile .Buyer-wrapper.BuyerEmail .SeeAbout p.note {
    color: #a5a5a5;
    padding: 0 20px;
    font-size: 12px;
}
.SeeAbout .input-box {
    padding: 15px 0 15px 15px;
}
.TotalFriends {
    display: grid;
    grid-template-columns: 70px 35px;
}
.TotalFriends .Friends {
    font-size: 14px;
    font-weight: 800;
    color: #000;
    line-height: normal;
}
.BookMark {
    font-size: 14px;
    font-weight: 900;
    color: #000;
    line-height: normal;
}
.TotalFriends .BookMark span.Icon {
    background: #000;
    color: #fff;
    text-align: center;
    width: 18px;
    height: 18px;
    line-height: 16px;
    border-radius: 3px;
    display: inline-block;
}
.Buyer-Reviews .reviews-tabs.ManageFriends ul.comments-list li {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
}
.Buyer-Reviews .reviews-tabs.ManageFriends ul.comments-list li .DeleteReviews {
    text-align: right;
    line-height: 60px;
}
main.dashboard-page.BuyerProfile .reviews-tabs.ManageFriends .comment.listing-details span.comment__author {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    line-height: normal;
    color: #0073bb;
}
.Buyer-Reviews .reviews-tabs.ManageFriends ul.comments-list li .comment-body,
.cities.recent.BuyerPhotos,
.cities.recent.BuyerPhotos .main-menu-content.listin_nav,
main.listing-list.post-list.banner .sidebar.listing-page.post-list-sidebar {
    padding-top: 0;
}
main.dashboard-page.BuyerProfile .reviews-tabs.ManageFriends .comment.listing-details p {
    font-size: 12px;
    font-weight: 900;
    color: #000;
}
.BuyerContent.FriendsManage .sorted .dropdown button {
    background: 0 0;
    border: 0;
    color: #0073bb;
    padding: 0;
}
.BuyerContent.FriendsManage .sorted {
    width: 200px;
}
.EmailAdd.EditLocation a,
.EmailAdd.Settings a {
    background: 0 0;
    color: #0073bb;
    border: 0;
}
.Buyer-Reviews.BuyerSecurity {
    grid-template-columns: 220px auto auto;
}
.CheckFB {
    font-size: 16px;
    font-weight: 700;
    color: #4f4f4f;
}
.FBDisconnectBtn button {
    border: 1px solid #999;
    border-radius: 3px;
    width: 237px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #4f4f4f;
    margin-top: 25px;
    background: 0 0;
}
.FBDisconnectBtn button svg {
    font-size: 21px;
    color: #999;
}
main.dashboard-page.BuyerProfile.BuyerEmailNoti .EmailKatika.E-Applications.Twitter p {
    padding-left: 0;
    color: #4f4f4f;
}
.TWDisconnectBtn button {
    border: 0;
    background: #74a9e6;
    border-radius: 3px;
    color: #fff;
    width: 210px;
    height: 36px;
    margin-top: 15px;
}
.GDisconnectBtn button {
    background: #fff;
    border: 1px solid #a5a5a5;
    box-sizing: border-box;
    border-radius: 3px;
    width: 221px;
    height: 38px;
    font-size: 14px;
    font-weight: 700;
    color: #4f4f4f;
    margin-top: 15px;
}
.AppleDisconnectBtn button {
    width: 210px;
    height: 36px;
    background: #000;
    border-radius: 3px;
    color: #fff;
    border: 0;
}
main.BusinessHome.AboutUs .CommonBreadcrumb {
    background-image: url("https://katika-images.s3.us-east-2.amazonaws.com/category/About1633936356.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}
main.BusinessHome.AboutUs .Rectangle.Video .VideoBox {
    background-image: url("https://katika-images.s3.us-east-2.amazonaws.com/category/About%20Box1633936924.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.CommonBreadcrumb {
    background: #c4c4c4;
    min-height: 480px;
    display: flex;
    width: 100%;
    position: relative;
    margin-top: 95px;
    align-items: center;
}
.CommonBreadcrumb h1 {
    font-size: 48px;
    font-weight: 900;
    color: #000;
    margin: 0 auto;
    width: 50%;
    text-align: center;
    text-shadow: 0 4px 4px rgb(0 0 0 / 25%);
}
section.TabsArea .y-page-link {
    position: relative;
    display: inline-block;
    width: 100%;
}
section.TabsArea .nav-tabs {
    float: right;
    display: inline-block;
    border-bottom: 0;
    width: 490px;
    margin-top: -43px;
}
main.dashboard-page.BuyerProfile.BuyerReviews.BookMarks .comment .comment-body .meta-data p a,
section.TabsArea .tab-content {
    width: 100%;
    display: inline-block;
}
section.TabsArea .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: inline-block;
}
section.TabsArea a.nav-item.nav-link {
    color: #999;
    font-size: 15px;
    border-bottom: 3px solid transparent;
}
section.TabsArea a.nav-item.nav-link.active {
    border-bottom-color: #f11200;
    color: #000;
}
.password label {
    font-size: 16px;
    font-weight: 700;
}
.password p {
    font-size: 12px;
    font-weight: 400;
    color: #a5a5a5;
    margin: 0;
}
.PressPage h2 {
    font-size: 32px;
    font-weight: 900;
    color: #000;
    text-align: center;
    text-transform: uppercase;
}
.PressPage.communityPage p {
    width: 80%;
    color: #000;
}
.PressPage p {
    width: 595px;
    margin: 15px auto 0;
    text-align: center;
    color: #000;
    font-size: 18px;
    font-weight: 400;
}
.PressKitBtn a.kit-btn,
.PressKitBtn button {
    width: 370px;
    background: #f11200;
    height: 55px;
}
.FaqsQuestion,
.Lists {
    width: 80%;
    margin: 0 auto;
}
.Lists ul li {
    margin-bottom: 40px;
}
.Lists ul li span {
    width: 16px;
    height: 16px;
    background: #000;
    display: inline-block;
    border-radius: 50%;
}
.Lists ul li h5 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    display: inline-block;
    margin: 0 0 0 25px;
}
.Lists ul li a {
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    padding-left: 41px;
}
.PressKitBtn {
    text-align: center;
    margin-bottom: 60px;
}
.PressKitBtn a.kit-btn {
    border-radius: 40px;
    border: 0;
    color: #fff;
    font-weight: 700;
    display: inline-block;
    line-height: 54px;
}
.PressKitBtn button {
    border-radius: 40px;
    border: 0;
    color: #fff;
    font-weight: 700;
}
.SubHeading {
    font-size: 24px;
    font-weight: 800;
    color: #000;
}
.FaqsQuestion section.faq-area .btn {
    padding-left: 0;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
.FaqsQuestion section.faq-area .accordion-item .card .btn i {
    background-color: transparent;
    box-shadow: none;
    color: #000;
}
.FaqsQuestion section.faq-area .accordion-item .card:not(:last-of-type),
.accordion-item .card:not(:first-of-type) {
    border-radius: 0;
    border-bottom: 1px solid #999;
}
.FaqsQuestion section.faq-area .card.mb-3 {
    margin-bottom: 0 !important;
}
.FaqsQuestion section.faq-area .accordion-item .card .card-body {
    padding: 0 40px 25px 10px;
    font-weight: 400;
    line-height: normal;
    font-size: 14px;
    color: #000;
}
.partners h4 {
    font-size: 28px;
    font-weight: 900;
    color: #f11200;
}
.partners p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin: 15px auto 0;
}
.CommonBreadcrumb p,
main.BusinessHome.AboutUs .partners h4 {
    font-size: 24px;
    color: #000;
}
main.BusinessHome.AboutUs section.TeamItem.about-team {
    justify-content: center;
    grid-template-columns: 292px 226px;
}
.ConnectInsta,
.MobileImages,
section.TeamItem,
section.TeamItem.about-team.padding-bottom-100px {
    width: 100%;
    text-align: center;
}
main.BusinessHome .logo.listing img {
    width: auto;
    max-width: unset;
}
.main-menu-content.BHome ul.BNavebar li {
    margin: 0 0 0 13px;
}
.main-menu-content.BHome ul.BNavebar li a.LoginBtn {
    background: #f11200;
    color: #fff;
    width: 69px;
    height: 35px;
    display: inline-block;
    line-height: 35px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #f11200;
}
.KatikaMusic .Music {
    width: 452px;
    margin: 0 auto;
    box-shadow: 0 0 10px #999;
    padding: 25px;
    border-radius: 7px;
}
.KatikaMusic .Music h2 {
    font-size: 36px;
    font-weight: 700;
    color: #000;
    margin-top: 5px;
}
.KatikaMusic .Music p {
    font-size: 18px;
    font-weight: 500;
    color: #000;
}
.KatikaMusic .Music p b {
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;
    margin-top: 45px;
    color: #000;
    font-weight: 700;
    font-size: 18px;
}
.KatikaMusic .Music a,
.KatikaMusic .Music button {
    text-align: center;
    line-height: 3;
    background: #f11200;
    border: 0;
    color: #fff;
    width: 371px;
    height: 55px;
    border-radius: 40px;
    margin: 80px auto 20px;
    display: block;
    font-size: 18px;
    font-weight: 700;
}
span.CountNumber:after,
span.CountNumber:before {
    border-style: solid;
    border-width: 4px 4px 4px 0;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    width: 0;
    height: 0;
    pointer-events: none;
    content: "";
}
.CommonBreadcrumb p {
    font-weight: 400;
    margin: 0 auto;
    width: 50%;
    text-align: center;
    padding-top: 0;
}
.ConnectHeading h1 {
    font-size: 32px;
    font-weight: 900;
    color: #000;
}
section.WhatConnectUs {
    background: #f3f3f3;
    padding-top: 60px;
    padding-bottom: 50px;
}
main.BusinessHome.AboutUs .BHeading p {
    width: 545px;
    text-align: center;
    margin: 0 auto 50px;
}
main.BusinessHome.AboutUs .PressPage p {
    width: 570px;
}
main.BusinessHome.AboutUs .Rectangle.Video .VideoContent {
    width: 470px;
}
main.BusinessHome.AboutUs .VideoBox {
    border-radius: 0;
    overflow: hidden;
    width: 482px;
    background: #4f4f4f;
    display: inline-block;
    height: 422px;
}
main.BusinessHome.TermsOfService .main-menu-content.BHome.TP {
    position: static;
    text-align: right;
    margin-top: 15px;
}
span.CountNumber {
    display: inline-block;
    width: auto;
    margin-left: 2px;
    position: relative;
    padding: 0 5px 0 4px;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    font-weight: 700;
    color: #666;
    font-size: 12px;
    line-height: 1.5em;
    transition: 0.2s ease-in-out;
}
span.CountNumber:before {
    display: inline-block;
    border-color: transparent #d2d2d2 transparent transparent;
    left: -4px;
}
span.CountNumber:after {
    border-color: transparent #fff transparent transparent;
    left: -3px;
    display: inline-block;
}
.ReviewsWrote {
    min-height: 410px;
    max-height: 410px;
    overflow: auto;
}
.card-item.col-last.first-col.single-img .card-image {
    height: 297px;
    padding-top: 80%;
}
.btn-link:hover {
    text-decoration: none;
}
main.home-1 .user-info-details {
    top: 0;
    bottom: auto;
    background: 0 0;
}
.FriendCount,
main.home-1 .modal.fade.account-delete-modal.show .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track li.BrainhubCarouselItem {
    display: inline-block;
}
main.home-1 .modal.fade.account-delete-modal.show .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track li.BrainhubCarouselItem img.img-example {
    max-width: 633px;
    width: auto;
    height: auto;
    max-height: 550px;
}
ul.BottomMenu {
    padding: 0;
    margin-top: 50px;
}
ul.BottomMenu li a {
    font-weight: 400;
    font-size: 12px;
}
ul.BottomMenu li span.dashboard-icon img {
    margin: 0 10px -4px 0;
    float: left;
}
.rating-rating .ReviewsDate {
    display: inline-block;
    color: #000;
}
main.dashboard-page.BuyerProfile.BuyerReviews.UserReviews .ReviewsBtn {
    margin-top: 20px;
    display: inline-flex;
}
main.dashboard-page.BuyerProfile.BuyerReviews.UserReviews .ReviewsBtn button.btn.btn-none {
    border: 1px solid #999;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 700;
    color: #999;
    margin: 0 15px 0 0;
    min-width: 100px;
    text-align: left;
    position: relative;
}
main.dashboard-page.BuyerProfile.BuyerReviews.UserReviews .ReviewsBtn button.btn.btn-none span {
    margin: 0 5px 0 0;
}
.comment.listing-details .rating-rating svg.svg-inline--fa.fa-star:after {
    background: red;
    content: "";
}
.comment.listing-details .rating-rating.rating-detail span {
    width: auto;
    background: 0 0;
    height: auto;
}
.main-menu-content.listin_nav nav ul li .dropdown-menu-item {
    width: 375px;
    padding: 25px;
    border-radius: 2px;
}
.main-menu-content.listin_nav nav ul li .dropdown-menu-item li {
    width: 50%;
    display: inline-block;
    border: 0;
}
.main-menu-content.listin_nav nav ul li .dropdown-menu-item li a,
.main-menu-content.listin_nav nav ul li .dropdown-menu-item li:first-child a {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #2b273c;
    border: 0;
    line-height: normal;
    margin-bottom: 5px;
}
.main-menu-content.listin_nav nav ul li {
    border-bottom: 4px solid transparent;
}
.main-menu-content.listin_nav nav ul li:last-child {
    float: none;
    border: 0;
    border-radius: 0;
    border-bottom: 4px solid transparent;
}
.main-menu-content.listin_nav nav ul li .dropdown-menu-item li a:hover,
.main-menu-content.listin_nav nav ul li .dropdown-menu-item li:first-child a:hover {
    background: #eeeeef;
    color: #2b273c;
}
.main-menu-content.listin_nav nav ul li:hover {
    color: #ff6b6b;
    border-color: #f11200;
}
.write-review {
    display: grid;
    grid-template-columns: 510px auto;
}
.CountNumber,
.CrumbBread,
.FriendCount span,
.InstaBtn button {
    display: inline-block;
}
.FriendCount span {
    position: relative;
    width: 120px;
}
.FriendCount span .ImageOne,
.FriendCount span .ImageTwo {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    top: 0;
    display: inline-block;
}
.SavedThis {
    font-size: 10px;
    font-weight: 500;
    color: #000;
}
.CountNumber {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 0 10px;
}
.InstaBtn,
.LoginORInsta {
    margin: 0 auto;
    text-align: center;
    width: 90%;
}
.FriendCount span .ImageOne {
    position: relative;
    z-index: 2;
}
.FriendCount span .ImageTwo {
    position: relative;
    z-index: 1;
    left: -10px;
}
.LoginORInsta {
    height: 1px;
    background: #ddd;
    position: relative;
}
.LoginORInsta span {
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: -13px;
    background: #fff;
    width: 44px;
}
.ConnectInsta h4 {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin: 20px 0;
    color: #000;
}
.InstaBtn button {
    min-width: 100%;
    border: 1px solid #c4c4c4 !important;
    border-radius: 5px !important;
    color: #000 !important;
    background: 0 0 !important;
}
.rating-rating.rating-detail.landing span span span {
    height: 25px;
    margin-bottom: 10px;
}
.rating-rating.rating-detail.landing span span span img.icon {
    width: 25px;
}
main .user-login-info .user-side.login.DropMenu {
    overflow: inherit;
    position: relative;
    z-index: 99;
    height: auto;
}
main .user-login-info .user-side.login.DropMenu .show.dropdown a.dropdown-item {
    padding: 5px 0 5px 10px;
}
main .user-login-info .user-side.login.DropMenu .show.dropdown button#dropdown-basic {
    background: #fff;
    border: 0;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu .dropdown-menu.show,
main .user-login-info .user-side.login.DropMenu .show.dropdown .dropdown-menu.show {
    min-width: 260px;
    left: 0 !important;
    transform: translate(-150px, 45px) !important;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu .dropdown-menu.show #user-picture .user-profile img {
    width: 61px;
    height: 61px;
    border-radius: 3px;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu .user-information .user-name h2,
main .user-login-info .user-side.login.DropMenu #user-picture .user-information .user-name h2 {
    margin-bottom: 0;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1877f2;
    text-align: left;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu .user-information .user-name h6,
main .user-login-info .user-side.login.DropMenu #user-picture .user-information .user-name h6 {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu ul.OptionMenu li,
main .user-login-info .user-side.login.DropMenu ul.OptionMenu li {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 5px 20px;
}
.cities.recent.BuyerPhotos .top-right.user-login-info .user-side.login.DropMenu ul.OptionMenu li a,
main .user-login-info .user-side.login.DropMenu ul.OptionMenu li a {
    white-space: normal;
    color: #0073bb;
    vertical-align: middle;
    /* width: 120px; */
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}
main .user-login-info .user-side.login.DropMenu .dropdown button#dropdown-basic {
    background: #fff;
    border: 0;
    padding: 0;
    margin: -5px 0 0;
}
.ReviewsWrote button.btn.btn-link {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
    text-align: left;
    padding-left: 10px;
    color: #0073bb;
}
main .user-login-info .user-side.login.DropMenu #user-picture .user-profile,
main.home-1 .main-menu-content .user-side.login .show.dropdown .user-profile {
    border-radius: 3px;
    width: 61px;
    height: 61px;
    position: relative;
    padding-top: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.15);
}
main .user-login-info .user-side.login.DropMenu #user-picture .user-profile img,
main.home-1 .main-menu-content .user-side.login .show.dropdown .user-profile img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
    border-radius: 3px;
}
.CrumbBread {
    width: 100%;
    margin: 50px 0 5px;
}
.CrumbBread a {
    font-size: 12px;
    font-weight: 600;
}
.CrumbBread a.Active {
    font-weight: 400;
    color: #2b273c;
}
.BName {
    position: absolute;
    bottom: 0;
    font-size: 12px;
    text-align: center;
    width: 100%;
    background: #000;
    border-radius: 0 0 4px 4px;
}
.cities.recent.BuyerPhotos .card-item {
    padding: 0;
    border: 0;
}
.BusinessHeading {
    font-size: 18px;
    font-weight: 600;
    color: #f11200;
    margin: 0 0 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
}
.cities.recent.BuyerPhotos .user-info-details {
    position: absolute;
    bottom: auto;
    left: 0;
    right: 0;
    width: 100%;
    background-color: transparent;
    padding: 15px 0 0 15px;
    top: 0;
}
main.BusinessHome .main-menu-content.BHome li.user-profile-name a img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.chat-module .full-width li.chat-message img {
    width: 30px;
    height: 30px;
}
main.dashboard-page.BuyerProfile .faq-forum .input-box.form-group textarea#things_i_love {
    padding-left: 12px;
}
.change-password .form-area .password label.Instructions {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
}
.UserPicture {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    cursor: pointer;
}
.UpdatePicture {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 3px;
    display: none;
    text-align: center;
    line-height: 70px;
}
.UpdatePicture svg {
    font-size: 30px;
    color: #fff;
}
.ShowHover {
    width: 345px;
    margin: 0 auto 20px;
    background: #fff;
    padding: 12px;
    position: relative;
    display: inline-block;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    text-align: left;
}
.CommonBoxColor,
.DownArrow,
.ListNumber,
.MidCustomWidth,
.media-video,
span.bg-search {
    text-align: center;
}
.hover-active-details p.count {
    color: #333;
    display: inline;
    font-size: 12px;
}
.ShowHover .HoverItem .hover-image {
    position: relative;
    height: 90px;
    padding-top: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.15);
    width: 90px;
}
.ShowHover:after {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #2f2f2f;
    font-size: 0;
    line-height: 0;
    float: left;
}
.DownArrow {
    position: absolute;
    bottom: -17px;
    left: 0;
    right: 0;
}
.DownArrow::before {
    transform: rotate(45deg);
}
.DownArrow::after {
    position: relative;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #fff;
    left: 0;
    transform: rotate(-45deg);
}
.ShowHover .HoverItem {
    display: inline-block;
    float: left;
    border-right: 12px solid transparent;
}
a.mouseOver {
    padding-top: 10px;
}
.DirectionMap {
    position: relative;
    height: 600px;
    margin-bottom: 50px;
    margin-top: 50px;
}
.MapHeight {
    position: absolute;
    top: 0;
}
.WarningMessage,
.app,
.btn-box {
    position: relative;
}
ul.generic-nav button.btn.btn-none {
    border: 0;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    padding: 0 15px 10px;
}
main.directions ul.generic-nav li {
    margin: 0 20px 0 0;
}
.btn:focus {
    outline: 0;
    border: 0;
    box-shadow: none;
}
main.directions input.form-control.c1.near-feild.pac-target-input:before {
    background: red;
    width: 50px;
    height: 50px;
    display: inline-block;
}
main.directions .filter .form-group span img.media-object {
    height: 18px;
}
main.directions .filter .form-group span {
    position: absolute;
    left: 10px;
    bottom: 0;
    top: 10px;
    margin: auto;
}
.AddAddress input.form-control.c1.near-feild.pac-target-input {
    padding-left: 30px;
    font-size: 14px;
}
main.directions table tbody tr td:first-child {
    width: 8%;
}
main.directions table tbody tr td:nth-child(2) {
    width: 73%;
}
main.directions table tbody tr td:nth-child(3) {
    width: 19%;
}
.alert-content .btn-box button {
    background: 0 0;
    color: #999;
    box-shadow: none;
    position: absolute;
    right: -45px;
    top: -30px;
}
.btn-box {
    z-index: 1;
}
.slider-content .photo-count.btn-box {
    position: static;
    top: 0;
    right: 0;
    display: inline-block;
    width: 100%;
    text-align: left;
}
.slider-content .photo-count.btn-box button.theme-btn.border-0.border-0.mr-1 {
    width: 100%;
    text-align: left;
    display: inline-block;
    position: static;
}
main.GalleryListing .slider-content {
    display: inline-block;
    text-align: left;
    width: 100%;
    padding: 0 24px 8px 0;
}
main.GalleryListing .modal-content.p-4 {
    height: 83vh;
    overflow: hidden;
}
.app {
    display: flex;
    width: 100%;
}
.WarningMessage {
    background: #f0f2f5;
    border-radius: 5px;
    padding: 10px 0;
}
.media-img,
span.user-preview {
    background-color: rgba(0, 0, 0, 0.15);
    overflow: hidden;
}
.w-icon {
    width: 40px;
    display: inline-block;
    height: 40px;
    position: relative;
}
.media-img,
.media-video {
    margin: 0 auto;
    width: 372px;
    position: relative;
}
.w-icon img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
}
.WarningMessage p.note.one {
    display: inline-block;
    width: 90%;
    margin-left: 45px;
    font-size: 14px;
    line-height: normal;
}
span.user-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px;
    display: inline-block;
    margin-top: 10px;
}
span.user-preview {
    width: 44px;
    border: 3px solid #fff;
    height: 44px;
    position: absolute;
    left: 23px;
    top: 23px;
    border-radius: 100%;
    z-index: 1;
}
.cities.stories-landing .story.custom-story.col-md-2 {
    max-width: 16.666667%;
    width: 16.666667%;
    margin: 0;
}
.cities.stories-landing .stories.carousel .story > .item-link > .item-preview {
    display: block;
    box-sizing: border-box;
    font-size: 0;
    max-height: none;
    overflow: hidden;
    transition: transform 0.2s;
    position: relative;
    height: 269px !important;
    padding-top: 100%;
    border-radius: 7px;
}
.cities.stories-landing .stories.carousel .story > .item-link > .item-preview img {
    display: block;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
}
.media-img {
    padding-top: 50%;
}
.media-img img.media {
    height: 85%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    object-fit: cover;
}
.media-video {
    height: 100%;
}
.media-video video {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.story.custom-story.no-padding {
    padding: 0 1px !important;
    margin: 1px 0;
    border-radius: 0;
}
.story.custom-story {
    width: 170px !important;
    max-width: 170px;
    height: 225px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.manual-link button.copy-link {
    border: 0;
    background: 0 0;
    display: inline-block;
    color: #f11200;
    padding-left: 7px;
    font-size: 12px;
    font-weight: 500;
}
.story.custom-story span.item-preview {
    position: relative;
    height: 225px;
    overflow: hidden;
    width: 100%;
    display: inline-block;
}
.story.custom-story span.item-preview img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
    border-radius: 0;
}
section.dashboard-area.account-page.seller-stories .BuyerContent {
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 3px;
    padding: 15px;
}
.sidebar.section-bg.details-sidebar .service .btn-box button.r-button-d-block.btn {
    height: auto;
    min-height: 48px;
}
.application.scroll-height {
    height: 100vh;
    overflow-y: scroll;
}
.StripeElement {
    border: 3px solid #1660cf;
    border-radius: 5px;
    height: 50px;
    padding: 13px 10px;
    margin: -20px 0 20px;
}
.StripeElement.StripeElement--empty,
.StripeElement.StripeElement--empty:focus,
.StripeElement.StripeElement--focus {
    border-color: #b2bcca;
}
.StripeElement.StripeElement--complete,
.StripeElement.StripeElement--focus,
.StripeElement.StripeElement--focus.StripeElement--complete {
    border-color: #1660cf;
}
.StripeElement.StripeElement--focus,
.StripeElement.StripeElement--invalid,
.StripeElement.StripeElement--invalid:focus {
    border-color: #dc3545;
}
span.bg-search {
    width: auto;
    padding: 5px;
    background: #8d8c8c;
    color: #fff;
    border-radius: 16px;
}
section.manage-post .chat-meta-button {
    background: #e4dede;
}
.MobileImages img {
    width: 90%;
}
.heading {
    width: 90%;
    margin: 0 auto;
}
.MobileAppView {
    background: #000;
    border-radius: 15px;
    width: 90%;
    margin: 0 auto;
}
.MobileAppView h1 {
    color: #fff;
    font-weight: 800;
}
.MobileAbout .Rectangle.Video .col-lg-6.col-sm-6 {
    padding-left: 15px;
    padding-right: 15px;
}
.MobileAbout .VideoContent h1 {
    font-size: 29px;
    text-align: left;
}
main.BusinessHome.AboutUs .MobileAbout .Rectangle.Video .VideoBox {
    background: 0 0;
    width: 90%;
    padding: 0;
    height: auto;
}
.MobileAbout .partners p,
main.dashboard-page .dashboard-area .y-page-link ul.SocailImages li:first-child {
    margin-left: 0;
}
.MobileAbout section.TeamItem .Items {
    margin: 0 auto 20px;
    width: 100%;
}
.color-black {
    color: #000 !important;
}
.MobilePartner .PressPage p,
.MobilePartner .partners h4,
.MobilePartner .partners p {
    text-align: left;
    margin-left: 0;
}
.MobilePartner h5 {
    font-weight: 800;
    font-size: 26px;
}
.MobilePartner .partners button.btn.btn-primary {
    border-radius: 50px;
}
.MobilePartner section.TeamItem .Items {
    margin: 0 10px 20px;
    width: 140px;
    display: inline-block;
}
.MobilePress .Lists ul li span {
    float: left;
    margin: 4px 0 0;
}
.MobilePress .Lists ul li h5 {
    margin: 0 0 0 15px;
    width: 90%;
}
.MobileCOMMUNITY .CommonBreadcrumb:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
}
.MobileCOMMUNITY h1 {
    color: #fff;
    position: relative;
    z-index: 11;
}
.SignUpSelection {
    border: 1px solid #ddd;
    border-radius: 6px;
}
.SelectUser {
    border: 1px solid #ddd;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    width: 100%;
}
.signupBtn {
    background: #0dcaf0;
    border-color: #0dcaf0;
    border-radius: 5px;
    color: #000;
}
.SelectUser.active-user,
.SelectUser:hover {
    background: #fff6f6;
    border-color: #f11200;
}
.SelectRadio input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    width: 22px;
    height: 22px;
    border: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.SelectRadio {
    height: 22px;
    width: 22px;
    border: 1px solid #e5e5e5;
    border-radius: 50px;
    float: right;
    position: absolute;
    right: 5px;
    top: 5px;
    background: #fff;
}
.SelectRadio input[type="radio"]:after {
    width: 18px;
    height: 18px;
    border-radius: 35px;
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    background-color: #fff;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 0;
    right: 0;
    margin: auto;
}
.SelectRadio input[type="radio"]:checked:after {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: 0;
    left: -1px;
    position: absolute;
    background-color: #f11200;
    content: "";
    visibility: visible;
    border: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
main.login-page .form-group.login-btn.loginPage span {
    width: 100% !important;
    height: 36px;
    line-height: 36px;
    border-radius: 50px;
    box-shadow: none;
    background: #4285f4 !important;
    color: #fff;
    display: flex;
    padding: 0 !important;
}
main.login-page .form-group.login-btn.loginPage button.btn.btn-info.whole-btn {
    background: 0 0 !important;
    border: 2px solid #000 !important;
    color: #000;
}
.SignUpSelection.LoginSection .input-box input.form-control {
    padding: 10px;
    height: 34px;
}
button.btn.btn-primary.rounded-50 {
    border-radius: 50px;
    width: 100%;
}
.SignUpSelection.LoginSection .contact-form-action {
    width: 60%;
    margin: 40px auto 0;
}
a.btn.btn-primary {
    background: #f11200;
    border-color: #f11200;
    margin: 0 15px 0 0;
}
select {
    -webkit-appearance: none;
    appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAfQAAAH0Bx0gPAAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACeSURBVDiN1ZPBDcIwDEWfMwt7sAQLMEWvnaRRC0dG4tY9+FyMZKgTIZULkSwl9n9PudgkseeUXfQvBAYcgQNQJT2+gswKcAbuACsgYAKKJHrlv56cWQEGfwioPYnDNeSH12AMzTmTODyH3CiJGIiSJUocXj7hN0EiuThY/L6BN4JEcvVK4VSQSJpwU5BIUlgS1tsFMzsBSLo1M/+/TE92ZfjD1tAH3gAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: center right 10px;
}
.billing-form-item .billing-content .contact-form-action.sign-up {
    width: 75%;
}
main.signup-page.login-page .section-devid {
    height: 10px;
}
.color-primary {
    color: #f11200 !important;
}
main.BusinessHome.BizHome.dashboard-page.Plans.account-page section.dashboard-area.account-page .y-page-link {
    padding: 13px 20px;
    border-radius: 16px;
}
.side-nav-container,
.side-user-panel {
    position: fixed;
    top: 0;
    left: 0;
    width: 85%;
    height: 100%;
    background-color: #fff;
    overflow-x: hidden;
    z-index: 999;
    transition: 0.4s cubic-bezier(0.7, 0, 0.2, 1);
    box-shadow: 0 0 40px rgb(82 85 90 / 10%);
    transform: translateX(-100%);
    max-width: 300px;
}
.side-nav-container .side-menu-ul li a,
.side-user-panel .side-menu-ul li a {
    font-size: 16px;
    color: #000;
    display: block;
    text-transform: capitalize;
    font-weight: 500;
    padding: 14px 20px;
    transition: 0.3s;
    position: relative;
}
.side-nav-container .side-menu-ul li a {
    border-bottom: 1px solid #000;
    font-weight: 600;
    font-size: 18px;
}
.disabled-link {
    pointer-events: none;
}
.terms-checkbox.new-signup p.term-url a,
section.form-shared.login-page .terms-checkbox.new-signup p.term-url {
    color: #999;
    font-size: 10px;
}
main.signup-page.login-page.Verify-buyer-account h3.widget-title.font-size-28.padding-top-40px.pb-0 {
    font-size: 18px !important;
    font-weight: 600;
}
.ListNumber {
    background: #74767e;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    color: #fff;
    font-size: 20px;
    flex-shrink: 0;
}
.ColorCode,
.EditIcon a {
    color: #555;
}
.SocialMediaLinks {
    line-height: normal;
    font-size: 14px;
}
main.dashboard-page .dashboard-area .y-page-link ul.SocialMediaList li {
    margin-bottom: 20px;
    display: inline-block;
    height: auto;
}
.CommonBoxColor {
    width: 70px;
}
.ColorBox {
    width: 40px;
    height: 40px;
}
.ColorCode {
    font-size: 12px;
}
.ColorRed {
    background: red;
}
.ColorGreen {
    background: #0f0;
}
.ColorBlue {
    background: #00f;
}
.ColorYellow {
    background: #ff0;
}
.FontFamiles input[type="checkbox"] {
    margin: 0 10px 0 0;
    float: left;
}
.FontFamiles img {
    max-height: 25px;
    padding: 0;
}
.reviews-tabs.SocialMedia .SocialQuestions h6 {
    color: #222;
    margin-bottom: 5px;
    font-size: 15px;
}
main.dashboard-page .dashboard-area .y-page-link ul.SocailImages li {
    height: auto;
    display: inline-block;
    flex-basis: auto;
    margin: 0 10px;
}
.BottomDesc {
    width: 96%;
    margin: 0 auto;
}
.SubmitBtn button {
    background: #000;
    color: #fff;
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 5px;
    font-style: italic;
}
.reviews-tabs.SocialMedia .BottomDesc p {
    font-size: 14px;
    color: #999;
    font-weight: 500;
    margin: 20px 0 0;
}
.DeliveryImg {
    width: 250px;
    display: inline-block;
}
.DeliveyBtns {
    margin-left: 40px;
}
.DeliveyBtns button.Btns {
    border: 0;
    border-radius: 60px;
    color: #fff;
    padding: 5px 10px;
    margin: 0 7px;
    width: 120px;
    font-size: 14px;
}
.ActivityDate {
    background: #efeff0;
    max-width: 90px;
    border-radius: 0 15px 15px 0;
    position: relative;
    left: -15px;
    padding: 3px 0 3px 10px;
    font-size: 17px;
    font-weight: 600;
}
main.dashboard-page .dashboard-area .y-page-link ul.ActivityList li {
    display: flex;
    flex-shrink: 0;
    grid-gap: 15px;
    gap: 15px;
    border: 0;
    height: auto;
    padding-bottom: 15px;
}
.ActivityDetail span.ActivityText {
    color: #595959;
}
span.DateActivity i {
    color: #999ba0;
    font-size: 14px;
}
.ActivityIcon:after {
    content: "";
    position: absolute;
    width: 1px;
    background: #d9d9d9;
    left: 0;
    right: 0;
    margin: auto;
    top: 60px;
    min-height: 17px;
    bottom: 0;
}
.ActivityDetail {
    width: 100%;
    border-bottom: 1px solid #efeff0;
    padding: 0 0 10px;
}
.ActivityDetail button {
    color: #f11200;
    font-size: 14px;
    font-weight: 500;
}
main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs.SocialMedia .form-area ul.react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected {
    border-color: #f11200 !important;
    color: #f11200 !important;
}
.SocialMediaName .SocialName.SocialMediaDescp {
    width: 31%;
    margin-top: 5px;
}
.SocialMediaName .SocialName.SocialMediaDescp p {
    line-height: normal;
    color: #000;
}
.ActivityBox ul.ActivityList li.disabled {
    cursor: no-drop;
}
.ActivityBox ul.ActivityList li.pointer {
    cursor: pointer;
}
.DeliveyBtns button:disabled {
    cursor: no-drop;
    pointer-events: unset !important;
}
.check-color input.form-control {
    padding: 0.375rem;
}
.MediaDrop .rotate-270 svg {
    transform: rotate(270deg);
    font-size: 26px;
    color: #000;
}
.MediaDrop .rotate-220 svg {
    transform: rotate(211deg);
    font-size: 26px;
    color: #000;
}
main.dashboard-page.media-page .MediaDrop select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, center;
    background-image: none;
}
.saved-bookmark {
    position: sticky;
    top: 130px;
}
.bookmark-post-title {
    color: #65676b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.bookmark-post-subtitle {
    margin-top: 25px;
    color: #999;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.bookmark-post-subtitle a:hover {
    text-decoration: underline !important;
    color: #999;
}
.list-post-bookmark .comment.listing-details {
    grid-template-columns: 38px auto;
}
.list-post-bookmark .comment.listing-details .user-img {
    width: 38px;
    height: 38px;
    margin: 10px 0;
}
.list-post-bookmark .comment-body .meta-data {
    color: #000;
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
}
.list-post-bookmark .comment-body .meta-data span {
    color: #000;
    font-size: 12px;
    font-weight: 600;
}
.terms.b-funnel {
    bottom: 20px;
}
section.WhoWeAre img {
    width: 353px;
    height: 198px;
    object-fit: cover;
}
.TestimonialAreaLanding img,
.content-presence img {
    width: 460px;
    height: 400px;
    object-fit: cover;
    border-radius: 20px;
}
.LeftCustomWidth,
.RightCustomWidth {
    flex: auto;
    /* max-width: 360px;
    min-width: 280px; */
    background-color: #fff;
    /* padding: 0; */
}
.MidCustomWidth {
    flex: auto;
    /* max-width: 680px;
    width: 680px; */
    margin: 0 auto;
}
.cities.recent .card-item.col-last {
    padding: 0;
    background: #fff;
}
.saved-bookmark .Ads img {
    max-width: 300px;
    min-width: 300px;
    width: 300px;
    height: 300px;
}
@media screen and (min-width: 2000px) {
    .custom-container {
        max-width: 1465px;
        margin: 0 auto;
    }
    .LeftCustomWidth,
    .RightCustomWidth {
        flex: auto;
        max-width: 300px;
        min-width: 280px;
    }
    .MidCustomWidth {
        flex: auto;
        max-width: 680px;
        width: 680px;
        margin: 0 auto;
        text-align: left;
    }
    main.home-1 .cities.recent.posts .card-image.slider-gallery .slick-slider .slick-list .slick-track .slide-slider video {
        max-height: 450px;
    }
}
/* @media screen and (min-width: 1700px) {
    .MidCustomWidth {
        flex: auto;
        max-width: 680px;
        width: 680px;
        margin: 0 auto;
        text-align: left;
    }
}
@media screen and (min-width: 1367px) and (max-width: 1699px) {
    .LeftCustomWidth,
    .RightCustomWidth {
        flex: auto;
        max-width: 300px;
        min-width: 280px;
        padding: 0;
    }
    .MidCustomWidth {
        flex: auto;
        max-width: 590px;
        width: 590px;
        margin: 0 auto;
        text-align: left;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1366px) {
    .LeftCustomWidth,
    .RightCustomWidth {
        flex: auto;
        max-width: 300px;
        min-width: 280px;
        padding: 0;
    }
    .MidCustomWidth {
        flex: auto;
        max-width: 500px;
        width: 500px;
        margin: 0 auto;
        text-align: left;
    }
    main.home-1 .cities.recent.posts .card-image.slider-gallery .slick-slider .slick-list .slick-track .slide-slider video {
        max-height: 450px;
    }
} */
main.dashboard-page.account-page.faqs-section .package_plan-tab .nav-item a.nav-link {
    width: 50%;
    border: 0;
    margin: 0;
    border: 1px solid transparent;
    border-bottom: 1px solid #BCBCBC;
    border-radius: 0;
    color: #BCBCBC;
}
main.dashboard-page.account-page.faqs-section .package_plan-tab .nav-item a.nav-link.active {
    background: transparent;
    color: #F11200;
    border-color: #BCBCBC;
}
main.dashboard-page.account-page.faqs-section .package_plan-tab .nav-item{
    margin: 0;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .LandingTopLogo img {
        width: 100%;
    }
    .LandingPage .content-presence.BusinessInfo .w-75,
    .MainHeadingLanding,
    ul.SignMeUpPoints {
        width: 100% !important;
    }
    .MainHeadingLanding h1 {
        font-weight: 600;
        font-size: 26px;
    }
    main.BusinessHome.BizHome.LandingPage section.hero-wrapper2.bizbanner {
        height: 450px;
    }
    .MainLandingImg iframe {
        width: 100%;
        margin-bottom: 20px;
    }
    section.WhoWeAre {
        margin-top: 60px;
    }
    .WhoWeAreContent p {
        text-align: left;
    }
    .TestimonialAreaLanding img,
    .content-presence img {
        width: 100%;
        max-height: 200px;
        border-radius: 10px;
    }
    section.WhoWeAre .WhoWeAreContent img {
        margin-bottom: 15px;
        width: 100%;
    }
    .ChangeOrder-2 {
        order: 2;
    }
    .LandingPage .content-presence.BusinessInfo h1 {
        text-align: left;
        font-size: 20px;
        line-height: normal;
    }
    .LandingPage .content-presence.BusinessInfo p {
        margin-top: 5px;
        text-align: left;
    }
    .SubHedingLanding h1 {
        font-size: 2rem;
    }
    .LandingForm input {
        border-radius: 10px;
        margin-bottom: 10px;
    }
    .WhoWeAreContent h1 {
        text-align: left;
        margin: 0 0 20px;
    }
    section.PricePlanLanding .padding-bottom-70px {
        padding-bottom: 0;
    }
    .MainHeadingLanding p {
        font-size: 16px;
    }
    .SignMeLanding h2 {
        font-size: 25px;
        margin-bottom: 15px;
    }
}
