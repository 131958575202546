@media screen and (max-width: 1199px) {
    .side-menu-open {
        display: none;
    }

    .logo.listing img {
        width: 100%;
    }

    .logo.listing {
        text-align: center;
        transform: translateX(00%);
        position: static;
    }

    .search-feild {
        position: relative;
    }

    .main-search-input-item.search-input button.button.theme-btn {
        position: absolute;
        right: 0;
        top: 0;
    }

    #user-picture {
        display: grid;
        grid-template-columns: 220px 450px auto;
        grid-column-gap: 15px;
    }

    main.dashboard-page.BuyerProfile .Buyer-wrapper {
        grid-template-columns: 180px 500px auto;
    }

    .top-right {
        margin-top: 20px;
        width: auto;
        text-align: center;
        display: inline-block;
    }

    .top-right a {
        padding: 4px;
    }

    main .user-login-info .user-area {
        width: auto;
        display: inline-block;
    }

    .user-side {
        display: inline-block;
    }

    main .user-login-info .user-side.login.DropMenu {
        width: auto;
    }

    .nav-listing-search .main-search-input-item input.react-autosuggest__input {
        width: 250px;
    }

    .main-search-input-item.search-input input.form-control,
    .main-search-input-item.location .css-2b097c-container {
        width: 275px;
    }

    .chat-room .friend-list .list-group-item {
        grid-template-columns: 50px 165px 72px;
    }

    section.k-class.BizFooter .logo {
        position: static;
        transform: translateX(0%);
    }

    .chat-area .chat-empty {
        width: 100%;
    }

    .cities.recent.BuyerPhotos .top-right.user-login-info .user-area {
        display: inline-block;
    }

    .container.nav-listing-search {
        max-width: 1200px;
    }

    .cities.recent.BuyerPhotos .main-menu-content.listin_nav {
        display: block;
    }

    .main-menu-content.listin_nav ul li {
        padding-right: 8px !important;
    }

    .main-menu-content.listin_nav nav ul li .dropdown-menu-item {
        right: 0;
        left: auto;
    }

    .b-listing {
        min-width: 485px;
    }

    .map-side.sticky,
    .side-bar-left.sticky {
        min-width: 100%;
    }

    .main-menu-content {
        display: block;
    }
}

@media screen and (max-width: 991px) {
    .Rectangle.Video .VideoContent {
        width: 100%;
    }

    .logo.listing img {
        width: 160px;
    }

    .nav-listing-search .main-search-input-item input.react-autosuggest__input,
    .main-search-input-item.search-input input.form-control {
        width: 230px;
    }

    main.dashboard-page.BuyerProfile .Buyer-wrapper {
        grid-template-columns: 180px 500px auto;
    }

    #user-picture {
        grid-template-columns: 220px 260px auto;
    }

    main.dashboard-page.BuyerProfile .Buyer-wrapper {
        grid-template-columns: 180px 310px auto;
    }

    section.k-class.BizFooter .logo {
        position: static;
        text-align: left;
        padding-left: 12px;
    }

    .copy-right .copyright-left-side.justify-content-end.d-flex {
        justify-content: center !important;
    }

    .copyright-right-side {
        flex: auto;
        justify-content: center !important;
    }

    section.k-class.footer-area.BizFooter .col-lg-3.col-md-3.col-sm-3.column-td-6.logo img {
        width: 160px;
        text-align: right;
    }

    main.dashboard-page.BuyerProfile.BuyerFriends .Buyer-wrapper {
        grid-template-columns: 220px auto auto;
    }

    .cities.recent.BuyerPhotos .top-right.user-login-info .user-area {
        display: inline-block;
    }

    .chat-area .chat-empty {
        width: 100%;
    }

    .chat-room .friend-list .list-group-item {
        grid-template-columns: 50px 90px 72px;
    }

    .search {
        width: 14rem;
    }

    .main-search-input-item.search-input .contact-form-action form {
        grid-template-columns: auto 1px auto 50px;
    }

    .top-right.user-login-info {
        width: 100%;
    }

    #page-content-wrapper {
        min-width: 100%;
    }
}

@media screen and (max-width: 767px) {

    .side-nav-container .side-menu-ul li .dropdown-menu-item li:first-child,
    .side-user-panel .side-menu-ul li .dropdown-menu-item li:first-child {
        border-top: 1px solid rgba(255, 255, 255, 0.12);
        padding-top: 0px;
    }

    .side-nav-container .side-menu-ul li.active .dropdown-menu-item,
    .side-user-panel .side-menu-ul li.active .dropdown-menu-item {
        margin-left: 15px;
    }

    .side-nav-container .side-menu-ul li .dropdown-menu-item li a, .side-user-panel .side-menu-ul li .dropdown-menu-item li a {
        padding-left: 15px;
        font-weight: normal;
        font-size: 18px;
        border: 0;
        margin: 10px 0;
    }

    .side-nav-container .side-menu-ul li.active a {
        margin-left: 15px;
        padding-left: 0;
    }

    /* ////////////////////////////////////////////////////////////////////////////// */

    .k-class.white-bg {
        border: 0;
    }

    .KatikaMusic .Music button,
    .KatikaMusic .Music a {
        width: auto;
        max-width: 280px
    }
    main.BusinessHome.FaqPages.GetHelp .PressPage p{
        width: 100%;
    }
    .MobileHelp .contact-help {
        padding: 0;
        box-shadow: none;
    }
    .footer-item .footer__title {
        border-bottom: 1px solid #000;
    }

    .PressPage h2 {
        font-size: 26px;
    }

    .PressPage.communityPage p {
        width: 100%;
    }

    main.BusinessHome.AboutUs section.TeamItem.about-team {
        grid-template-columns: auto;
    }

    .CommShare a {
        font-size: 15px;
        word-break: break-all;
    }

    main.BusinessHome.FaqPages.Community ul.Community-Posts li {
        grid-template-columns: auto;
    }

    .input-box.state-checkout-input {
        margin-bottom: 10px;
    }

    .processSignUp .content-area ul li {
        display: inline-flex;
    }

    .processSignUp .content-area ul li svg {
        margin-right: 5px;
    }

    .processBtn .btn-p a {
        width: 100%;
    }

    main.signup-page.BizSignUp .SignUpBg .sign-up-image img.random-img {
        width: 100%;
    }

    section.TeamItem.about-team.padding-bottom-100px {
        display: inline-block;
    }

    .BLanding.BNavabar.bLanding .side-menu-open {
        margin-right: 0px;
    }

    .BLanding.BNavabar.bLanding .logo.listing {
        text-align: left;
    }

    .BLanding.BNavabar.bLanding .logo.listing img {
        min-width: 200px;
    }

    section.dashboard-area.business-detail.u-photos span.badge-toggle.b-right {
        right: 5px;
    }

    main.dashboard-page section.dashboard-area.business-detail.business-price ul.price.p1 li .custom-legend fieldset.form-group {
        grid-template-columns: 50% 50%;
    }

    /* ////////////////////////////////////////////////////////////////////////////////////// */
    .share-story {
        float: none;
        margin-top: 10px;
    }

    ul.comparison-card-list {
        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3;
    }

    .mid-border {
        height: 1px;
        border-bottom: 1px solid #EEEEEF;
        width: 100% !important;
        position: relative;
        margin-bottom: 20px;
        margin-top: 20px;
        border-left: 0;
        bottom: 0;

    }

    .Rectangle.Video .VideoContent {
        width: auto;
    }

    .owner {
        margin-top: 10px;
        padding-left: 0;
    }

    .logo {
        position: static;
        transform: translateX(0%);
    }

    .main-search-input-item.search-input button.button.theme-btn {
        position: static;
    }

    .container.nav-listing-search {
        max-width: none;
    }

    .theme-btn1 {
        border-radius: 4px;
        width: 100%;
    }

    .main-search-input .main-search-input-btn {
        margin-top: 0;
    }

    .side-menu-open {
        display: block;
    }

    .part {
        width: 156px;
    }

    .browse-category {
        margin-bottom: 30px;
    }

    .category.text-center {
        padding: 20px 0 20px;
    }

    .icon-box {
        margin-bottom: 20px;
    }

    .icon-box-layout {
        border-radius: 4px;
        padding-top: 0;
        padding: 20px 10px 20px 10px;
    }

    .katika-app {
        padding: 30px 0 0px 0;
    }

    .footer-area {
        padding: 0px 0 20px !important;
    }

    .copy-right {
        margin-top: 0;
        padding-top: 0;
    }

    .footer-item {
        margin-bottom: 0;
        margin-top: 25px;
    }

    .list-items li {
        margin-bottom: 0;
    }

    .contact-form-action .form-control.c1.near-feild {
        border-radius: 4px;
    }

    .main-search-input .contact-form-action input {
        border-radius: 4px;
    }

    .logo.listing {
        text-align: center;
        display: block;
        margin: auto;
        position: static;
        transform: translateX(0);
        padding: 20px 0 10px 0;
    }

    .main-search-input-item.search-input .contact-form-action form {
        display: inline-block;
        width: 100%;
    }

    .search-feild {
        margin-top: 10px;
        height: auto;
    }

    .main-search-input-item.search-input input.form-control,
    .main-search-input-item.location .css-2b097c-container {
        border: 0;
        padding: 12px 0px 12px 15px;
        width: 100%;
        height: 46px;
        border-bottom: 1px solid #C4C4C4;
        border-radius: 0;
    }

    .main-search-input-item.location .css-2b097c-container {
        padding-left: 0;
    }

    .main-search-input-item.search-input button.button.theme-btn {
        border-radius: 4px;
        width: 100%;
        margin-top: 25px;
    }

    .main-search-input-item.location .css-2b097c-container .css-yk16xz-control {
        border-left: 0;
        border-bottom: 1px solid #C4C4C4;
    }

    .top-right {
        margin-top: 10px;
    }

    /* main.listing-list .side-menu-open,
    main.listing-details .side-menu-open {
        display: none;
    } */
    .breadcrumb-area {
        padding-top: 90px;
    }

    .breadcrumb-content .breadcrumb__title {
        font-size: 30px;
    }

    .BusinessHour span.rest-info {
        display: inline-block;
        margin-top: 5px;
    }

    .BusinessHour span.rest-info img {
        margin: -3px 0 0;
    }

    .write-review a {
        margin: 0 5px 15px;
    }

    .direction-btn {
        width: 155px;
    }

    .single-listing-wrap.details h2.widget-title {
        text-align: left;
    }

    main.dashboard-page.BuyerProfile .main-menu-content.listin_nav {
        display: none;
    }

    h2.all-result {
        margin-top: 20px;
    }

    h2.heading-listing,
    h2.all-result {
        font-size: 18px;
    }

    .card-item.card-listing.d-flex.listing-page {
        margin-bottom: 15px;
    }

    .card-content-wrap.lists .card-content {
        padding: 0 15px 15px 15px;
    }

    .bottom_buttons {
        padding: 9px;
    }

    .sidebar.listing-page {
        margin-top: 0;
    }

    section.card-area.padding-top-40px.padding-bottom-100px {
        padding-top: 0;
    }

    main.dashboard-page .dashboard-area .y-page-link a.web-link {
        top: 0;
        line-break: anywhere;
    }

    #sidebar-wrapper {
        margin-left: auto;
    }

    #page-content-wrapper,
    main.dashboard-page header.header-area {
        padding-top: 0px;
    }

    main.dashboard-page #sidebar-wrapper {
        padding-top: 40px;
    }

    main.dashboard-page .sidebar.section-bg {
        margin-bottom: 20px;
    }

    section.dashboard-area.business-detail .y-page-link h2 {
        margin-top: 30px;
    }

    main.dashboard-page .dashboard-area .y-page-link ul li span.text-rigt {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
        text-align: right;
    }

    .google-map {
        width: 100%;
    }

    .hero-wrapper2 .category-item {
        padding: 10px 0 10px 25px;
        width: 140px;
    }

    .bottom-heading {
        bottom: 10px;
    }

    .hero-wrapper2 .category-item .icon-element {
        left: 0;
    }

    .card-item.blog-card {
        margin-top: 20px;
        border-radius: 5px;
    }

    main.listing-details .search-feild .main-search-input-item.search-input button.button.theme-btn {
        margin: 0;
        border-radius: 0;
    }

    main.listing-details .search-feild input.form-control {
        border: 0;
        margin: 0 0 10px 0;
        border-bottom: 1px solid #C4C4C4;
    }

    .single-listing-wrap .write-review a,
    .single-listing-wrap .write-review button.btn.btn-none {
        display: inline-block;
        width: 47%;
        margin-top: 20px;
    }

    .single-listing-wrap .write-review button.btn.btn-none {
        margin-top: 0;
    }

    section.single-listing-area .direction-btn {
        width: 155px;
        top: 25px;
    }

    section.single-listing-area {
        padding: 0;
    }

    main.listing-list section.card-area {
        padding-top: 0;
        padding-bottom: 0;
    }

    .billing-content {
        padding: 20px 0 0 0;
    }

    .user-img-place {
        width: 100%;
        height: 100%;
        margin: 50px auto 0;
    }

    main.signup-page .signup-picture .user-img-place {
        height: auto;
    }

    .form-shared.login-page .input-box input.form-control,
    .form-shared.login-page .contact-form-action,
    main.login-page.b-login.b .contact-form-action {
        width: 100%;
    }

    .form-group.login-btn span button.theme-btn,
    .form-group.login-btn button,
    .form-group.login-btn span {
        width: 100% !important;

    }

    section.form-shared.login-page.padding-top-40px.padding-bottom-100px {
        display: inline-block;
    }

    section.form-shared.login-page .input-box {
        width: 100%;
    }

    .col-lg-6.mx-auto.signup-picture {
        order: 1;
    }

    .col-lg-6.mx-auto {
        order: 2;
    }

    main.dashboard-page .side-menu-open {
        right: 15px !important;
        top: 30px;
    }

    main.dashboard-page section.dashboard-area.business-detail .y-page-link h2 {
        font-size: 18px;
        margin-top: 14px;
        width: 78%;
    }

    section.dashboard-area.business-detail .listing-map.gmaps {
        margin-top: -30px;
    }

    section.dashboard-area.business-detail .y-page-link {
        padding-bottom: 15px;
        padding-top: 0;
    }

    span.dashboard-icon {
        text-align: center;
        display: inline-block;
        margin: 14px 15px 0 0px;
    }

    main.dashboard-page .dashboard-area.deactive-account .y-page-link {
        padding: 20px 15px 100px;
    }

    main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul.covid-point li,
    main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul.covid-point li:last-child {
        display: grid;
        grid-template-columns: 90% 10%;
    }

    main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul li span.la-star {
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    section.dashboard-area.business-detail.u-photos .y-page-link .two-clmn {
        display: grid;
        grid-template-columns: auto auto;
    }

    .card-image .badge-toggle.b-left {
        left: 5px;
        top: 5px;
    }

    main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul li span.text-rigt {
        width: 70%;
        text-align: left;
    }

    main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul li span.text-left {
        float: left;
        width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    main.dashboard-page.details-info section.dashboard-area.business-detail .y-page-link ul li span.text-rigt {
        width: 50%;
        text-align: left;
        white-space: normal;
    }

    .key-field {
        width: 100%;
    }

    main.dashboard-page .dashboard-area .y-page-link.call-to-act {
        min-height: auto;
    }

    .password.save-button {
        position: relative;
        margin-bottom: 0;
    }

    main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs .tabs-content ul.comments-list li .react-rater .react-rater-star:before {
        left: auto;

    }

    .write-b-review {
        grid-template-columns: auto;
    }

    .review-btns.react-review-button {
        text-align: center;
    }

    .review-btns.react-review-button p {
        display: none;
    }

    .review-btns.react-review-button button {
        margin-top: 15px;
    }

    main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs .tabs-content ul.comments-list li .comment.listing-details .comment .comment-body .meta-data {
        text-align: left;
    }

    main.dashboard-page section.dashboard-area.security .y-page-link.reviews .reviews-tabs .form-area ul.react-tabs__tab-list li {
        margin: 0 9px 0 0;
    }

    main.dashboard-page.stats-page .total-stats ul li {
        flex-basis: 50%;
        height: auto;
    }

    .total-stats {
        margin-top: 20px;
    }

    .responsive-table {
        overflow: auto;
    }

    .category-item.radius-rounded a {
        text-align: left;
    }

    section.k-class.footer-area.BizFooter .col-lg-3.col-md-3.col-sm-3.column-td-6.logo img {
        margin-top: 50px;
    }

    .back-to-top.show-back-to-top {
        bottom: 10px;
    }

    .nav-listing-search .main-search-input-item input.react-autosuggest__input {
        border-bottom: 1px solid #C4C4C4;
        width: 100%;
        border-radius: 0;
    }

    .cities.recent.BuyerPhotos .main-menu-content.listin_nav {
        display: none;
    }

    .b-listing {
        min-width: auto;
    }

    .sidebar.listing-page {
        position: static;
        padding-bottom: 0;
    }

    .ListingBox {
        flex-direction: column;
    }

    .card-area .row {
        display: inline-block;
    }

    .side-menu-open {
        transform: translateY(-305px);
    }

    h2.heading-listing {
        width: 50%;
        margin-top: 0px;
        white-space: normal;
    }

    main.listing-details .side-menu-open {
        transform: translateY(-285px);
    }

    .write-review {
        grid-template-columns: auto;
    }

    .comment.listing-details {
        text-align: left;
    }

    .product-img {
        width: 45%;
        padding-top: 52%;
    }

    .single-listing-wrap {
        margin-bottom: 0;
    }

    section.single-listing-area .details.single-listing-wrap .comments-wrap {
        margin-bottom: 0;
    }

    main.dashboard-page.BuyerProfile .side-menu-open {
        top: 0;
    }

    #user-picture,
    main.dashboard-page.BuyerProfile .Buyer-wrapper {
        grid-template-columns: auto;
        grid-column-gap: 0;
        margin: 0;
    }

    .Buyer-Reviews .comment.listing-details {
        grid-template-columns: 64px 250px auto;
        margin: 0 0 10px 0;
    }

    ul.VoteReviews li {
        display: inline-block;
        width: 50%;
    }

    .user-profile {
        margin: 0 auto;
    }

    .user-info {
        height: auto;

    }

    .uploads-update {
        height: auto;
        padding: 0;
        margin-top: 15px;
        border: 0;
    }

    main.dashboard-page.BuyerProfile.BuyerFriends .Buyer-wrapper {
        grid-template-columns: auto;
    }

    .sorted .manage-friend {
        margin-top: 0;
    }

    .frineds-list ul {
        grid-template-columns: auto auto;

    }

    .Buyer-Reviews {
        grid-template-columns: auto;
        grid-column-gap: 0;
    }

    .search {
        width: 100%;
    }

    .chat-filter select.selectpicker {
        padding-left: 0px;
        width: 80%;
        background: transparent;
    }

    main.dashboard-page.BuyerProfile.BuyerReviews.BookMarks .comment.listing-details {
        grid-template-columns: 64px 250px auto;
    }

    .chat-module .add-caret label.caret {
        margin-left: -13px;
    }

    main.home-1 .side-menu-open {
        transform: translateY(-45%);
        padding: 10px;
    }

    .BuyerContent {
        padding-left: 0;
    }

    .BuyerContent .contact-form-action .form-group input.form-control,
    .BuyerContent .contact-form-action textarea.message-control.form-control,
    .BuyerContent .contact-form-action .form-group select.form-control {
        width: 100%;
    }

    .Buyer-Reviews.BuyerSecurity,
    main.dashboard-page.BuyerProfile .Buyer-wrapper.BuyerEmail {
        grid-template-columns: auto;
    }

    .BuyerEmailList {
        grid-template-columns: auto auto;
    }

    section.TabsArea .nav-tabs {
        width: 100%;
    }

    section.TabsArea a.nav-item.nav-link,
    section.TabsArea a.nav-item.nav-link.active {
        width: auto;
    }

    section.TabsArea .nav-tabs {
        margin: 0;
    }

    .buyer-info {
        border-left: 0;
        padding-left: 0;
    }

    .EmailKatika .custom-checkbox {
        margin-bottom: 0;
        padding-left: 0;
    }

    .Permissions {
        grid-template-columns: 145px auto;
    }

    main.BusinessHome.AboutUs .PressPage p {
        width: 100%;
    }

    section.TeamItem {
        display: inline-block;
        grid-template-columns: 213px;
    }

    section.TeamItem .Items {
        margin: 0 0 20px 0;
    }

    section.TeamItem .Items {
        margin: 0 auto 20px;
        width: 213px;
    }

    main.BusinessHome.AboutUs .BHeading p {
        width: 95%;
    }

    .CommonBreadcrumb h1 {
        font-size: 30px;
        width: 80%;
        margin-top: 60px;
    }

    .CommonBreadcrumb p {
        margin: 20px auto 0;
        width: 70%;
        font-size: 20px;
    }

    .CommonBreadcrumb {
        padding-bottom: 50px;
    }

    main.BusinessHome.AboutUs .Rectangle.Video .VideoContent {
        width: 95%;
        margin: 0 auto;
    }

    main.BusinessHome.AboutUs .Rectangle.Video .VideoContent p {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .VideoContent h1 {
        font-size: 20px;
        text-align: center;
    }

    .partners p {
        margin: 15px auto 30px;
    }

    .ConnectHeading.padding-bottom-100px {
        padding-bottom: 30px;
    }

    section.testimonial-area.padding-top-100px.text-center {
        padding-top: 20px;
    }

    section.TeamItem.padding-bottom-100px {
        padding-bottom: 20px;
    }

    .main-menu-content.BHome {
        display: block;
    }

    main.BusinessHome.AboutUs .side-menu-open,
    main.BusinessHome.FaqPages .side-menu-open {
        transform: translateY(-20px);
        margin-right: 15px;
        background-color: #fff !important;
    }

    .TopHeader nav {
        display: none;
    }

    .main-menu-content.BHome .TopHeader .logo.listing {
        width: 80%;
        text-align: left;
        display: inline-block;
        padding: 0;
    }

    main.BusinessHome.AboutUs .CommonBreadcrumb,
    main.BusinessHome.FaqPages .CommonBreadcrumb {
        min-height: auto;
    }

    .KatikaMusic .Music button,
    main.BusinessHome.FaqPages .FaqsQuestion,
    .Lists,
    .KatikaMusic .Music,
    .EditLocationForm,
    .PressPage p {
        width: 100%;
    }

    .PressKitBtn.padding-top-140px.padding-bottom-100px {
        padding: 0;
    }

    .cities {
        padding-top: 20px;
    }

    .cities h2 {
        margin: 0 0 20px;
    }

    .tabs_heading h5.sec__title.undefined {
        padding: 15px 0 0 0;

    }

    .cities .row.mt-5 {
        margin-top: 0px !important;
    }

    main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent.BuyerLocation {
        grid-template-columns: auto;
    }

    .BuyerContent.BuyerLocation.BuyerFindFriend .BuyerEmailList {
        grid-template-columns: auto;
    }

    .info-list-2 .BuyerEmail {
        margin-top: 50px;
        position: static;
    }

    .nav-listing-search .main-search-input-item #react-autowhatever-1 {
        z-index: 999;
    }

    .BusinessDetail {
        grid-template-columns: 32px auto;
    }

    main.GalleryListing .tabs-top .rc-tabs.rc-tabs-top .rc-tabs-nav-container.rc-tabs-nav-container-scrolling {
        width: 100%;
    }

    .uploads-photo {
        width: 100%;
        margin-top: 20px;
    }

    .instructions {
        grid-template-columns: auto auto;

    }

    .instructions .instruction {
        margin-bottom: 30px;
    }

    .photo-upload {
        min-height: 250px;
        margin: 0;
    }

    .covid ul {
        grid-template-columns: auto auto;
    }

    section.single-listing-area .direction-btn {
        margin-top: 0px;
    }

    main.dashboard-page.details-info section.dashboard-area.business-detail.u-photos .y-page-link .two-clmn {
        grid-template-columns: 50% 50%;
    }

    main.dashboard-page section.dashboard-area.business-detail .y-page-link .listing-map.gmaps {
        margin-top: 0;
        display: inline-block;
        width: 100%;
    }

    .signup-picture {
        width: 100%;
    }

    li.BrainhubCarouselItem img.img-example {
        width: 100%;
    }

    .BusinessInfo h1 {
        width: 100%;
        font-size: 35px;
        text-align: center;
    }

    .BusinessInfo p {
        width: 100%;
        text-align: center;
        font-size: 14px;
    }

    .BusinessInfo button.btn.btn-default,
    .BusinessInfo a.btn.btn-default {
        display: block;
        max-width: 280px;
        margin: 10px auto 0;
        text-align: center;
    }

    .BHeading h2 {
        font-size: 26px;
        padding: 0 15px;
        margin-bottom: 20px;
    }

    main.BusinessHome .testimonial-item .testi-comment h1 {
        font-size: 22px;
        margin-bottom: 15px;
        width: 100%;
    }

    .places-carousel .slick-slide {
        padding: 0;
    }

    main.BusinessHome .sign-up-image img.random-img {
        width: 100%;
    }

    section.testimonial-area.padding-bottom-100px,
    .BHeading.padding-bottom-60px {
        padding-bottom: 20px;
    }

    .Rectangle.Video {
        padding: 15px;
    }

    .VideoContent h3,
    .VideoContent p {
        font-size: 18px;
        text-align: center;
    }

    .VideoContent p {
        font-size: 16px;
    }

    .GetStarted {
        margin-bottom: 20px;
    }

    section.testimonial-area.padding-top-0px.padding-bottom-60px.text-center {
        padding-bottom: 20px;
    }

    .OwnersSay {
        margin-bottom: 50px;
    }

    .SignUpDesc.padding-top-100px.padding-bottom-20px {
        padding-top: 20px;
    }

    main.dashboard-page .main-menu-content {
        height: auto;
    }

    .main-menu-content .D-Logo {
        text-align: center;
    }

    .main-menu-content li.LogOut.seller {
        text-align: center;
        margin: 0 auto;
        float: none;
        display: inherit;
    }

    .TopHeader ul.BNavebar {
        text-align: center;
        padding-bottom: 0;
    }

    .sidebar {
        margin-top: 0;
    }

    .Rectangle .slick-slider.places-carousel.testimonial-carousel.slick-initialized button.slick-arrow.slick-prev {
        right: auto;
        left: -5px;
    }

    .Rectangle .slick-slider.places-carousel.testimonial-carousel.slick-initialized button.slick-arrow.slick-next {
        right: -10px;
        left: auto;
    }

    .billing-content.BSignupForm .contact-form-action.sign-up,
    .billing-content.BSignupForm .contact-form-action.sign-up .input-box input.form-control {
        width: 100%;
    }

    main.signup-page.BizSignUp .bottom-btn.step-1 {
        margin-left: 0;
        text-align: center;
    }

    .signup-desc {
        width: 100%;
    }

    .bottom-btn button.btn.btn-default.ml-5 {
        margin-left: 0 !important;
        margin: 15px 0;
    }

    .bottom-btn button.btn.btn-default {
        width: 150px;
        margin: 0 15px;
        text-align: center;
    }

    .bottom-btn {
        margin-top: 0;
    }

    .signup-desc.step4.location p {
        width: 100%;
    }

    .signup-desc.step4.location {
        padding-left: 0;
    }

    .container.nav-listing-search {
        max-width: 540px;
    }

    .main-menu-content {
        display: none;
    }

    .chat-user-info {
        width: 93%;
        border-left: 0;
    }

    .chat-module .card-body {
        width: 99%;
    }

    .chat-room .friend-list .list-group-item {
        grid-template-columns: 50px 310px 100px;
    }

    main.dashboard-page .chat-module .sidebar.section-bg {
        border-left: 0;
    }

    .form-group.basic-textarea {
        width: 96%;

    }

    .contact-form-action .form-control.c1.near-feild {
        border-radius: 0;
    }

    .biz-ligh-back {
        /*background: transparent;*/
        height: auto;
    }

    .BannerContent {
        width: 100%;
    }

    section.hero-wrapper2.bizbanner .col-lg-6.col-12.col-px-12 {
        padding: 0;
    }

    .BusinessInfo {
        border-radius: 0;
        width: 100%;
        position: static;
        padding: 20px;
        height: auto;
        padding-bottom: 40px;
    }

    .BusinessInfo.banner_img.desktop-show {
        display: none;
    }

    .BusinessInfo.banner_img.mobile-show {
        display: block;
    }

    main.BusinessHome.BizHome header.header-area {
        box-shadow: 0px 0px 3px 0px #888888;
        z-index: 1;
        position: relative;
        padding: 0;
    }

    section.hero-wrapper2.bizbanner {
        background-size: cover;
        background-position: bottom right;
        min-height: auto;
        height: auto;
        margin: 0;
        padding: 0;
    }

    .content-presence img {
        width: 100%;
    }

    .apps-logo {
        grid-template-columns: auto;
    }

    section.k-class.footer-area.BizFooter .col-lg-3.col-md-3.col-sm-3.column-td-6.logo img {
        margin-top: 10px;
    }

    .SubHeading {
        line-height: normal;
    }

    .BLanding.BNavabar.bLanding .side-menu-open,
    .main-menu-dashboard .side-menu-open {
        transform: translateY(-17px);
    }

    .content-presence.BusinessInfo {
        min-height: auto;
        height: auto;
        padding: 0 20px;
        margin-bottom: 20px;
    }

    .FaqsQuestion {
        width: 90%;
        margin: 0 auto;
        padding: 0;
    }

    .col-lg-6.col-12.bottom {
        order: 2;
    }

    .content-presence {
        margin-bottom: 20px;
    }

    .mobile-img {
        position: static;
        width: 100%
    }

    .mobile-img img {
        width: 100%
    }

    .content-presence.BusinessInfo a.btn.btn-default {
        margin-top: 20px;
        display: inline-block;
    }

    .u-photos .card-item .card__img.custom-img {
        width: 100%;
        max-height: 215px;
        border-radius: 10px;
        min-height: 215px;
        object-fit: cover;
    }

    .contact-form-action {
        display: inline-block;
        width: 100%
    }

    .input-box a.cvv {
        padding-top: 0;
    }

    .checkout-page .btn-box.purchase button.theme-btn {
        width: 70%;
        margin-bottom: 20px;
    }

    .checkout-page .btn-box.purchase button.btn-danger {
        width: 70%;
        margin-left: 0 !important;
    }

    .checkout-page .billing-content {
        padding: 20px 15px;
    }
}


@media screen and (max-width: 640px) {
    ul.comparison-card-list {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }

    .story-slider.view-story .slick-slide-item .storyHeader.detasil {
        display: none;
    }

    .storyHeader.detasil.name {
        display: none;
    }

    .DashboardAccoundModal main.dashboard-page.account-page .nav-item a.nav-link,
    main.dashboard-page.account-page .nav-item a.nav-link {
        width: 150px;
        border-radius: 0;
        margin: 0
    }
}

@media screen and (max-width: 412px) {
    .sign-up-image img.random-img {
        width: 100%;
    }
}

@media screen and (max-width: 360px) {

    .single-listing-wrap .write-review a,
    .single-listing-wrap .write-review button.btn.btn-none {
        padding: 10px 0;
        text-align: center;
        width: 46%;
    }

    section.single-listing-area .direction-btn {
        top: 0;
        margin-top: -40px;
    }

}